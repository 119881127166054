import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import { closeSearchModalDialog } from 'actions/Actions'

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiTypography-h6': {
      height: '24px',
      color: '#2C3F58',
      fontFamily: 'Hiragino Sans',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  formContent: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  contentDescription: {
    flex: 1,
    display: 'flex',
    marginBottom: '20px',
    paddingLeft: '18px',
    paddingRight: '18px',
    height: '48px',
    borderRadius: '4px',
    backgroundColor: '#FEF7E0',
    alignItems: 'center'
  },
  descriptionIcon: {
    color: '#FFD422'
  },
  descriptionText: {
    marginLeft: '14px'
  },
  formFooter: {
    marginTop: '8px',
    marginLeft: '16px',
    marginBottom: '8px',
    marginRight: '16px'
  }
}))

function SearchFormModalDialog(props) {
  const classes = useStyles()

  const { title, description, content, footer, postData } = props

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={props.closeSearchModalDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <form onSubmit={postData}>
          <Divider />
          <DialogContent className={classes.formContent}>
            {content}
          </DialogContent>
          <Divider />
          <DialogActions className={classes.formFooter}>{footer}</DialogActions>
        </form>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => {
  return {
    isOpen: state.searchModalDialog.isOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFormModalDialog)
