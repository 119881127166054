import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import { Cache } from 'aws-amplify'
import Grid from '@material-ui/core/Grid'
import Pagination from 'components/common/Pagination'
import { PAGE_LIMIT } from 'utils/const'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import SearchSaveAndCallButton from 'components/common/SearchSaveAndCallButton'
import Loading from 'components/common/Loading'
import List from './List'
import { KeyReceiptApplicationState, ScreenUrl } from 'utils/enum'
import RadioButtons from 'components/common/RadioButtons'
import CommonChip from 'components/common/CommonChip'
import SearchFormModalDialog from './SearchFormModalDialog'
import SearchFormContent from './SearchFormContent'
import SearchModalDialogFooter from './SearchModalDialogFooter'
import SearchSaveModalDialog from 'components/common/SearchSaveModalDialog'
import SearchSaveContent from 'components/common/SearchSaveContent'
import { QueryHelper } from 'utils/api.utils'
import {
  closeSearchModalDialog,
  closeSearchSaveModalDialog,
  saveKeyReceiptSearchCondition
} from 'actions/Actions'
import { fromCode } from 'utils/enum.utils'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  }
}))

const ListContent = props => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { pageId } = useParams()
  const [pageNumber, setPageNumber] = useState(isFinite(pageId) ? pageId : 1)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(
    location.state !== undefined && 'application_state_id' in location.state
      ? location.state.application_state_id
      : KeyReceiptApplicationState.all.code
  )
  const [searchCondition, setSearchCondition] = useState(
    location.state !== undefined ? location.state : props.keyReceiptSearchParams
  )
  const [searchConditionErrors, setSearchConditionErrors] = useState({})
  const buttonsData = Object.keys(KeyReceiptApplicationState).map(key => ({
    value: KeyReceiptApplicationState[key].code,
    label: KeyReceiptApplicationState[key].value
  }))
  const initialState = {
    data: [],
    paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
  }
  const keyReceiptApplicationListCache = Cache.getItem(
    'totono-advertiser-keyReceiptApplication-list'
  )
  const { data, paging } = keyReceiptApplicationListCache
    ? keyReceiptApplicationListCache
    : initialState
  const [keyReceiptApplicationList, setKeyReceiptApplicationList] = useState(
    data
  )
  const [companyGroupList, setCompanyGroupList] = useState(null)
  const [fetchedCompanyGroupList, setFetchedCompanyGroupList] = useState(null)
  const [companyUserList, setCompanyUserList] = useState(null)
  const [fetchedCompanyUserList, setFetchedCompanyUserList] = useState(null)
  const [masterData, setMasterData] = useState({
    ttyKnrPatternList: [],
    ttyKnrTantoList: [],
    ttyTnpList: []
  })
  const [pagingInfo, setPagingInfo] = useState(paging)

  const handleChangePage = (event, newPage) => {
    searchCondition['pageNumber'] = newPage + 1
    setPageNumber(newPage + 1)
  }

  const handleCustomChangePage = newPage => {
    searchCondition['pageNumber'] = newPage
    setPageNumber(newPage)
  }

  const handleSelectedButton = selectedStatus => {
    var params = Object.assign({}, searchCondition, {
      application_state_id: selectedStatus
    })
    if (!selectedStatus) {
      params['application_state_id'] = KeyReceiptApplicationState.all.code
    }
    params['pageNumber'] = 1
    props.saveKeyReceiptSearchCondition(params)
    setSearchCondition(
      params,
      setSelectedStatus(selectedStatus),
      setPageNumber(1)
    )
  }

  const createSearchCondition = () => {
    var filter = {}
    var created_at = []
    Object.keys(searchCondition).forEach(key => {
      const value = searchCondition[key]
      if (!value) return

      switch (key) {
        case 'application_state_id':
          filter['application_state_id'] = { eq: value }
          setSelectedStatus(value)
          break

        case 'application.keyReceipt.created_at_from':
          if ('application.keyReceipt.created_at_from' in searchCondition) {
            created_at.push(value)
          } else {
            props.enqueueSnackbar('申込日(To)を指定してください', {
              variant: 'error'
            })
          }
          break

        case 'application.keyReceipt.created_at_to':
          if ('application.keyReceipt.created_at_to' in searchCondition) {
            created_at.push(value)
          } else {
            props.enqueueSnackbar('申込日(From)を指定してください', {
              variant: 'error'
            })
          }
          break

        case 'application.keyReceipt.id':
          filter['id'] = { eq: value }
          break

        case 'application.keyReceipt.customer_id':
          filter['customer_id'] = { contains: value }
          break

        case 'application.keyReceipt.TTY_NAME':
          filter['TTY_NAME'] = { contains: value }
          break

        case 'application.keyReceipt.is_arranged':
          filter.is_arranged = value == 1 ? { eq: true } : { ne: true }
          break

        case 'application.keyReceipt.company_group_id':
          if (value != 0) {
            filter.company_group_id = { eq: value }
          }
          break

        case 'application.keyReceipt.company_user_id':
          if (value != 0) {
            filter.company_user_id = { eq: value }
          }
          break

        case 'application.keyReceipt.memberFlag1':
          filter['KYK_MEMBER_FLG'] = { eq: value }
          break

        case 'application.keyReceipt.memberFlag2':
          filter['KYK_MEMBER_FLG_2'] = { eq: value }
          break

        case 'application.keyReceipt.knrPattern':
          filter['TTY_KNRPATTERN_ID'] = { eq: value }
          break

        case 'application.keyReceipt.knrTanto':
          filter['TTY_TANTO_SHN_ID'] = { eq: value }
          break

        case 'application.keyReceipt.ttyTnp':
          filter['TTY_TNP_ID'] = { eq: value }
          break

        default:
          break
      }
    })
    if (created_at.length > 0) {
      filter['created_at'] = { between: created_at }
    }
    return filter
  }

  const getKeyReceiptApplicationList = async ({ offset, limit }) => {
    const filter = createSearchCondition()
    const result = await QueryHelper(
      'getKeyReceiptApplicationList',
      {
        filter,
        offset,
        limit
      },
      false
    )
    if (result.error) {
      props.enqueueSnackbar('鍵受領書申請一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setKeyReceiptApplicationList(result.data, setPagingInfo(result.paging))
    }
  }

  const fetchCompanyGroupList = async (offset, limit) => {
    const filter = {
      status: {
        contains: [1, 2, 3]
      }
    }
    const result = await QueryHelper(
      'getCompanyGroupList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      props.enqueueSnackbar('グループ一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const companyGroupItems = [{ value: 0, label: '' }]
      if (result.data.length > 0) {
        for (let item of result.data) {
          if (item.role_id != 5 && item.status === 1) {
            const companyGroupItem = {
              value: item.id,
              label: item.name
            }
            companyGroupItems.push(companyGroupItem)
          }
        }
      }
      setCompanyGroupList(companyGroupItems)
      setFetchedCompanyGroupList(result.data)
    }
  }

  const fetchCompanyUserList = async (offset, limit) => {
    const filter = {
      status: {
        contains: [1, 2, 3]
      }
    }
    const result = await QueryHelper(
      'getCompanyUserList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      props.enqueueSnackbar('利用者一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const userMap = new Map()
      const companyUserItems = [{ value: 0, label: '' }]
      if (result.data.length > 0) {
        for (let item of result.data) {
          if (item.company_user.status === 1) {
            const companyUserItem = {
              value: item.company_user.id,
              label: item.company_user.name
            }
            companyUserItems.push(companyUserItem)
            if (!userMap.has(item.company_group.id)) {
              userMap.set(item.company_group.id, [companyUserItem])
            } else {
              const companyUserItemArray = userMap.get(item.company_group.id)
              companyUserItemArray.push(companyUserItem)
            }
          }
        }
      }
      setCompanyUserList(userMap)
      setFetchedCompanyUserList(result.data)
    }
  }

  const fetchTateyaIndividualMaster = async () => {
    const result = await QueryHelper('getTateyaIndividualMaster', {})

    if (result.error) {
      props.enqueueSnackbar('建物管理データを取得できませんでした', {
        variant: 'error'
      })
    } else {
      const defaultItems = [{ value: 0, label: '' }]

      const ttyKnrPatternList = [
        ...defaultItems,
        ...(result.KnrPattern || []).map(item => ({
          value: item.TTY_KNRPATTERN_ID,
          label: item.TTY_KNRPATTERN_NAME
        }))
      ]

      const ttyKnrTantoList = [
        ...defaultItems,
        ...(result.KnrTanto || []).map(item => ({
          value: item.TTY_TANTO_SHN_ID,
          label: item.TTY_TANTO_SHN_NAME
        }))
      ]

      const ttyTnpList = [
        ...defaultItems,
        ...(result.KnrTenpo || []).map(item => ({
          value: item.TTY_TNP_ID,
          label: item.TTY_TNP_NAME
        }))
      ]

      setMasterData({
        ttyKnrPatternList,
        ttyKnrTantoList,
        ttyTnpList
      })
    }
  }

  const validateSearchCondition = params => {
    const valid = {}

    if (
      params['application.keyReceipt.created_at_from'] &&
      !params['application.keyReceipt.created_at_to']
    ) {
      valid['application.keyReceipt.created_at_to.message'] =
        '申請日(From)が指定されてる時は申請日(To)は必須です'
    }
    if (
      !params['application.keyReceipt.created_at_from'] &&
      params['application.keyReceipt.created_at_to']
    ) {
      valid['application.keyReceipt.created_at_from.message'] =
        '申請日(To)が指定されてる時は申請日(From)は必須です'
    }

    if (Object.keys(valid).length > 0) {
      setSearchConditionErrors(valid)
      return true
    }
    setSearchConditionErrors({})
    return false
  }

  const searchPostData = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let params = {
      application_state_id: selectedStatus
    }
    for (let entry of formData.entries()) {
      if (entry[1] != 0 && entry[1] != '') {
        params[entry[0]] = entry[1]
      }
    }
    if (validateSearchCondition(params)) {
      return
    }
    props.saveKeyReceiptSearchCondition(params)
    props.closeSearchModalDialog()
    params['pageNumber'] = 1
    setSearchCondition(
      params,
      setSelectedStatus(selectedStatus),
      setPageNumber(1)
    )
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([
      getKeyReceiptApplicationList({
        offset,
        limit: PAGE_LIMIT
      }),
      companyGroupList ? '' : fetchCompanyGroupList(0, 0),
      companyUserList ? '' : fetchCompanyUserList(0, null),
      masterData.ttyKnrPatternList.length ||
      masterData.ttyKnrTantoList.length ||
      masterData.ttyTnpList.length
        ? ''
        : fetchTateyaIndividualMaster()
    ])
    setIsLoading(false)
  }

  const fetchSearchConditions = async () => {
    const result = await QueryHelper('getSearchConditionList', {
      screen: ScreenUrl.applicationKeyReceiptList
    })
    if (!result.error) {
      let defaultSearchCondition = {}
      for (let row of result.data) {
        if (row.isDefault) {
          defaultSearchCondition = row.condition
          break
        }
      }
      if (Object.keys(defaultSearchCondition).length > 0) {
        setSearchCondition(JSON.parse(defaultSearchCondition))
      }
    }
  }

  useEffect(() => {
    if (Object.keys(searchCondition).length === 0) {
      fetchSearchConditions()
    }
  }, [])

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `${ScreenUrl.applicationKeyReceiptList}${pageNumber}`,
      state: searchCondition
    })
    fetchData(offset)
  }, [pageNumber, selectedStatus, searchCondition])

  const isContainsApplicationKeyReceiptBySearchConditions = () => {
    const val = Object.keys(searchCondition).find(
      x =>
        x.match(/^application.keyReceipt.+/) ||
        (x === 'application_state_id' &&
          searchCondition[x] != KeyReceiptApplicationState.all.code)
    )
    return val !== null && val !== undefined
  }

  const getLabelFromSearchConditions = (key, list, labelPrefix) => {
    const value = Number(searchCondition[key])
    if (value && list) {
      const item = list.find(item => item.value === value)
      return item ? `${labelPrefix}: ${item.label}` : undefined
    }
    return undefined
  }

  const createLabel = (key, value) => {
    if (!value) return undefined

    switch (key) {
      case 'application_state_id': {
        if (
          searchCondition[key] != KeyReceiptApplicationState.all.code &&
          searchCondition[key] != undefined
        ) {
          return (
            '状態: ' +
            fromCode(KeyReceiptApplicationState, Number(searchCondition[key]))
              .value
          )
        }
        break
      }

      case 'application.keyReceipt.created_at_from':
        return '申請日(From): ' + value

      case 'application.keyReceipt.created_at_to':
        return '申請日(To): ' + value

      case 'application.keyReceipt.id':
        return 'ID: ' + value

      case 'application.keyReceipt.customer_id':
        return '入居者名: ' + value

      case 'application.keyReceipt.TTY_NAME':
        return '物件名: ' + value

      case 'application.keyReceipt.is_arranged':
        return value == 1 ? '案件手配のみ表示' : '案件手配を含めない'

      case 'application.keyReceipt.company_group_id': {
        const companyGroup = fetchedCompanyGroupList
          ? fetchedCompanyGroupList.find(group => group.id === Number(value))
          : undefined
        if (companyGroup) {
          const formattedName = getFormattedNameByStatus(
            companyGroup.name,
            companyGroup.status
          )
          return '担当グループ: ' + formattedName
        }
        return undefined
      }

      case 'application.keyReceipt.company_user_id': {
        const companyUser = fetchedCompanyUserList
          ? fetchedCompanyUserList.find(
              user => user.company_user.id === Number(value)
            )
          : undefined
        if (companyUser) {
          const formattedName = getFormattedNameByStatus(
            companyUser.company_user.name,
            companyUser.company_user.status
          )
          return '担当者: ' + formattedName
        }
        return undefined
      }

      case 'application.keyReceipt.memberFlag1':
        return '会員フラグ1: ON'

      case 'application.keyReceipt.memberFlag2':
        return '会員フラグ2: ON'

      case 'application.keyReceipt.knrPattern': {
        const { ttyKnrPatternList } = masterData
        return getLabelFromSearchConditions(
          key,
          ttyKnrPatternList,
          '管理パターン'
        )
      }

      case 'application.keyReceipt.knrTanto': {
        const { ttyKnrTantoList } = masterData
        return getLabelFromSearchConditions(key, ttyKnrTantoList, '管理担当者')
      }

      case 'application.keyReceipt.ttyTnp': {
        const { ttyTnpList } = masterData
        return getLabelFromSearchConditions(key, ttyTnpList, '建物取扱店舗')
      }
    }
  }

  const handleDeleteSearchCondition = (target, id = null) => event => {
    const params = JSON.parse(JSON.stringify(searchCondition))
    switch (target) {
      case 'application_state_id': {
        setSelectedStatus(KeyReceiptApplicationState.all.code)
        delete params[target]
        break
      }
      case 'application.keyReceipt.created_at_from': {
        delete params['application.keyReceipt.created_at_to']
        delete params['application.keyReceipt.created_at_from']
        break
      }
      case 'application.keyReceipt.created_at_to': {
        delete params['application.keyReceipt.created_at_to']
        delete params['application.keyReceipt.created_at_from']
        break
      }
      case 'application.keyReceipt.company_group_id': {
        delete params[target]
        delete params['application.keyReceipt.company_user_id']
        break
      }
      default: {
        delete params[target]
        break
      }
    }
    params['pageNumber'] = 1
    props.saveKeyReceiptSearchCondition(params)
    setSearchCondition(params, setPageNumber(1))
  }

  const SearchConditionChips = () => {
    return (
      <Grid item xs={12}>
        {searchCondition &&
          Object.keys(searchCondition).length > 0 &&
          isContainsApplicationKeyReceiptBySearchConditions() && (
            <Paper component="ul" className={classes.chips}>
              {Object.keys(searchCondition).map(key => {
                const label = createLabel(key, searchCondition[key])
                return label ? (
                  <CommonChip
                    label={label}
                    onDelete={handleDeleteSearchCondition(key)}
                  />
                ) : null
              })}
            </Paper>
          )}
      </Grid>
    )
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <SearchConditionChips />
        <Grid item xs className={classes.tableTips} />
        <Grid item xs={5} className={classes.tableTips}>
          <RadioButtons
            current={selectedStatus}
            handleSelectedButton={handleSelectedButton}
            buttonsData={buttonsData}
          />
        </Grid>
        <SearchSaveAndCallButton />
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={pagingInfo.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {!isLoading && keyReceiptApplicationList ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List
              keyReceiptApplicationList={keyReceiptApplicationList}
              searchCondition={searchCondition}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={pagingInfo.count}
        rowsPerPage={PAGE_LIMIT}
      />
      <SearchFormModalDialog
        content={
          <SearchFormContent
            companyGroupList={companyGroupList}
            companyUserList={companyUserList}
            fetchedCompanyGroupList={fetchedCompanyGroupList}
            fetchedCompanyUserList={fetchedCompanyUserList}
            masterData={masterData}
            searchCondition={searchCondition}
            searchConditionErrors={searchConditionErrors}
          />
        }
        footer={
          <SearchModalDialogFooter
            setSearchConditionErrors={setSearchConditionErrors}
          />
        }
        postData={searchPostData}
      />
      <SearchSaveModalDialog
        content={
          <SearchSaveContent
            screen={ScreenUrl.applicationKeyReceiptList}
            searchConditions={searchCondition}
            stateName={'application_state_id'}
            setSelectedStatus={setSelectedStatus}
            setSearchConditions={setSearchCondition}
            closeSearchSaveModalDialog={props.closeSearchSaveModalDialog}
          />
        }
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    keyReceiptSearchParams: state.saveSearchCondition.keyReceipt
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    saveKeyReceiptSearchCondition: parameter => {
      dispatch(saveKeyReceiptSearchCondition(parameter))
    },
    closeSearchSaveModalDialog: () => {
      dispatch(closeSearchSaveModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
