/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOwnerBuilding = /* GraphQL */ `
  query GetOwnerBuilding($owner_id: Int, $building_id: Int) {
    getOwnerBuilding(owner_id: $owner_id, building_id: $building_id) {
      id
      owner_id
      building_id
      is_usage
      created_at
      updated_at
      deleted_at
    }
  }
`
export const getOwner = /* GraphQL */ `
  query GetOwner($filter: TableOwnerFilterInput, $limit: Int, $offset: Int) {
    getOwner(filter: $filter, limit: $limit, offset: $offset) {
      owner {
        id
        name
        name_kana
        app_user_id
        company_id
        created_at
        updated_at
        deleted_at
      }
      app_user_and_attachment {
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        created_at
        deleted_at
        id
        is_activation
        last_login_at
        password
        registered_at
        status
        updated_at
        username
      }
      mst_tateya_and_building {
        attachments {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        building {
          company_id
          contract_cnt
          created_at
          customer_cnt
          deleted_at
          display_order
          id
          is_management
          remark
          room_cnt
          status
          tty_id
          tty_isp_tty_id
          updated_at
          mst_tateya {
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_COMPLETION_DATE
            TTY_CSV_ID
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_DEL_FLG
            TTY_GROUND_FLOOR_NUM
            TY_GRP_NAME
            TTY_ID
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_INSERTED_DATE
            TTY_ISP_KRM_ID
            TTY_ISP_TTY_ID
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_KNR_DIV
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_NO
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNRPATTERN_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_NAME
            TTY_NAME_KANA
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_TIME
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NOBEYUKA_MENSEKI
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_REMARK
            TTY_SOG_DAY
            TTY_SOG_ID
            TTY_SOG_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TNP_NAME
            TTY_TOTAL_COUNT
            TTY_TOTI_MENSEKI
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_UGGROUND_FLOOR_NUM
            TTY_UPDATED_DATE
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_ZIPCODE
            TTY_GRP_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
          is_manually_disabled
        }
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
      }
    }
  }
`
export const getOwnerAttachments = /* GraphQL */ `
  query GetOwnerAttachments(
    $limit: Int
    $offset: Int
    $owner_id: Int
    $building_id: Int
  ) {
    getOwnerAttachments(
      limit: $limit
      offset: $offset
      owner_id: $owner_id
      building_id: $building_id
    ) {
      owner_attachment {
        category_id
        category_name
        owner_attachment_id
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
      }
      category {
        id
        name
      }
    }
  }
`
export const getOwnerList = /* GraphQL */ `
  query GetOwnerList(
    $filter: TableOwnerFilterInput
    $offset: Int
    $limit: Int
  ) {
    getOwnerList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        owner {
          id
          name
          name_kana
          app_user_id
          company_id
          created_at
          updated_at
          deleted_at
        }
        app_user {
          attachment_id
          created_at
          deleted_at
          id
          is_activation
          last_login_at
          password
          registered_at
          status
          updated_at
          username
        }
        owner_building_and_building_and_tty {
          owner_building {
            id
            owner_id
            building_id
            is_usage
            created_at
            updated_at
            deleted_at
          }
          building {
            company_id
            contract_cnt
            created_at
            customer_cnt
            deleted_at
            display_order
            id
            is_management
            remark
            room_cnt
            status
            tty_id
            tty_isp_tty_id
            updated_at
            mst_tateya {
              TTY_ADDR_01
              TTY_ADDR_02
              TTY_COMPLETION_DATE
              TTY_CSV_ID
              TTY_CTY_CD
              TTY_CTY_NAME
              TTY_DEL_FLG
              TTY_GROUND_FLOOR_NUM
              TY_GRP_NAME
              TTY_ID
              TTY_IKT_LEASE_KINGAKU
              TTY_IKT_LEASE_KINGAKU_TAX
              TTY_INSERTED_DATE
              TTY_ISP_KRM_ID
              TTY_ISP_TTY_ID
              TTY_KNRKEITAI_DIV
              TTY_KNRKEITAI_DIV_NAME
              TTY_KNR_DIV
              TTY_KNR_KANRIGIRE_FLG
              TTY_KNR_KEIYAKU_END_DATE
              TTY_KNR_KEIYAKU_STR_DATE
              TTY_KNR_NEWEST_KSN_DATE
              TTY_KNR_NO
              TTY_KNR_ORG_KEIYAKU_DATE
              TTY_KNR_TANTO_SHN_ID
              TTY_KNR_TANTO_SHN_NAME
              TTY_KNRPATTERN_NAME
              TTY_LESSOR_KKY_ID
              TTY_LESSOR_KKY_NAME
              TTY_NAME
              TTY_NAME_KANA
              TTY_NEAREST_BUS_01_RAILWAY
              TTY_NEAREST_BUS_01_RIDE_TIME
              TTY_NEAREST_BUS_01_STATION
              TTY_NEAREST_BUS_01_TIME
              TTY_NEAREST_BUS_02_RAILWAY
              TTY_NEAREST_BUS_02_RIDE_TIME
              TTY_NEAREST_BUS_02_STATION
              TTY_NEAREST_BUS_02_TIME
              TTY_NEAREST_BUS_03_RAILWAY
              TTY_NEAREST_BUS_03_RIDE_TIME
              TTY_NEAREST_BUS_03_STATION
              TTY_NEAREST_BUS_03_TIME
              TTY_NEAREST_TRAIN_01_RAILWAY
              TTY_NEAREST_TRAIN_01_STATION
              TTY_NEAREST_TRAIN_01_TIME
              TTY_NEAREST_TRAIN_02_RAILWAY
              TTY_NEAREST_TRAIN_02_STATION
              TTY_NEAREST_TRAIN_02_TIME
              TTY_NEAREST_TRAIN_03_RAILWAY
              TTY_NEAREST_TRAIN_03_STATION
              TTY_NEAREST_TRAIN_03_TIME
              TTY_NOBEYUKA_MENSEKI
              TTY_OWN_01_KKY_ID
              TTY_OWN_01_KKY_NAME
              TTY_OWN_02_KKY_ID
              TTY_OWN_02_KKY_NAME
              TTY_OWN_03_KKY_ID
              TTY_OWN_03_KKY_NAME
              TTY_PRF_CD
              TTY_PRF_NAME
              TTY_PUBLIC_DRA_KKY_NAME
              TTY_PUBLIC_DRA_KKY_TEL
              TTY_PUBLIC_DRA_KYO_CD
              TTY_PUBLIC_DRA_KYO_NAME
              TTY_PUBLIC_ELC_KKY_NAME
              TTY_PUBLIC_ELC_KKY_TEL
              TTY_PUBLIC_GUS_KKY_NAME
              TTY_PUBLIC_GUS_KKY_TEL
              TTY_PUBLIC_HOTW_KKY_NAME
              TTY_PUBLIC_HOTW_KKY_TEL
              TTY_PUBLIC_WAT_KKY_NAME
              TTY_PUBLIC_WAT_KKY_TEL
              TTY_PUBLIC_WAT_KYO_CD
              TTY_PUBLIC_WAT_KYO_NAME
              TTY_REMARK
              TTY_SOG_DAY
              TTY_SOG_ID
              TTY_SOG_NAME
              TTY_STS_CD
              TTY_STS_NAME
              TTY_TNP_NAME
              TTY_TOTAL_COUNT
              TTY_TOTI_MENSEKI
              TTY_TWN_CD
              TTY_TWN_NAME
              TTY_TYK_CD
              TTY_TYK_NAME
              TTY_TYS_CD
              TTY_TYS_NAME
              TTY_UGGROUND_FLOOR_NUM
              TTY_UPDATED_DATE
              TTY_YAR_CD
              TTY_YAR_NAME
              TTY_ZIPCODE
              TTY_GRP_NAME
              TTY_BSH_TANTO_SHN_NAME
              TTY_GET_TANTO_SHN_NAME
            }
            is_manually_disabled
          }
          mst_tateya {
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_COMPLETION_DATE
            TTY_CSV_ID
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_DEL_FLG
            TTY_GROUND_FLOOR_NUM
            TY_GRP_NAME
            TTY_ID
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_INSERTED_DATE
            TTY_ISP_KRM_ID
            TTY_ISP_TTY_ID
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_KNR_DIV
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_NO
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNRPATTERN_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_NAME
            TTY_NAME_KANA
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_TIME
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NOBEYUKA_MENSEKI
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_REMARK
            TTY_SOG_DAY
            TTY_SOG_ID
            TTY_SOG_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TNP_NAME
            TTY_TOTAL_COUNT
            TTY_TOTI_MENSEKI
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_UGGROUND_FLOOR_NUM
            TTY_UPDATED_DATE
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_ZIPCODE
            TTY_GRP_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
      is_latest
    }
  }
`
export const getOwnerListByBuilding = /* GraphQL */ `
  query GetOwnerListByBuilding(
    $filter: TableOwnerByBuildingFilterInput
    $limit: Int
    $offset: Int
  ) {
    getOwnerListByBuilding(filter: $filter, limit: $limit, offset: $offset) {
      data {
        owner {
          id
          name
          name_kana
          app_user_id
          company_id
          created_at
          updated_at
          deleted_at
        }
        app_user {
          attachment_id
          created_at
          deleted_at
          id
          is_activation
          last_login_at
          password
          registered_at
          status
          updated_at
          username
        }
        owner_buildings {
          id
          owner_id
          building_id
          is_usage
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
      is_latest
    }
  }
`
export const getApplicationConvertList = /* GraphQL */ `
  query GetApplicationConvertList(
    $filter: TableApplicationConvertFilterInput
    $limit: Int
    $offset: Int
  ) {
    getApplicationConvertList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        applicationConvert {
          application_id
          company_id
          convert_status
          convert_type
          created_at
          deleted_at
          id
          status
          updated_at
        }
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getApplicationList = /* GraphQL */ `
  query GetApplicationList(
    $filter: TableApplicationFilterInput
    $limit: Int
    $offset: Int
  ) {
    getApplicationList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        application {
          application_state_id
          application_type_id
          building_id
          company_id
          content
          contract_id
          created_at
          customer_id
          deleted_at
          id
          last_user_notice_at
          remark
          room_id
          status
          updated_at
        }
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        arrangement_status
        is_arranged
        mst_heya {
          HYA_BALCONY_MENSEKI
          HYA_BOX_NUMBER
          HYA_BSH_START_DATE
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_DEL_FLG
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_KINGAKU
          HYA_HOKEN_YR
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_ID
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_INSERTED_DATE
          HYA_ISP_KRS_ID
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_KAIYAKU_DATE
          HYA_KEY_PLACE
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHIN_YR
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_MDR_NUM
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_NYUKYOKANO_DATE
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_REMARK
          HYA_ROOM_NO
          HYA_SENYU_MENSEKI
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
          HYA_TAIKYO_DATE
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_TTY_ID
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_UPDATED_DATE
        }
        mst_kokyaku_kys {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_kokyaku_nky {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        assigned_user_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        application_memos {
          id
          application_id
          memo
          company_user_id
          created_at
          updated_at
          deleted_at
        }
        application_tags {
          id
          status
          body
          colorCode
          createdAt
          createdBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          updatedAt
          updatedBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          deletedAt
        }
        thread {
          application_id
          assigned_by
          assigned_group_by
          building_id
          category_id
          company_id
          contract_id
          created_at
          customer_id
          deleted_at
          exchanged_at
          exchanged_by
          id
          is_reply
          room_id
          status
          thread_state_id
          updated_at
        }
        app_usage_status
        exchanged_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        exchanged_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        room_check_list_application_id
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getApplicationListCSV = /* GraphQL */ `
  query GetApplicationListCSV($filter: AWSJSON) {
    getApplicationListCSV(filter: $filter) {
      attachment_type_id
      bucket
      company_id
      convert_key
      created_at
      deleted_at
      filename
      id
      key
      title
      mime_type
      status
      thumbnail_key
      updated_at
    }
  }
`
export const getApplicationMemos = /* GraphQL */ `
  query GetApplicationMemos($filter: TableApplicationMemoFilterInput) {
    getApplicationMemos(filter: $filter) {
      data {
        id
        memo
        updater {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getApplicationOption = /* GraphQL */ `
  query GetApplicationOption($filter: TableApplicationOptionFilterInput) {
    getApplicationOption(filter: $filter) {
      application_option {
        id
        status
        is_enabled
        is_checked_url
        application_type_id
        company_id
        application_url
        caution_body
        caution_url_text
        caution_url
        complete_title
        complete_body
        complete_url_text
        complete_url
        cancellation_body
        created_at
        updated_at
        deleted_at
      }
      attachments {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      room_check_application_period {
        id
        status
        company_id
        application_type_id
        reckoning_date_type_id
        display_period
        reception_period
        created_at
        updated_at
        deleted_at
      }
      resident_cancellation_option {
        requires_departure_desired_date
        show_departure_desired_date
        requires_attend_desired_date
        show_attend_desired_date
        requires_financial_institution
        show_financial_institution
        show_parking_cancellation
        cancellation_rejection_reason
        rejection_url
        rejection_url_text
      }
    }
  }
`
export const getApplicationType = /* GraphQL */ `
  query GetApplicationType {
    getApplicationType {
      application_types {
        created_at
        deleted_at
        id
        name
        status
        updated_at
      }
    }
  }
`
export const getArrangement = /* GraphQL */ `
  query GetArrangement($filter: TableArrangementFilterInput) {
    getArrangement(filter: $filter) {
      id
      external_assigned_group_by {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      external_assigned_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      arrangement_status
      body
      arrangement_memo_updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      arrangement_memo_updated_at
    }
  }
`
export const getBicycleParkingStickerApplication = /* GraphQL */ `
  query GetBicycleParkingStickerApplication(
    $filter: TableBicycleParkingStickerApplicationFilterInput
  ) {
    getBicycleParkingStickerApplication(filter: $filter) {
      application {
        application_state_id
        application_type_id
        building_id
        company_id
        content
        contract_id
        created_at
        customer_id
        deleted_at
        id
        last_user_notice_at
        remark
        room_id
        status
        updated_at
      }
      attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      arrangement_status
      is_arranged
      mst_heya {
        HYA_BALCONY_MENSEKI
        HYA_BOX_NUMBER
        HYA_BSH_START_DATE
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_DEL_FLG
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_KINGAKU
        HYA_HOKEN_YR
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_ID
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_INSERTED_DATE
        HYA_ISP_KRS_ID
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_KAIYAKU_DATE
        HYA_KEY_PLACE
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHIN_YR
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_MDR_NUM
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_NYUKYOKANO_DATE
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_REMARK
        HYA_ROOM_NO
        HYA_SENYU_MENSEKI
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
        HYA_TAIKYO_DATE
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_TTY_ID
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_UPDATED_DATE
      }
      mst_kokyaku_kys {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_kokyaku_nky {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_tateya {
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_COMPLETION_DATE
        TTY_CSV_ID
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_DEL_FLG
        TTY_GROUND_FLOOR_NUM
        TY_GRP_NAME
        TTY_ID
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_INSERTED_DATE
        TTY_ISP_KRM_ID
        TTY_ISP_TTY_ID
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_KNR_DIV
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_NO
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNRPATTERN_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_NAME
        TTY_NAME_KANA
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_TIME
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NOBEYUKA_MENSEKI
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_REMARK
        TTY_SOG_DAY
        TTY_SOG_ID
        TTY_SOG_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TNP_NAME
        TTY_TOTAL_COUNT
        TTY_TOTI_MENSEKI
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_UGGROUND_FLOOR_NUM
        TTY_UPDATED_DATE
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_ZIPCODE
        TTY_GRP_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      assigned_user_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      assigned_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      application_memos {
        id
        application_id
        memo
        company_user_id
        created_at
        updated_at
        deleted_at
      }
      application_tags {
        id
        status
        body
        colorCode
        createdAt
        createdBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updatedAt
        updatedBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        deletedAt
      }
      thread {
        application_id
        assigned_by
        assigned_group_by
        building_id
        category_id
        company_id
        contract_id
        created_at
        customer_id
        deleted_at
        exchanged_at
        exchanged_by
        id
        is_reply
        room_id
        status
        thread_state_id
        updated_at
      }
      app_usage_status
      exchanged_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      exchanged_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      room_check_list_application_id
    }
  }
`
export const getBicycleParkingStickerApplicationList = /* GraphQL */ `
  query GetBicycleParkingStickerApplicationList(
    $filter: TableBicycleParkingStickerApplicationFilterInput
    $limit: Int
    $offset: Int
  ) {
    getBicycleParkingStickerApplicationList(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      data {
        application {
          application_state_id
          application_type_id
          building_id
          company_id
          content
          contract_id
          created_at
          customer_id
          deleted_at
          id
          last_user_notice_at
          remark
          room_id
          status
          updated_at
        }
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        arrangement_status
        is_arranged
        mst_heya {
          HYA_BALCONY_MENSEKI
          HYA_BOX_NUMBER
          HYA_BSH_START_DATE
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_DEL_FLG
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_KINGAKU
          HYA_HOKEN_YR
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_ID
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_INSERTED_DATE
          HYA_ISP_KRS_ID
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_KAIYAKU_DATE
          HYA_KEY_PLACE
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHIN_YR
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_MDR_NUM
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_NYUKYOKANO_DATE
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_REMARK
          HYA_ROOM_NO
          HYA_SENYU_MENSEKI
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
          HYA_TAIKYO_DATE
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_TTY_ID
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_UPDATED_DATE
        }
        mst_kokyaku_kys {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_kokyaku_nky {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        assigned_user_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        application_memos {
          id
          application_id
          memo
          company_user_id
          created_at
          updated_at
          deleted_at
        }
        application_tags {
          id
          status
          body
          colorCode
          createdAt
          createdBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          updatedAt
          updatedBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          deletedAt
        }
        thread {
          application_id
          assigned_by
          assigned_group_by
          building_id
          category_id
          company_id
          contract_id
          created_at
          customer_id
          deleted_at
          exchanged_at
          exchanged_by
          id
          is_reply
          room_id
          status
          thread_state_id
          updated_at
        }
        app_usage_status
        exchanged_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        exchanged_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        room_check_list_application_id
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getBookmark = /* GraphQL */ `
  query GetBookmark($filter: TableBookmarkFilterInput) {
    getBookmark(filter: $filter) {
      attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      bookmark {
        attachment_id
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        status
        description
        created_by {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_by {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_at
      }
    }
  }
`
export const getBookmarkList = /* GraphQL */ `
  query GetBookmarkList(
    $filter: TableBookmarkFilterInput
    $limit: Int
    $offset: Int
  ) {
    getBookmarkList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        bookmark {
          attachment_id
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          status
          description
          created_by {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          updated_by {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          updated_at
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getBuilding = /* GraphQL */ `
  query GetBuilding($filter: TableBuildingFilterInput) {
    getBuilding(filter: $filter) {
      attachments {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      building {
        company_id
        contract_cnt
        created_at
        customer_cnt
        deleted_at
        display_order
        id
        is_management
        remark
        room_cnt
        status
        tty_id
        tty_isp_tty_id
        updated_at
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        is_manually_disabled
      }
      mst_tateya {
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_COMPLETION_DATE
        TTY_CSV_ID
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_DEL_FLG
        TTY_GROUND_FLOOR_NUM
        TY_GRP_NAME
        TTY_ID
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_INSERTED_DATE
        TTY_ISP_KRM_ID
        TTY_ISP_TTY_ID
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_KNR_DIV
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_NO
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNRPATTERN_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_NAME
        TTY_NAME_KANA
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_TIME
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NOBEYUKA_MENSEKI
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_REMARK
        TTY_SOG_DAY
        TTY_SOG_ID
        TTY_SOG_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TNP_NAME
        TTY_TOTAL_COUNT
        TTY_TOTI_MENSEKI
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_UGGROUND_FLOOR_NUM
        TTY_UPDATED_DATE
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_ZIPCODE
        TTY_GRP_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
    }
  }
`
export const getBuildingAttachments = /* GraphQL */ `
  query GetBuildingAttachments($buildingId: Int) {
    getBuildingAttachments(buildingId: $buildingId) {
      attachments {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      buildingAttachments {
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        description
      }
    }
  }
`
export const getBuildingCsv = /* GraphQL */ `
  query GetBuildingCsv($filter: TableBuildingFilterInput) {
    getBuildingCsv(filter: $filter) {
      companyId
      key
    }
  }
`
export const getBuildingList = /* GraphQL */ `
  query GetBuildingList(
    $filter: TableBuildingFilterInput
    $limit: Int
    $offset: Int
  ) {
    getBuildingList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        attachments {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        building {
          company_id
          contract_cnt
          created_at
          customer_cnt
          deleted_at
          display_order
          id
          is_management
          remark
          room_cnt
          status
          tty_id
          tty_isp_tty_id
          updated_at
          mst_tateya {
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_COMPLETION_DATE
            TTY_CSV_ID
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_DEL_FLG
            TTY_GROUND_FLOOR_NUM
            TY_GRP_NAME
            TTY_ID
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_INSERTED_DATE
            TTY_ISP_KRM_ID
            TTY_ISP_TTY_ID
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_KNR_DIV
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_NO
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNRPATTERN_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_NAME
            TTY_NAME_KANA
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_TIME
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NOBEYUKA_MENSEKI
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_REMARK
            TTY_SOG_DAY
            TTY_SOG_ID
            TTY_SOG_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TNP_NAME
            TTY_TOTAL_COUNT
            TTY_TOTI_MENSEKI
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_UGGROUND_FLOOR_NUM
            TTY_UPDATED_DATE
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_ZIPCODE
            TTY_GRP_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
          is_manually_disabled
        }
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
      }
      searchMetadata {
        csv_validation_format_ver
        mst_tateya_knr_pattern {
          id
          knr_pattern_cd
          knr_pattern_name
        }
        mst_tateya_grp {
          id
          grp_cd
          grp_name
        }
        mst_toriatsukai_tnp {
          id
          toriatsukai_tnp_cd
          toriatsukai_tnp_name
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getBuildingManagement = /* GraphQL */ `
  query GetBuildingManagement($filter: TableMstTateyaMergeFilterInput) {
    getBuildingManagement(filter: $filter) {
      TTY_ID
      TTY_ISP_TTY_ID
      TTY_KNR_DIV
      is_management
    }
  }
`
export const getBuildingMemoList = /* GraphQL */ `
  query GetBuildingMemoList($building_id: Int, $limit: Int, $offset: Int) {
    getBuildingMemoList(
      building_id: $building_id
      limit: $limit
      offset: $offset
    ) {
      data {
        id
        original_data_id
        memo
        created_at
        created_by {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_at
        updated_by {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        deleted_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getBuildingNameList = /* GraphQL */ `
  query GetBuildingNameList($limit: Int, $offset: Int) {
    getBuildingNameList(limit: $limit, offset: $offset) {
      data {
        TTY_NAME
        TTY_NAME_KANA
        id
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getBulkDeliveryList = /* GraphQL */ `
  query GetBulkDeliveryList(
    $filter: TableBulkDeliveryFilterInput
    $limit: Int
    $offset: Int
  ) {
    getBulkDeliveryList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        id
        name
        send_counts
        type
        status
        large_category {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          parent_id
          status
          updated_at
          published_count
          unpublished_count
          icon
        }
        medium_category {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          parent_id
          status
          updated_at
          published_count
          unpublished_count
          icon
        }
        assigned_user_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        send_user_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        send_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        answer_button_type
        send_date_setting
        send_date
        sent_status
        updated_user_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        created_at
        updated_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getBulkDeliveryListCsv = /* GraphQL */ `
  query GetBulkDeliveryListCsv(
    $filter: TableSelected
    $limit: Int
    $offset: Int
  ) {
    getBulkDeliveryListCsv(filter: $filter, limit: $limit, offset: $offset) {
      companyId
      key
    }
  }
`
export const getBulkDeliveryMessages = /* GraphQL */ `
  query GetBulkDeliveryMessages(
    $filter: TableBulkDeliveryMessagesFilterInput!
    $limit: Int
    $offset: Int
  ) {
    getBulkDeliveryMessages(filter: $filter, limit: $limit, offset: $offset) {
      data {
        id
        kyk_status_div
        mst_kokyaku {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        mst_heya {
          HYA_BALCONY_MENSEKI
          HYA_BOX_NUMBER
          HYA_BSH_START_DATE
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_DEL_FLG
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_KINGAKU
          HYA_HOKEN_YR
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_ID
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_INSERTED_DATE
          HYA_ISP_KRS_ID
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_KAIYAKU_DATE
          HYA_KEY_PLACE
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHIN_YR
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_MDR_NUM
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_NYUKYOKANO_DATE
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_REMARK
          HYA_ROOM_NO
          HYA_SENYU_MENSEKI
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
          HYA_TAIKYO_DATE
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_TTY_ID
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_UPDATED_DATE
        }
        knr_keiyaku {
          KYK_CSV_ID
          KYK_DEL_FLG
          KYK_FREERENT_FLG
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_GYS_KKY_ID
          KYK_GYS_KKY_NAME
          KYK_GYS_SHN_NAME
          KYK_HOKEN_GYS_KKY_ID
          KYK_HOKEN_GYS_KKY_NAME
          KYK_HOKEN_KEIYAKU_END_DATE
          KYK_HOKEN_KEIYAKU_STR_DATE
          KYK_HOKEN_KINGAKU
          KYK_HOKEN_KINGAKU_TAX
          KYK_HOKEN_KOSHIN_YR
          KYK_HOKEN_SYOKEN_NO
          KYK_HOSYO_GYS_KKY_ID
          KYK_HOSYO_GYS_KKY_NAME
          KYK_HOSYO_KEIYAKU_END_DATE
          KYK_HOSYO_KEIYAKU_STR_DATE
          KYK_HOSYO_KINGAKU
          KYK_HOSYO_KINGAKU_TAX
          KYK_HOSYO_KOSHIN_YR
          KYK_HOSYO_SYOKEN_NO
          KYK_HSN_01_KKY_ID
          KYK_HSN_01_KKY_NAME
          KYK_HSN_01_ZKG_CD
          KYK_HSN_01_ZKG_NAME
          KYK_HSN_02_KKY_ID
          KYK_HSN_02_KKY_NAME
          KYK_HSN_02_ZKG_CD
          KYK_HSN_02_ZKG_NAME
          KYK_HYA_ID
          KYK_ID
          KYK_INSERTED_DATE
          KYK_ISP_KYK_ID
          KYK_KAIYAKU_ADV_CD
          KYK_KAIYAKU_ADV_NAME
          KYK_KAIYAKU_BNB_CD
          KYK_KAIYAKU_BNB_NAME
          KYK_KAIYAKU_BNK_CD
          KYK_KAIYAKU_BNK_NAME
          KYK_KAIYAKU_DATE
          KYK_KAIYAKU_HENKIN_YOTEI_DATE
          KYK_KAIYAKU_KNK_FB_FLG
          KYK_KAIYAKU_MEIGI
          KYK_KAIYAKU_MEIGI_KANA
          KYK_KAIYAKU_NUMBER
          KYK_KAIYAKU_REMARK
          KYK_KAIYAKU_SEK_END_MN
          KYK_KAIYAKU_SEK_END_YR
          KYK_KAIYAKU_SSN_HAKKO_DATE
          KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
          KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
          KYK_KAIYAKU_TACHIAI_YOTEI_DATE
          KYK_KAIYAKU_TAIKYO_YOTEI_DATE
          KYK_KAIYAKU_TKG_ADDR_01
          KYK_KAIYAKU_TKG_ADDR_02
          KYK_KAIYAKU_TKG_ATENA
          KYK_KAIYAKU_TKG_CTY_CD
          KYK_KAIYAKU_TKG_CTY_NAME
          KYK_KAIYAKU_TKG_PRF_CD
          KYK_KAIYAKU_TKG_PRF_NAME
          KYK_KAIYAKU_TKG_TEL
          KYK_KAIYAKU_TKG_TKR_CD
          KYK_KAIYAKU_TKG_TKR_NAME
          KYK_KAIYAKU_TKG_TWN_CD
          KYK_KAIYAKU_TKG_TWN_NAME
          KYK_KAIYAKU_TKG_ZIPCODE
          KYK_KAIYAKU_TSUCHI_DATE
          KYK_KAIYAKU_YOTEI_DATE
          KYK_KEIYAKU_DATE
          KYK_KEIYAKU_END_DATE
          KYK_KEIYAKU_STR_DATE
          KYK_KOSHINJIMURYO_CALC_DIV
          KYK_KOSHINJIMURYO_CALC_DIV_NAME
          KYK_KOSHINJIMURYO_KINGAKU
          KYK_KOSHINJIMURYO_KINGAKU_TAX
          KYK_KOSHINJIMURYO_MN
          KYK_KOSHINRYO_CALC_DIV
          KYK_KOSHINRYO_CALC_DIV_NAME
          KYK_KOSHINRYO_KINGAKU
          KYK_KOSHINRYO_KINGAKU_TAX
          KYK_KOSHINRYO_MN
          KYK_KOSHINTESURYO_KINGAKU
          KYK_KOSHINTESURYO_KINGAKU_TAX
          KYK_KOSHINTESURYO_PER
          KYK_KOSHINYOKOKU_DIV
          KYK_KOSHINYOKOKU_DIV_NAME
          KYK_KOSHINYOKOKU_NUM
          KYK_KOSHIN_AUTO_FLG
          KYK_KOSHIN_YR
          KYK_KYS_KKY_ID
          KYK_KYS_KKY_NAME
          KYK_MEMBER_CD
          KYK_MEMBER_CD_2
          KYK_MEMBER_FLG
          KYK_MEMBER_FLG_2
          KYK_MEMBER_NAME
          KYK_MEMBER_NAME_2
          KYK_MSK_ID
          KYK_NKY_KKY_ID
          KYK_NKY_KKY_NAME
          KYK_NKY_ZKG_CD
          KYK_NKY_ZKG_NAME
          KYK_NYUKYO_DATE
          KYK_REMARK
          KYK_SDK_KKY_ID
          KYK_SDK_KKY_NAME
          KYK_SEK_DIV_NAME
          KYK_SEK_LIMIT_DATE
          KYK_STATUS_DIV
          KYK_STATUS_DIV_NAME
          KYK_TAIKYOYOKOKU_DIV
          KYK_TAIKYOYOKOKU_DIV_NAME
          KYK_TAIKYOYOKOKU_NUM
          KYK_TAIKYO_SEIKYU_01_KINGAKU
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_01_TKM_CD
          KYK_TAIKYO_SEIKYU_01_TKM_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_02_TKM_CD
          KYK_TAIKYO_SEIKYU_02_TKM_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_03_TKM_CD
          KYK_TAIKYO_SEIKYU_03_TKM_NAME
          KYK_TEISYAKU_FLG
          KYK_TEISYAKU_TERM_DATE
          KYK_TEISYAKU_TERM_DIV
          KYK_TEISYAKU_TERM_DIV_NAME
          KYK_TEISYAKU_TERM_MN
          KYK_TEISYAKU_TERM_YR
          KYK_TINRYO_HASSEI_DATE
          KYK_UPDATED_DATE
          KYK_ZIJ_SEK_TSY_KKY_ID
          KYK_ZIJ_SEK_TSY_KKY_NAME
        }
        body
        attachments {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        is_edited
        customer {
          app_user_id
          company_id
          created_at
          deleted_at
          id
          iot_provider_id
          is_activation
          is_usage
          kky_id
          password
          registered_at
          remark
          status
          updated_at
          username
        }
        building {
          company_id
          contract_cnt
          created_at
          customer_cnt
          deleted_at
          display_order
          id
          is_management
          remark
          room_cnt
          status
          tty_id
          tty_isp_tty_id
          updated_at
          mst_tateya {
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_COMPLETION_DATE
            TTY_CSV_ID
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_DEL_FLG
            TTY_GROUND_FLOOR_NUM
            TY_GRP_NAME
            TTY_ID
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_INSERTED_DATE
            TTY_ISP_KRM_ID
            TTY_ISP_TTY_ID
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_KNR_DIV
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_NO
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNRPATTERN_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_NAME
            TTY_NAME_KANA
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_TIME
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NOBEYUKA_MENSEKI
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_REMARK
            TTY_SOG_DAY
            TTY_SOG_ID
            TTY_SOG_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TNP_NAME
            TTY_TOTAL_COUNT
            TTY_TOTI_MENSEKI
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_UGGROUND_FLOOR_NUM
            TTY_UPDATED_DATE
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_ZIPCODE
            TTY_GRP_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
          is_manually_disabled
        }
        room {
          building_id
          company_id
          created_at
          deleted_at
          display_order
          hya_id
          id
          remark
          status
          updated_at
          is_manually_disabled
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getBulkDeliveryTemplates = /* GraphQL */ `
  query GetBulkDeliveryTemplates(
    $filter: TableBulkDeliveryTemplateFilterInput
    $limit: Int
    $offset: Int
  ) {
    getBulkDeliveryTemplates(filter: $filter, limit: $limit, offset: $offset) {
      data {
        attachments {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        id
        title
        body
        large_category {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          parent_id
          status
          updated_at
          published_count
          unpublished_count
          icon
        }
        medium_category {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          parent_id
          status
          updated_at
          published_count
          unpublished_count
          icon
        }
        answer_button_type
        sent_status
        updater_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getBulletinBoard = /* GraphQL */ `
  query GetBulletinBoard($filter: TableBulletinBoardFilterInput) {
    getBulletinBoard(filter: $filter) {
      attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      building {
        company_id
        contract_cnt
        created_at
        customer_cnt
        deleted_at
        display_order
        id
        is_management
        remark
        room_cnt
        status
        tty_id
        tty_isp_tty_id
        updated_at
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        is_manually_disabled
      }
      bulletin_board {
        body
        bulletin_board_type_id
        company_id
        created_at
        created_by
        deleted_at
        deleted_by
        display_end_at
        display_order
        display_start_at
        id
        is_assign
        is_important
        status
        title
        updated_at
        updated_by
        url
        with_owner
        url_text
        referenced_count
        syndication_destination
      }
      bulletin_board_type {
        created_at
        deleted_at
        display_order
        id
        name
        status
        updated_at
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      title_attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      updated_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      building_count
    }
  }
`
export const getBulletinBoardList = /* GraphQL */ `
  query GetBulletinBoardList(
    $filter: TableBulletinBoardFilterInput
    $limit: Int
    $offset: Int
  ) {
    getBulletinBoardList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        building {
          company_id
          contract_cnt
          created_at
          customer_cnt
          deleted_at
          display_order
          id
          is_management
          remark
          room_cnt
          status
          tty_id
          tty_isp_tty_id
          updated_at
          mst_tateya {
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_COMPLETION_DATE
            TTY_CSV_ID
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_DEL_FLG
            TTY_GROUND_FLOOR_NUM
            TY_GRP_NAME
            TTY_ID
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_INSERTED_DATE
            TTY_ISP_KRM_ID
            TTY_ISP_TTY_ID
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_KNR_DIV
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_NO
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNRPATTERN_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_NAME
            TTY_NAME_KANA
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_TIME
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NOBEYUKA_MENSEKI
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_REMARK
            TTY_SOG_DAY
            TTY_SOG_ID
            TTY_SOG_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TNP_NAME
            TTY_TOTAL_COUNT
            TTY_TOTI_MENSEKI
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_UGGROUND_FLOOR_NUM
            TTY_UPDATED_DATE
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_ZIPCODE
            TTY_GRP_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
          is_manually_disabled
        }
        bulletin_board {
          body
          bulletin_board_type_id
          company_id
          created_at
          created_by
          deleted_at
          deleted_by
          display_end_at
          display_order
          display_start_at
          id
          is_assign
          is_important
          status
          title
          updated_at
          updated_by
          url
          with_owner
          url_text
          referenced_count
          syndication_destination
        }
        bulletin_board_type {
          created_at
          deleted_at
          display_order
          id
          name
          status
          updated_at
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        title_attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        updated_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        building_count
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getBulletinBoardTypeList = /* GraphQL */ `
  query GetBulletinBoardTypeList(
    $filter: TableBulletinBoardTypeFilterInput
    $limit: Int
    $offset: Int
  ) {
    getBulletinBoardTypeList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        created_at
        deleted_at
        display_order
        id
        name
        status
        updated_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getCancellationApplicationOption = /* GraphQL */ `
  query GetCancellationApplicationOption(
    $filter: TableCancellationApplicationOptionFilterInput
  ) {
    getCancellationApplicationOption(filter: $filter) {
      attachments {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      cancellationApplicationOption {
        company_id
        content
        created_at
        created_by
        deleted_at
        deleted_by
        id
        status
        updated_at
        updated_by
        url
      }
      created_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      deleted_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
    }
  }
`
export const getCategory = /* GraphQL */ `
  query GetCategory($filter: TableCategoryFilterInput, $category_type: Int) {
    getCategory(filter: $filter, category_type: $category_type) {
      category {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        parent_id
        status
        updated_at
        published_count
        unpublished_count
        icon
      }
      parent {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        parent_id
        status
        updated_at
        published_count
        unpublished_count
        icon
      }
    }
  }
`
export const getCityList = /* GraphQL */ `
  query GetCityList($filter: TableCityFilterInput) {
    getCityList(filter: $filter) {
      data {
        created_at
        cty_cd
        deleted_at
        display_order
        id
        name
        name_kana
        prefecture_id
        status
        updated_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getCoc2UseParkingApplication = /* GraphQL */ `
  query GetCoc2UseParkingApplication(
    $filter: TableCoc2UseParkingApplicationFilterInput
  ) {
    getCoc2UseParkingApplication(filter: $filter) {
      application {
        application_state_id
        application_type_id
        building_id
        company_id
        content
        contract_id
        created_at
        customer_id
        deleted_at
        id
        last_user_notice_at
        remark
        room_id
        status
        updated_at
      }
      attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      arrangement_status
      is_arranged
      mst_heya {
        HYA_BALCONY_MENSEKI
        HYA_BOX_NUMBER
        HYA_BSH_START_DATE
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_DEL_FLG
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_KINGAKU
        HYA_HOKEN_YR
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_ID
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_INSERTED_DATE
        HYA_ISP_KRS_ID
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_KAIYAKU_DATE
        HYA_KEY_PLACE
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHIN_YR
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_MDR_NUM
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_NYUKYOKANO_DATE
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_REMARK
        HYA_ROOM_NO
        HYA_SENYU_MENSEKI
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
        HYA_TAIKYO_DATE
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_TTY_ID
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_UPDATED_DATE
      }
      mst_kokyaku_kys {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_kokyaku_nky {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_tateya {
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_COMPLETION_DATE
        TTY_CSV_ID
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_DEL_FLG
        TTY_GROUND_FLOOR_NUM
        TY_GRP_NAME
        TTY_ID
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_INSERTED_DATE
        TTY_ISP_KRM_ID
        TTY_ISP_TTY_ID
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_KNR_DIV
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_NO
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNRPATTERN_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_NAME
        TTY_NAME_KANA
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_TIME
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NOBEYUKA_MENSEKI
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_REMARK
        TTY_SOG_DAY
        TTY_SOG_ID
        TTY_SOG_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TNP_NAME
        TTY_TOTAL_COUNT
        TTY_TOTI_MENSEKI
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_UGGROUND_FLOOR_NUM
        TTY_UPDATED_DATE
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_ZIPCODE
        TTY_GRP_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      assigned_user_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      assigned_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      application_memos {
        id
        application_id
        memo
        company_user_id
        created_at
        updated_at
        deleted_at
      }
      application_tags {
        id
        status
        body
        colorCode
        createdAt
        createdBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updatedAt
        updatedBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        deletedAt
      }
      thread {
        application_id
        assigned_by
        assigned_group_by
        building_id
        category_id
        company_id
        contract_id
        created_at
        customer_id
        deleted_at
        exchanged_at
        exchanged_by
        id
        is_reply
        room_id
        status
        thread_state_id
        updated_at
      }
      app_usage_status
      exchanged_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      exchanged_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      room_check_list_application_id
    }
  }
`
export const getCoc2UseParkingApplicationList = /* GraphQL */ `
  query GetCoc2UseParkingApplicationList(
    $filter: TableCoc2UseParkingApplicationFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCoc2UseParkingApplicationList(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      data {
        application {
          application_state_id
          application_type_id
          building_id
          company_id
          content
          contract_id
          created_at
          customer_id
          deleted_at
          id
          last_user_notice_at
          remark
          room_id
          status
          updated_at
        }
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        arrangement_status
        is_arranged
        mst_heya {
          HYA_BALCONY_MENSEKI
          HYA_BOX_NUMBER
          HYA_BSH_START_DATE
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_DEL_FLG
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_KINGAKU
          HYA_HOKEN_YR
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_ID
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_INSERTED_DATE
          HYA_ISP_KRS_ID
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_KAIYAKU_DATE
          HYA_KEY_PLACE
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHIN_YR
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_MDR_NUM
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_NYUKYOKANO_DATE
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_REMARK
          HYA_ROOM_NO
          HYA_SENYU_MENSEKI
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
          HYA_TAIKYO_DATE
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_TTY_ID
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_UPDATED_DATE
        }
        mst_kokyaku_kys {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_kokyaku_nky {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        assigned_user_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        application_memos {
          id
          application_id
          memo
          company_user_id
          created_at
          updated_at
          deleted_at
        }
        application_tags {
          id
          status
          body
          colorCode
          createdAt
          createdBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          updatedAt
          updatedBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          deletedAt
        }
        thread {
          application_id
          assigned_by
          assigned_group_by
          building_id
          category_id
          company_id
          contract_id
          created_at
          customer_id
          deleted_at
          exchanged_at
          exchanged_by
          id
          is_reply
          room_id
          status
          thread_state_id
          updated_at
        }
        app_usage_status
        exchanged_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        exchanged_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        room_check_list_application_id
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getCompany = /* GraphQL */ `
  query GetCompany {
    getCompany {
      attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      company {
        address
        privacy_policy_url
        business_hours
        owner_function_enabled
        business_hours_text
        created_at
        created_by
        deleted_at
        deleted_by
        emergency_name
        emergency_tel
        emergency_time
        emergency_time_text
        fax
        id
        mail
        name
        name_kana
        display_name
        prefecture_id
        regular_holiday
        regular_holiday_text
        status
        stock_signage
        tel
        updated_at
        updated_by
        url
        zip_code
        privacy_policy_type_id
      }
      company_external_system {
        company_id
        external_system_id
      }
      contact_information {
        id
        is_enabled
        contact_label_id
        contact_type_id
        title
        chat_title
        phone_number
        url
        url_text
        reception_start_time
        reception_end_time
        all_day_reception
        description
        created_at
        updated_at
        deleted_at
      }
      updated_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      company_attachments {
        logo {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        icon {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        privacy_policy_attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
      }
    }
  }
`
export const getCompanyCloseWeekList = /* GraphQL */ `
  query GetCompanyCloseWeekList($filter: TableCompanyCloseWeekFilterInput) {
    getCompanyCloseWeekList(filter: $filter) {
      data {
        company_id
        created_at
        deleted_at
        id
        status
        updated_at
        week_id
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getCompanyGroup = /* GraphQL */ `
  query GetCompanyGroup($filter: TableCompanyGroupFilterInput) {
    getCompanyGroup(filter: $filter) {
      company_id
      created_at
      deleted_at
      display_order
      id
      name
      role_id
      status
      updated_at
    }
  }
`
export const getCompanyGroupList = /* GraphQL */ `
  query GetCompanyGroupList(
    $filter: TableCompanyGroupFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCompanyGroupList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getCompanyHolidayList = /* GraphQL */ `
  query GetCompanyHolidayList($filter: TableCompanyHolidayFilterInput) {
    getCompanyHolidayList(filter: $filter) {
      data {
        company_id
        created_at
        deleted_at
        holiday
        start
        end
        is_all_day
        id
        status
        updated_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getCompanyIotProviders = /* GraphQL */ `
  query GetCompanyIotProviders {
    getCompanyIotProviders {
      data {
        body
        created_at
        deleted_at
        id
        name
        option_text
        status
        title
        url
        updated_at
      }
    }
  }
`
export const getCompanyNoticeList = /* GraphQL */ `
  query GetCompanyNoticeList(
    $filter: TableCompanyNoticeFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCompanyNoticeList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        companyNotice {
          body
          created_at
          created_by
          deleted_at
          deleted_by
          id
          status
          updated_at
          updated_by
          url
        }
        createdUser {
          created_at
          deleted_at
          id
          name
          password
          status
          updated_at
          username
        }
        deletedUser {
          created_at
          deleted_at
          id
          name
          password
          status
          updated_at
          username
        }
        updatedUser {
          created_at
          deleted_at
          id
          name
          password
          status
          updated_at
          username
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getCompanyNotificationSettings = /* GraphQL */ `
  query GetCompanyNotificationSettings {
    getCompanyNotificationSettings {
      categories {
        category_id
        company_id
        mail_address
      }
      applications {
        application_type_id
        company_id
        mail_address
      }
    }
  }
`
export const getCompanyUser = /* GraphQL */ `
  query GetCompanyUser($filter: TableCompanyUserFilterInput) {
    getCompanyUser(filter: $filter) {
      attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      company_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      company_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      company_user_notification_setting {
        company_user_id
        should_receive_responsible_notify
        should_receive_group_notify
      }
    }
  }
`
export const getCompanyUserList = /* GraphQL */ `
  query GetCompanyUserList(
    $filter: TableCompanyUserFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCompanyUserList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        company_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        company_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        company_user_notification_setting {
          company_user_id
          should_receive_responsible_notify
          should_receive_group_notify
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getContract = /* GraphQL */ `
  query GetContract($filter: TableContractFilterInput) {
    getContract(filter: $filter) {
      building {
        company_id
        contract_cnt
        created_at
        customer_cnt
        deleted_at
        display_order
        id
        is_management
        remark
        room_cnt
        status
        tty_id
        tty_isp_tty_id
        updated_at
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        is_manually_disabled
      }
      contract {
        building_id
        company_id
        contractor_id
        created_at
        deleted_at
        id
        kyk_id
        kyk_keiyaku_end_date
        kyk_keiyaku_str_date
        kyk_status_div
        room_id
        status
        tenant_id
        updated_at
      }
      customer {
        app_user_id
        company_id
        created_at
        deleted_at
        id
        iot_provider_id
        is_activation
        is_usage
        kky_id
        password
        registered_at
        remark
        status
        updated_at
        username
      }
      app_usage_status
      knr_keiyaku {
        KYK_CSV_ID
        KYK_DEL_FLG
        KYK_FREERENT_FLG
        KYK_GTJ_SEK_TSY_KKY_ID
        KYK_GTJ_SEK_TSY_KKY_NAME
        KYK_GYS_KKY_ID
        KYK_GYS_KKY_NAME
        KYK_GYS_SHN_NAME
        KYK_HOKEN_GYS_KKY_ID
        KYK_HOKEN_GYS_KKY_NAME
        KYK_HOKEN_KEIYAKU_END_DATE
        KYK_HOKEN_KEIYAKU_STR_DATE
        KYK_HOKEN_KINGAKU
        KYK_HOKEN_KINGAKU_TAX
        KYK_HOKEN_KOSHIN_YR
        KYK_HOKEN_SYOKEN_NO
        KYK_HOSYO_GYS_KKY_ID
        KYK_HOSYO_GYS_KKY_NAME
        KYK_HOSYO_KEIYAKU_END_DATE
        KYK_HOSYO_KEIYAKU_STR_DATE
        KYK_HOSYO_KINGAKU
        KYK_HOSYO_KINGAKU_TAX
        KYK_HOSYO_KOSHIN_YR
        KYK_HOSYO_SYOKEN_NO
        KYK_HSN_01_KKY_ID
        KYK_HSN_01_KKY_NAME
        KYK_HSN_01_ZKG_CD
        KYK_HSN_01_ZKG_NAME
        KYK_HSN_02_KKY_ID
        KYK_HSN_02_KKY_NAME
        KYK_HSN_02_ZKG_CD
        KYK_HSN_02_ZKG_NAME
        KYK_HYA_ID
        KYK_ID
        KYK_INSERTED_DATE
        KYK_ISP_KYK_ID
        KYK_KAIYAKU_ADV_CD
        KYK_KAIYAKU_ADV_NAME
        KYK_KAIYAKU_BNB_CD
        KYK_KAIYAKU_BNB_NAME
        KYK_KAIYAKU_BNK_CD
        KYK_KAIYAKU_BNK_NAME
        KYK_KAIYAKU_DATE
        KYK_KAIYAKU_HENKIN_YOTEI_DATE
        KYK_KAIYAKU_KNK_FB_FLG
        KYK_KAIYAKU_MEIGI
        KYK_KAIYAKU_MEIGI_KANA
        KYK_KAIYAKU_NUMBER
        KYK_KAIYAKU_REMARK
        KYK_KAIYAKU_SEK_END_MN
        KYK_KAIYAKU_SEK_END_YR
        KYK_KAIYAKU_SSN_HAKKO_DATE
        KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
        KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
        KYK_KAIYAKU_TACHIAI_YOTEI_DATE
        KYK_KAIYAKU_TAIKYO_YOTEI_DATE
        KYK_KAIYAKU_TKG_ADDR_01
        KYK_KAIYAKU_TKG_ADDR_02
        KYK_KAIYAKU_TKG_ATENA
        KYK_KAIYAKU_TKG_CTY_CD
        KYK_KAIYAKU_TKG_CTY_NAME
        KYK_KAIYAKU_TKG_PRF_CD
        KYK_KAIYAKU_TKG_PRF_NAME
        KYK_KAIYAKU_TKG_TEL
        KYK_KAIYAKU_TKG_TKR_CD
        KYK_KAIYAKU_TKG_TKR_NAME
        KYK_KAIYAKU_TKG_TWN_CD
        KYK_KAIYAKU_TKG_TWN_NAME
        KYK_KAIYAKU_TKG_ZIPCODE
        KYK_KAIYAKU_TSUCHI_DATE
        KYK_KAIYAKU_YOTEI_DATE
        KYK_KEIYAKU_DATE
        KYK_KEIYAKU_END_DATE
        KYK_KEIYAKU_STR_DATE
        KYK_KOSHINJIMURYO_CALC_DIV
        KYK_KOSHINJIMURYO_CALC_DIV_NAME
        KYK_KOSHINJIMURYO_KINGAKU
        KYK_KOSHINJIMURYO_KINGAKU_TAX
        KYK_KOSHINJIMURYO_MN
        KYK_KOSHINRYO_CALC_DIV
        KYK_KOSHINRYO_CALC_DIV_NAME
        KYK_KOSHINRYO_KINGAKU
        KYK_KOSHINRYO_KINGAKU_TAX
        KYK_KOSHINRYO_MN
        KYK_KOSHINTESURYO_KINGAKU
        KYK_KOSHINTESURYO_KINGAKU_TAX
        KYK_KOSHINTESURYO_PER
        KYK_KOSHINYOKOKU_DIV
        KYK_KOSHINYOKOKU_DIV_NAME
        KYK_KOSHINYOKOKU_NUM
        KYK_KOSHIN_AUTO_FLG
        KYK_KOSHIN_YR
        KYK_KYS_KKY_ID
        KYK_KYS_KKY_NAME
        KYK_MEMBER_CD
        KYK_MEMBER_CD_2
        KYK_MEMBER_FLG
        KYK_MEMBER_FLG_2
        KYK_MEMBER_NAME
        KYK_MEMBER_NAME_2
        KYK_MSK_ID
        KYK_NKY_KKY_ID
        KYK_NKY_KKY_NAME
        KYK_NKY_ZKG_CD
        KYK_NKY_ZKG_NAME
        KYK_NYUKYO_DATE
        KYK_REMARK
        KYK_SDK_KKY_ID
        KYK_SDK_KKY_NAME
        KYK_SEK_DIV_NAME
        KYK_SEK_LIMIT_DATE
        KYK_STATUS_DIV
        KYK_STATUS_DIV_NAME
        KYK_TAIKYOYOKOKU_DIV
        KYK_TAIKYOYOKOKU_DIV_NAME
        KYK_TAIKYOYOKOKU_NUM
        KYK_TAIKYO_SEIKYU_01_KINGAKU
        KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_01_TKM_CD
        KYK_TAIKYO_SEIKYU_01_TKM_NAME
        KYK_TAIKYO_SEIKYU_02_KINGAKU
        KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_02_TKM_CD
        KYK_TAIKYO_SEIKYU_02_TKM_NAME
        KYK_TAIKYO_SEIKYU_03_KINGAKU
        KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_03_TKM_CD
        KYK_TAIKYO_SEIKYU_03_TKM_NAME
        KYK_TEISYAKU_FLG
        KYK_TEISYAKU_TERM_DATE
        KYK_TEISYAKU_TERM_DIV
        KYK_TEISYAKU_TERM_DIV_NAME
        KYK_TEISYAKU_TERM_MN
        KYK_TEISYAKU_TERM_YR
        KYK_TINRYO_HASSEI_DATE
        KYK_UPDATED_DATE
        KYK_ZIJ_SEK_TSY_KKY_ID
        KYK_ZIJ_SEK_TSY_KKY_NAME
      }
      knr_keiyaku_r_getsuji {
        KYK_GTJ_DEL_FLG
        KYK_GTJ_ID
        KYK_GTJ_INSERTED_DATE
        KYK_GTJ_ISP_KYK_RKM_01_KYK_ID
        KYK_GTJ_ISP_KYK_RKM_01_SORT_NO
        KYK_GTJ_KINGAKU
        KYK_GTJ_KINGAKU_TAX
        KYK_GTJ_KYK_ID
        KYK_GTJ_SEK_DIV
        KYK_GTJ_SEK_DIV_NAME
        KYK_GTJ_SEK_TSY_KKY_ID
        KYK_GTJ_SEK_TSY_KKY_NAME
        KYK_GTJ_TAX_DIV
        KYK_GTJ_TAX_DIV_NAME
        KYK_GTJ_TKM_CD
        KYK_GTJ_TKM_NAME
        KYK_GTJ_UPDATED_DATE
        KYK_GTJ_ZIJ_FLG
        tax_flg
        total_amount
      }
      mst_heya {
        HYA_BALCONY_MENSEKI
        HYA_BOX_NUMBER
        HYA_BSH_START_DATE
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_DEL_FLG
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_KINGAKU
        HYA_HOKEN_YR
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_ID
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_INSERTED_DATE
        HYA_ISP_KRS_ID
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_KAIYAKU_DATE
        HYA_KEY_PLACE
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHIN_YR
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_MDR_NUM
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_NYUKYOKANO_DATE
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_REMARK
        HYA_ROOM_NO
        HYA_SENYU_MENSEKI
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
        HYA_TAIKYO_DATE
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_TTY_ID
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_UPDATED_DATE
      }
      mst_kokyaku_copy {
        company_id
        kky_id
        kky_legalperson_div
        kky_name
        kky_name_kana
        kky_tel_01
        kky_tel_02
        status
      }
      mst_kokyaku_kyk_hsn_01 {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_kokyaku_kyk_hsn_02 {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_kokyaku_kyk_kys {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_kokyaku_kyk_nky {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_tateya {
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_COMPLETION_DATE
        TTY_CSV_ID
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_DEL_FLG
        TTY_GROUND_FLOOR_NUM
        TY_GRP_NAME
        TTY_ID
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_INSERTED_DATE
        TTY_ISP_KRM_ID
        TTY_ISP_TTY_ID
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_KNR_DIV
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_NO
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNRPATTERN_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_NAME
        TTY_NAME_KANA
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_TIME
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NOBEYUKA_MENSEKI
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_REMARK
        TTY_SOG_DAY
        TTY_SOG_ID
        TTY_SOG_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TNP_NAME
        TTY_TOTAL_COUNT
        TTY_TOTI_MENSEKI
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_UGGROUND_FLOOR_NUM
        TTY_UPDATED_DATE
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_ZIPCODE
        TTY_GRP_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      room {
        building_id
        company_id
        created_at
        deleted_at
        display_order
        hya_id
        id
        remark
        status
        updated_at
        is_manually_disabled
      }
    }
  }
`
export const getContract2 = /* GraphQL */ `
  query GetContract2($filter: TableContractFilterInput) {
    getContract2(filter: $filter) {
      appUser {
        attachment_id
        created_at
        deleted_at
        id
        is_activation
        last_login_at
        password
        registered_at
        status
        updated_at
        username
      }
      building {
        company_id
        contract_cnt
        created_at
        customer_cnt
        deleted_at
        display_order
        id
        is_management
        remark
        room_cnt
        status
        tty_id
        tty_isp_tty_id
        updated_at
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        is_manually_disabled
      }
      contract {
        building_id
        company_id
        contractor_id
        created_at
        deleted_at
        id
        kyk_id
        kyk_keiyaku_end_date
        kyk_keiyaku_str_date
        kyk_status_div
        room_id
        status
        tenant_id
        updated_at
      }
      customer {
        app_user_id
        company_id
        created_at
        deleted_at
        id
        iot_provider_id
        is_activation
        is_usage
        kky_id
        password
        registered_at
        remark
        status
        updated_at
        username
      }
      knr_keiyaku {
        KYK_CSV_ID
        KYK_DEL_FLG
        KYK_FREERENT_FLG
        KYK_GTJ_SEK_TSY_KKY_ID
        KYK_GTJ_SEK_TSY_KKY_NAME
        KYK_GYS_KKY_ID
        KYK_GYS_KKY_NAME
        KYK_GYS_SHN_NAME
        KYK_HOKEN_GYS_KKY_ID
        KYK_HOKEN_GYS_KKY_NAME
        KYK_HOKEN_KEIYAKU_END_DATE
        KYK_HOKEN_KEIYAKU_STR_DATE
        KYK_HOKEN_KINGAKU
        KYK_HOKEN_KINGAKU_TAX
        KYK_HOKEN_KOSHIN_YR
        KYK_HOKEN_SYOKEN_NO
        KYK_HOSYO_GYS_KKY_ID
        KYK_HOSYO_GYS_KKY_NAME
        KYK_HOSYO_KEIYAKU_END_DATE
        KYK_HOSYO_KEIYAKU_STR_DATE
        KYK_HOSYO_KINGAKU
        KYK_HOSYO_KINGAKU_TAX
        KYK_HOSYO_KOSHIN_YR
        KYK_HOSYO_SYOKEN_NO
        KYK_HSN_01_KKY_ID
        KYK_HSN_01_KKY_NAME
        KYK_HSN_01_ZKG_CD
        KYK_HSN_01_ZKG_NAME
        KYK_HSN_02_KKY_ID
        KYK_HSN_02_KKY_NAME
        KYK_HSN_02_ZKG_CD
        KYK_HSN_02_ZKG_NAME
        KYK_HYA_ID
        KYK_ID
        KYK_INSERTED_DATE
        KYK_ISP_KYK_ID
        KYK_KAIYAKU_ADV_CD
        KYK_KAIYAKU_ADV_NAME
        KYK_KAIYAKU_BNB_CD
        KYK_KAIYAKU_BNB_NAME
        KYK_KAIYAKU_BNK_CD
        KYK_KAIYAKU_BNK_NAME
        KYK_KAIYAKU_DATE
        KYK_KAIYAKU_HENKIN_YOTEI_DATE
        KYK_KAIYAKU_KNK_FB_FLG
        KYK_KAIYAKU_MEIGI
        KYK_KAIYAKU_MEIGI_KANA
        KYK_KAIYAKU_NUMBER
        KYK_KAIYAKU_REMARK
        KYK_KAIYAKU_SEK_END_MN
        KYK_KAIYAKU_SEK_END_YR
        KYK_KAIYAKU_SSN_HAKKO_DATE
        KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
        KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
        KYK_KAIYAKU_TACHIAI_YOTEI_DATE
        KYK_KAIYAKU_TAIKYO_YOTEI_DATE
        KYK_KAIYAKU_TKG_ADDR_01
        KYK_KAIYAKU_TKG_ADDR_02
        KYK_KAIYAKU_TKG_ATENA
        KYK_KAIYAKU_TKG_CTY_CD
        KYK_KAIYAKU_TKG_CTY_NAME
        KYK_KAIYAKU_TKG_PRF_CD
        KYK_KAIYAKU_TKG_PRF_NAME
        KYK_KAIYAKU_TKG_TEL
        KYK_KAIYAKU_TKG_TKR_CD
        KYK_KAIYAKU_TKG_TKR_NAME
        KYK_KAIYAKU_TKG_TWN_CD
        KYK_KAIYAKU_TKG_TWN_NAME
        KYK_KAIYAKU_TKG_ZIPCODE
        KYK_KAIYAKU_TSUCHI_DATE
        KYK_KAIYAKU_YOTEI_DATE
        KYK_KEIYAKU_DATE
        KYK_KEIYAKU_END_DATE
        KYK_KEIYAKU_STR_DATE
        KYK_KOSHINJIMURYO_CALC_DIV
        KYK_KOSHINJIMURYO_CALC_DIV_NAME
        KYK_KOSHINJIMURYO_KINGAKU
        KYK_KOSHINJIMURYO_KINGAKU_TAX
        KYK_KOSHINJIMURYO_MN
        KYK_KOSHINRYO_CALC_DIV
        KYK_KOSHINRYO_CALC_DIV_NAME
        KYK_KOSHINRYO_KINGAKU
        KYK_KOSHINRYO_KINGAKU_TAX
        KYK_KOSHINRYO_MN
        KYK_KOSHINTESURYO_KINGAKU
        KYK_KOSHINTESURYO_KINGAKU_TAX
        KYK_KOSHINTESURYO_PER
        KYK_KOSHINYOKOKU_DIV
        KYK_KOSHINYOKOKU_DIV_NAME
        KYK_KOSHINYOKOKU_NUM
        KYK_KOSHIN_AUTO_FLG
        KYK_KOSHIN_YR
        KYK_KYS_KKY_ID
        KYK_KYS_KKY_NAME
        KYK_MEMBER_CD
        KYK_MEMBER_CD_2
        KYK_MEMBER_FLG
        KYK_MEMBER_FLG_2
        KYK_MEMBER_NAME
        KYK_MEMBER_NAME_2
        KYK_MSK_ID
        KYK_NKY_KKY_ID
        KYK_NKY_KKY_NAME
        KYK_NKY_ZKG_CD
        KYK_NKY_ZKG_NAME
        KYK_NYUKYO_DATE
        KYK_REMARK
        KYK_SDK_KKY_ID
        KYK_SDK_KKY_NAME
        KYK_SEK_DIV_NAME
        KYK_SEK_LIMIT_DATE
        KYK_STATUS_DIV
        KYK_STATUS_DIV_NAME
        KYK_TAIKYOYOKOKU_DIV
        KYK_TAIKYOYOKOKU_DIV_NAME
        KYK_TAIKYOYOKOKU_NUM
        KYK_TAIKYO_SEIKYU_01_KINGAKU
        KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_01_TKM_CD
        KYK_TAIKYO_SEIKYU_01_TKM_NAME
        KYK_TAIKYO_SEIKYU_02_KINGAKU
        KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_02_TKM_CD
        KYK_TAIKYO_SEIKYU_02_TKM_NAME
        KYK_TAIKYO_SEIKYU_03_KINGAKU
        KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_03_TKM_CD
        KYK_TAIKYO_SEIKYU_03_TKM_NAME
        KYK_TEISYAKU_FLG
        KYK_TEISYAKU_TERM_DATE
        KYK_TEISYAKU_TERM_DIV
        KYK_TEISYAKU_TERM_DIV_NAME
        KYK_TEISYAKU_TERM_MN
        KYK_TEISYAKU_TERM_YR
        KYK_TINRYO_HASSEI_DATE
        KYK_UPDATED_DATE
        KYK_ZIJ_SEK_TSY_KKY_ID
        KYK_ZIJ_SEK_TSY_KKY_NAME
      }
      knr_keiyaku_r_getsuji {
        KYK_GTJ_DEL_FLG
        KYK_GTJ_ID
        KYK_GTJ_INSERTED_DATE
        KYK_GTJ_ISP_KYK_RKM_01_KYK_ID
        KYK_GTJ_ISP_KYK_RKM_01_SORT_NO
        KYK_GTJ_KINGAKU
        KYK_GTJ_KINGAKU_TAX
        KYK_GTJ_KYK_ID
        KYK_GTJ_SEK_DIV
        KYK_GTJ_SEK_DIV_NAME
        KYK_GTJ_SEK_TSY_KKY_ID
        KYK_GTJ_SEK_TSY_KKY_NAME
        KYK_GTJ_TAX_DIV
        KYK_GTJ_TAX_DIV_NAME
        KYK_GTJ_TKM_CD
        KYK_GTJ_TKM_NAME
        KYK_GTJ_UPDATED_DATE
        KYK_GTJ_ZIJ_FLG
        tax_flg
        total_amount
      }
      mst_heya {
        HYA_BALCONY_MENSEKI
        HYA_BOX_NUMBER
        HYA_BSH_START_DATE
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_DEL_FLG
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_KINGAKU
        HYA_HOKEN_YR
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_ID
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_INSERTED_DATE
        HYA_ISP_KRS_ID
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_KAIYAKU_DATE
        HYA_KEY_PLACE
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHIN_YR
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_MDR_NUM
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_NYUKYOKANO_DATE
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_REMARK
        HYA_ROOM_NO
        HYA_SENYU_MENSEKI
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
        HYA_TAIKYO_DATE
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_TTY_ID
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_UPDATED_DATE
      }
      mst_kokyaku_copy {
        company_id
        kky_id
        kky_legalperson_div
        kky_name
        kky_name_kana
        kky_tel_01
        kky_tel_02
        status
      }
      mst_kokyaku_kyk_hsn_01 {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_kokyaku_kyk_hsn_02 {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_kokyaku_kyk_kys {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_kokyaku_kyk_nky {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_tateya {
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_COMPLETION_DATE
        TTY_CSV_ID
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_DEL_FLG
        TTY_GROUND_FLOOR_NUM
        TY_GRP_NAME
        TTY_ID
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_INSERTED_DATE
        TTY_ISP_KRM_ID
        TTY_ISP_TTY_ID
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_KNR_DIV
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_NO
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNRPATTERN_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_NAME
        TTY_NAME_KANA
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_TIME
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NOBEYUKA_MENSEKI
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_REMARK
        TTY_SOG_DAY
        TTY_SOG_ID
        TTY_SOG_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TNP_NAME
        TTY_TOTAL_COUNT
        TTY_TOTI_MENSEKI
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_UGGROUND_FLOOR_NUM
        TTY_UPDATED_DATE
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_ZIPCODE
        TTY_GRP_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      room {
        building_id
        company_id
        created_at
        deleted_at
        display_order
        hya_id
        id
        remark
        status
        updated_at
        is_manually_disabled
      }
    }
  }
`
export const getContractCsv = /* GraphQL */ `
  query GetContractCsv(
    $filter: TableContractFilterInput
    $limit: Int
    $offset: Int
  ) {
    getContractCsv(filter: $filter, limit: $limit, offset: $offset) {
      companyId
      key
    }
  }
`
export const getContractCsv2 = /* GraphQL */ `
  query GetContractCsv2(
    $filter: TableContractFilterInput
    $limit: Int
    $offset: Int
  ) {
    getContractCsv2(filter: $filter, limit: $limit, offset: $offset) {
      companyId
      key
    }
  }
`
export const getContractorMemoList = /* GraphQL */ `
  query GetContractorMemoList($contractor_id: Int, $limit: Int, $offset: Int) {
    getContractorMemoList(
      contractor_id: $contractor_id
      limit: $limit
      offset: $offset
    ) {
      data {
        id
        original_data_id
        memo
        created_at
        created_by {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_at
        updated_by {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        deleted_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getCustomerHistoryCsv = /* GraphQL */ `
  query GetCustomerHistoryCsv($filter: TableCustomerHistoryCsvFilterInput) {
    getCustomerHistoryCsv(filter: $filter) {
      companyId
      key
    }
  }
`
export const getContractList = /* GraphQL */ `
  query GetContractList(
    $filter: TableContractFilterInput
    $limit: Int
    $offset: Int
  ) {
    getContractList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        building {
          company_id
          contract_cnt
          created_at
          customer_cnt
          deleted_at
          display_order
          id
          is_management
          remark
          room_cnt
          status
          tty_id
          tty_isp_tty_id
          updated_at
          mst_tateya {
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_COMPLETION_DATE
            TTY_CSV_ID
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_DEL_FLG
            TTY_GROUND_FLOOR_NUM
            TY_GRP_NAME
            TTY_ID
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_INSERTED_DATE
            TTY_ISP_KRM_ID
            TTY_ISP_TTY_ID
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_KNR_DIV
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_NO
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNRPATTERN_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_NAME
            TTY_NAME_KANA
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_TIME
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NOBEYUKA_MENSEKI
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_REMARK
            TTY_SOG_DAY
            TTY_SOG_ID
            TTY_SOG_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TNP_NAME
            TTY_TOTAL_COUNT
            TTY_TOTI_MENSEKI
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_UGGROUND_FLOOR_NUM
            TTY_UPDATED_DATE
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_ZIPCODE
            TTY_GRP_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
          is_manually_disabled
        }
        contract {
          building_id
          company_id
          contractor_id
          created_at
          deleted_at
          id
          kyk_id
          kyk_keiyaku_end_date
          kyk_keiyaku_str_date
          kyk_status_div
          room_id
          status
          tenant_id
          updated_at
        }
        customer {
          app_user_id
          company_id
          created_at
          deleted_at
          id
          iot_provider_id
          is_activation
          is_usage
          kky_id
          password
          registered_at
          remark
          status
          updated_at
          username
        }
        app_usage_status
        knr_keiyaku {
          KYK_CSV_ID
          KYK_DEL_FLG
          KYK_FREERENT_FLG
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_GYS_KKY_ID
          KYK_GYS_KKY_NAME
          KYK_GYS_SHN_NAME
          KYK_HOKEN_GYS_KKY_ID
          KYK_HOKEN_GYS_KKY_NAME
          KYK_HOKEN_KEIYAKU_END_DATE
          KYK_HOKEN_KEIYAKU_STR_DATE
          KYK_HOKEN_KINGAKU
          KYK_HOKEN_KINGAKU_TAX
          KYK_HOKEN_KOSHIN_YR
          KYK_HOKEN_SYOKEN_NO
          KYK_HOSYO_GYS_KKY_ID
          KYK_HOSYO_GYS_KKY_NAME
          KYK_HOSYO_KEIYAKU_END_DATE
          KYK_HOSYO_KEIYAKU_STR_DATE
          KYK_HOSYO_KINGAKU
          KYK_HOSYO_KINGAKU_TAX
          KYK_HOSYO_KOSHIN_YR
          KYK_HOSYO_SYOKEN_NO
          KYK_HSN_01_KKY_ID
          KYK_HSN_01_KKY_NAME
          KYK_HSN_01_ZKG_CD
          KYK_HSN_01_ZKG_NAME
          KYK_HSN_02_KKY_ID
          KYK_HSN_02_KKY_NAME
          KYK_HSN_02_ZKG_CD
          KYK_HSN_02_ZKG_NAME
          KYK_HYA_ID
          KYK_ID
          KYK_INSERTED_DATE
          KYK_ISP_KYK_ID
          KYK_KAIYAKU_ADV_CD
          KYK_KAIYAKU_ADV_NAME
          KYK_KAIYAKU_BNB_CD
          KYK_KAIYAKU_BNB_NAME
          KYK_KAIYAKU_BNK_CD
          KYK_KAIYAKU_BNK_NAME
          KYK_KAIYAKU_DATE
          KYK_KAIYAKU_HENKIN_YOTEI_DATE
          KYK_KAIYAKU_KNK_FB_FLG
          KYK_KAIYAKU_MEIGI
          KYK_KAIYAKU_MEIGI_KANA
          KYK_KAIYAKU_NUMBER
          KYK_KAIYAKU_REMARK
          KYK_KAIYAKU_SEK_END_MN
          KYK_KAIYAKU_SEK_END_YR
          KYK_KAIYAKU_SSN_HAKKO_DATE
          KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
          KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
          KYK_KAIYAKU_TACHIAI_YOTEI_DATE
          KYK_KAIYAKU_TAIKYO_YOTEI_DATE
          KYK_KAIYAKU_TKG_ADDR_01
          KYK_KAIYAKU_TKG_ADDR_02
          KYK_KAIYAKU_TKG_ATENA
          KYK_KAIYAKU_TKG_CTY_CD
          KYK_KAIYAKU_TKG_CTY_NAME
          KYK_KAIYAKU_TKG_PRF_CD
          KYK_KAIYAKU_TKG_PRF_NAME
          KYK_KAIYAKU_TKG_TEL
          KYK_KAIYAKU_TKG_TKR_CD
          KYK_KAIYAKU_TKG_TKR_NAME
          KYK_KAIYAKU_TKG_TWN_CD
          KYK_KAIYAKU_TKG_TWN_NAME
          KYK_KAIYAKU_TKG_ZIPCODE
          KYK_KAIYAKU_TSUCHI_DATE
          KYK_KAIYAKU_YOTEI_DATE
          KYK_KEIYAKU_DATE
          KYK_KEIYAKU_END_DATE
          KYK_KEIYAKU_STR_DATE
          KYK_KOSHINJIMURYO_CALC_DIV
          KYK_KOSHINJIMURYO_CALC_DIV_NAME
          KYK_KOSHINJIMURYO_KINGAKU
          KYK_KOSHINJIMURYO_KINGAKU_TAX
          KYK_KOSHINJIMURYO_MN
          KYK_KOSHINRYO_CALC_DIV
          KYK_KOSHINRYO_CALC_DIV_NAME
          KYK_KOSHINRYO_KINGAKU
          KYK_KOSHINRYO_KINGAKU_TAX
          KYK_KOSHINRYO_MN
          KYK_KOSHINTESURYO_KINGAKU
          KYK_KOSHINTESURYO_KINGAKU_TAX
          KYK_KOSHINTESURYO_PER
          KYK_KOSHINYOKOKU_DIV
          KYK_KOSHINYOKOKU_DIV_NAME
          KYK_KOSHINYOKOKU_NUM
          KYK_KOSHIN_AUTO_FLG
          KYK_KOSHIN_YR
          KYK_KYS_KKY_ID
          KYK_KYS_KKY_NAME
          KYK_MEMBER_CD
          KYK_MEMBER_CD_2
          KYK_MEMBER_FLG
          KYK_MEMBER_FLG_2
          KYK_MEMBER_NAME
          KYK_MEMBER_NAME_2
          KYK_MSK_ID
          KYK_NKY_KKY_ID
          KYK_NKY_KKY_NAME
          KYK_NKY_ZKG_CD
          KYK_NKY_ZKG_NAME
          KYK_NYUKYO_DATE
          KYK_REMARK
          KYK_SDK_KKY_ID
          KYK_SDK_KKY_NAME
          KYK_SEK_DIV_NAME
          KYK_SEK_LIMIT_DATE
          KYK_STATUS_DIV
          KYK_STATUS_DIV_NAME
          KYK_TAIKYOYOKOKU_DIV
          KYK_TAIKYOYOKOKU_DIV_NAME
          KYK_TAIKYOYOKOKU_NUM
          KYK_TAIKYO_SEIKYU_01_KINGAKU
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_01_TKM_CD
          KYK_TAIKYO_SEIKYU_01_TKM_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_02_TKM_CD
          KYK_TAIKYO_SEIKYU_02_TKM_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_03_TKM_CD
          KYK_TAIKYO_SEIKYU_03_TKM_NAME
          KYK_TEISYAKU_FLG
          KYK_TEISYAKU_TERM_DATE
          KYK_TEISYAKU_TERM_DIV
          KYK_TEISYAKU_TERM_DIV_NAME
          KYK_TEISYAKU_TERM_MN
          KYK_TEISYAKU_TERM_YR
          KYK_TINRYO_HASSEI_DATE
          KYK_UPDATED_DATE
          KYK_ZIJ_SEK_TSY_KKY_ID
          KYK_ZIJ_SEK_TSY_KKY_NAME
        }
        knr_keiyaku_r_getsuji {
          KYK_GTJ_DEL_FLG
          KYK_GTJ_ID
          KYK_GTJ_INSERTED_DATE
          KYK_GTJ_ISP_KYK_RKM_01_KYK_ID
          KYK_GTJ_ISP_KYK_RKM_01_SORT_NO
          KYK_GTJ_KINGAKU
          KYK_GTJ_KINGAKU_TAX
          KYK_GTJ_KYK_ID
          KYK_GTJ_SEK_DIV
          KYK_GTJ_SEK_DIV_NAME
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_GTJ_TAX_DIV
          KYK_GTJ_TAX_DIV_NAME
          KYK_GTJ_TKM_CD
          KYK_GTJ_TKM_NAME
          KYK_GTJ_UPDATED_DATE
          KYK_GTJ_ZIJ_FLG
          tax_flg
          total_amount
        }
        mst_heya {
          HYA_BALCONY_MENSEKI
          HYA_BOX_NUMBER
          HYA_BSH_START_DATE
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_DEL_FLG
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_KINGAKU
          HYA_HOKEN_YR
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_ID
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_INSERTED_DATE
          HYA_ISP_KRS_ID
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_KAIYAKU_DATE
          HYA_KEY_PLACE
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHIN_YR
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_MDR_NUM
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_NYUKYOKANO_DATE
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_REMARK
          HYA_ROOM_NO
          HYA_SENYU_MENSEKI
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
          HYA_TAIKYO_DATE
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_TTY_ID
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_UPDATED_DATE
        }
        mst_kokyaku_copy {
          company_id
          kky_id
          kky_legalperson_div
          kky_name
          kky_name_kana
          kky_tel_01
          kky_tel_02
          status
        }
        mst_kokyaku_kyk_hsn_01 {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_kokyaku_kyk_hsn_02 {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_kokyaku_kyk_kys {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_kokyaku_kyk_nky {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        room {
          building_id
          company_id
          created_at
          deleted_at
          display_order
          hya_id
          id
          remark
          status
          updated_at
          is_manually_disabled
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getContractList2 = /* GraphQL */ `
  query GetContractList2(
    $filter: TableContractFilterInput
    $limit: Int
    $offset: Int
  ) {
    getContractList2(filter: $filter, limit: $limit, offset: $offset) {
      data {
        appUser {
          attachment_id
          created_at
          deleted_at
          id
          is_activation
          last_login_at
          password
          registered_at
          status
          updated_at
          username
        }
        building {
          company_id
          contract_cnt
          created_at
          customer_cnt
          deleted_at
          display_order
          id
          is_management
          remark
          room_cnt
          status
          tty_id
          tty_isp_tty_id
          updated_at
          mst_tateya {
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_COMPLETION_DATE
            TTY_CSV_ID
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_DEL_FLG
            TTY_GROUND_FLOOR_NUM
            TY_GRP_NAME
            TTY_ID
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_INSERTED_DATE
            TTY_ISP_KRM_ID
            TTY_ISP_TTY_ID
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_KNR_DIV
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_NO
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNRPATTERN_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_NAME
            TTY_NAME_KANA
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_TIME
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NOBEYUKA_MENSEKI
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_REMARK
            TTY_SOG_DAY
            TTY_SOG_ID
            TTY_SOG_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TNP_NAME
            TTY_TOTAL_COUNT
            TTY_TOTI_MENSEKI
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_UGGROUND_FLOOR_NUM
            TTY_UPDATED_DATE
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_ZIPCODE
            TTY_GRP_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
          is_manually_disabled
        }
        contract {
          building_id
          company_id
          contractor_id
          created_at
          deleted_at
          id
          kyk_id
          kyk_keiyaku_end_date
          kyk_keiyaku_str_date
          kyk_status_div
          room_id
          status
          tenant_id
          updated_at
        }
        customer {
          app_user_id
          company_id
          created_at
          deleted_at
          id
          iot_provider_id
          is_activation
          is_usage
          kky_id
          password
          registered_at
          remark
          status
          updated_at
          username
        }
        knr_keiyaku {
          KYK_CSV_ID
          KYK_DEL_FLG
          KYK_FREERENT_FLG
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_GYS_KKY_ID
          KYK_GYS_KKY_NAME
          KYK_GYS_SHN_NAME
          KYK_HOKEN_GYS_KKY_ID
          KYK_HOKEN_GYS_KKY_NAME
          KYK_HOKEN_KEIYAKU_END_DATE
          KYK_HOKEN_KEIYAKU_STR_DATE
          KYK_HOKEN_KINGAKU
          KYK_HOKEN_KINGAKU_TAX
          KYK_HOKEN_KOSHIN_YR
          KYK_HOKEN_SYOKEN_NO
          KYK_HOSYO_GYS_KKY_ID
          KYK_HOSYO_GYS_KKY_NAME
          KYK_HOSYO_KEIYAKU_END_DATE
          KYK_HOSYO_KEIYAKU_STR_DATE
          KYK_HOSYO_KINGAKU
          KYK_HOSYO_KINGAKU_TAX
          KYK_HOSYO_KOSHIN_YR
          KYK_HOSYO_SYOKEN_NO
          KYK_HSN_01_KKY_ID
          KYK_HSN_01_KKY_NAME
          KYK_HSN_01_ZKG_CD
          KYK_HSN_01_ZKG_NAME
          KYK_HSN_02_KKY_ID
          KYK_HSN_02_KKY_NAME
          KYK_HSN_02_ZKG_CD
          KYK_HSN_02_ZKG_NAME
          KYK_HYA_ID
          KYK_ID
          KYK_INSERTED_DATE
          KYK_ISP_KYK_ID
          KYK_KAIYAKU_ADV_CD
          KYK_KAIYAKU_ADV_NAME
          KYK_KAIYAKU_BNB_CD
          KYK_KAIYAKU_BNB_NAME
          KYK_KAIYAKU_BNK_CD
          KYK_KAIYAKU_BNK_NAME
          KYK_KAIYAKU_DATE
          KYK_KAIYAKU_HENKIN_YOTEI_DATE
          KYK_KAIYAKU_KNK_FB_FLG
          KYK_KAIYAKU_MEIGI
          KYK_KAIYAKU_MEIGI_KANA
          KYK_KAIYAKU_NUMBER
          KYK_KAIYAKU_REMARK
          KYK_KAIYAKU_SEK_END_MN
          KYK_KAIYAKU_SEK_END_YR
          KYK_KAIYAKU_SSN_HAKKO_DATE
          KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
          KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
          KYK_KAIYAKU_TACHIAI_YOTEI_DATE
          KYK_KAIYAKU_TAIKYO_YOTEI_DATE
          KYK_KAIYAKU_TKG_ADDR_01
          KYK_KAIYAKU_TKG_ADDR_02
          KYK_KAIYAKU_TKG_ATENA
          KYK_KAIYAKU_TKG_CTY_CD
          KYK_KAIYAKU_TKG_CTY_NAME
          KYK_KAIYAKU_TKG_PRF_CD
          KYK_KAIYAKU_TKG_PRF_NAME
          KYK_KAIYAKU_TKG_TEL
          KYK_KAIYAKU_TKG_TKR_CD
          KYK_KAIYAKU_TKG_TKR_NAME
          KYK_KAIYAKU_TKG_TWN_CD
          KYK_KAIYAKU_TKG_TWN_NAME
          KYK_KAIYAKU_TKG_ZIPCODE
          KYK_KAIYAKU_TSUCHI_DATE
          KYK_KAIYAKU_YOTEI_DATE
          KYK_KEIYAKU_DATE
          KYK_KEIYAKU_END_DATE
          KYK_KEIYAKU_STR_DATE
          KYK_KOSHINJIMURYO_CALC_DIV
          KYK_KOSHINJIMURYO_CALC_DIV_NAME
          KYK_KOSHINJIMURYO_KINGAKU
          KYK_KOSHINJIMURYO_KINGAKU_TAX
          KYK_KOSHINJIMURYO_MN
          KYK_KOSHINRYO_CALC_DIV
          KYK_KOSHINRYO_CALC_DIV_NAME
          KYK_KOSHINRYO_KINGAKU
          KYK_KOSHINRYO_KINGAKU_TAX
          KYK_KOSHINRYO_MN
          KYK_KOSHINTESURYO_KINGAKU
          KYK_KOSHINTESURYO_KINGAKU_TAX
          KYK_KOSHINTESURYO_PER
          KYK_KOSHINYOKOKU_DIV
          KYK_KOSHINYOKOKU_DIV_NAME
          KYK_KOSHINYOKOKU_NUM
          KYK_KOSHIN_AUTO_FLG
          KYK_KOSHIN_YR
          KYK_KYS_KKY_ID
          KYK_KYS_KKY_NAME
          KYK_MEMBER_CD
          KYK_MEMBER_CD_2
          KYK_MEMBER_FLG
          KYK_MEMBER_FLG_2
          KYK_MEMBER_NAME
          KYK_MEMBER_NAME_2
          KYK_MSK_ID
          KYK_NKY_KKY_ID
          KYK_NKY_KKY_NAME
          KYK_NKY_ZKG_CD
          KYK_NKY_ZKG_NAME
          KYK_NYUKYO_DATE
          KYK_REMARK
          KYK_SDK_KKY_ID
          KYK_SDK_KKY_NAME
          KYK_SEK_DIV_NAME
          KYK_SEK_LIMIT_DATE
          KYK_STATUS_DIV
          KYK_STATUS_DIV_NAME
          KYK_TAIKYOYOKOKU_DIV
          KYK_TAIKYOYOKOKU_DIV_NAME
          KYK_TAIKYOYOKOKU_NUM
          KYK_TAIKYO_SEIKYU_01_KINGAKU
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_01_TKM_CD
          KYK_TAIKYO_SEIKYU_01_TKM_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_02_TKM_CD
          KYK_TAIKYO_SEIKYU_02_TKM_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_03_TKM_CD
          KYK_TAIKYO_SEIKYU_03_TKM_NAME
          KYK_TEISYAKU_FLG
          KYK_TEISYAKU_TERM_DATE
          KYK_TEISYAKU_TERM_DIV
          KYK_TEISYAKU_TERM_DIV_NAME
          KYK_TEISYAKU_TERM_MN
          KYK_TEISYAKU_TERM_YR
          KYK_TINRYO_HASSEI_DATE
          KYK_UPDATED_DATE
          KYK_ZIJ_SEK_TSY_KKY_ID
          KYK_ZIJ_SEK_TSY_KKY_NAME
        }
        knr_keiyaku_r_getsuji {
          KYK_GTJ_DEL_FLG
          KYK_GTJ_ID
          KYK_GTJ_INSERTED_DATE
          KYK_GTJ_ISP_KYK_RKM_01_KYK_ID
          KYK_GTJ_ISP_KYK_RKM_01_SORT_NO
          KYK_GTJ_KINGAKU
          KYK_GTJ_KINGAKU_TAX
          KYK_GTJ_KYK_ID
          KYK_GTJ_SEK_DIV
          KYK_GTJ_SEK_DIV_NAME
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_GTJ_TAX_DIV
          KYK_GTJ_TAX_DIV_NAME
          KYK_GTJ_TKM_CD
          KYK_GTJ_TKM_NAME
          KYK_GTJ_UPDATED_DATE
          KYK_GTJ_ZIJ_FLG
          tax_flg
          total_amount
        }
        mst_heya {
          HYA_BALCONY_MENSEKI
          HYA_BOX_NUMBER
          HYA_BSH_START_DATE
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_DEL_FLG
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_KINGAKU
          HYA_HOKEN_YR
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_ID
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_INSERTED_DATE
          HYA_ISP_KRS_ID
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_KAIYAKU_DATE
          HYA_KEY_PLACE
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHIN_YR
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_MDR_NUM
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_NYUKYOKANO_DATE
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_REMARK
          HYA_ROOM_NO
          HYA_SENYU_MENSEKI
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
          HYA_TAIKYO_DATE
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_TTY_ID
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_UPDATED_DATE
        }
        mst_kokyaku_copy {
          company_id
          kky_id
          kky_legalperson_div
          kky_name
          kky_name_kana
          kky_tel_01
          kky_tel_02
          status
        }
        mst_kokyaku_kyk_hsn_01 {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_kokyaku_kyk_hsn_02 {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_kokyaku_kyk_kys {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_kokyaku_kyk_nky {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        room {
          building_id
          company_id
          created_at
          deleted_at
          display_order
          hya_id
          id
          remark
          status
          updated_at
          is_manually_disabled
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getContractIdList = /* GraphQL */ `
  query GetContractIdList(
    $filter: TableContractIdListFilterInput
    $limit: Int
    $offset: Int
  ) {
    getContractIdList(filter: $filter, limit: $limit, offset: $offset) {
      id
    }
  }
`
export const getCustomerHistoryMessageList = /* GraphQL */ `
  query GetCustomerHistoryMessageList(
    $filter: TableCustomerHistoryMessageFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCustomerHistoryMessageList(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      customer_history_messages {
        customer_history_thread_id
        customer_history_message_type_id
        message
        created_at
        updated_user
        updated_user_status
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getCustomerHistoryThreadList = /* GraphQL */ `
  query GetCustomerHistoryThreadList(
    $filter: TableCustomerHistoryThreadFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCustomerHistoryThreadList(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      data {
        customer_history_thread {
          id
          original_data_id
          created_at
          status
          large_category {
            company_id
            created_at
            deleted_at
            display_order
            id
            name
            parent_id
            status
            updated_at
            published_count
            unpublished_count
            icon
          }
          medium_category {
            company_id
            created_at
            deleted_at
            display_order
            id
            name
            parent_id
            status
            updated_at
            published_count
            unpublished_count
            icon
          }
          application_type {
            created_at
            deleted_at
            id
            name
            status
            updated_at
          }
          other_activity_type {
            id
            name
            created_at
            updated_at
            deleted_at
          }
          type
          updated_at
          updated_user {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
        }
        customer_history_messages {
          customer_history_thread_id
          customer_history_message_type_id
          message
          created_at
          updated_user
          updated_user_status
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getCustomerHistoryTypeList = /* GraphQL */ `
  query GetCustomerHistoryTypeList(
    $filter: TableCustomerHistoryTypeFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCustomerHistoryTypeList(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      data {
        created_at
        deleted_at
        id
        name
        status
        updated_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getCustomerList = /* GraphQL */ `
  query GetCustomerList(
    $filter: TableCustomerFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCustomerList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        appUser {
          attachment_id
          created_at
          deleted_at
          id
          is_activation
          last_login_at
          password
          registered_at
          status
          updated_at
          username
        }
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        customer {
          app_user_id
          company_id
          created_at
          deleted_at
          id
          iot_provider_id
          is_activation
          is_usage
          kky_id
          password
          registered_at
          remark
          status
          updated_at
          username
        }
        kYKNKYKKYIDKNRKEIYAKUS {
          KYK_CSV_ID
          KYK_DEL_FLG
          KYK_FREERENT_FLG
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_GYS_KKY_ID
          KYK_GYS_KKY_NAME
          KYK_GYS_SHN_NAME
          KYK_HOKEN_GYS_KKY_ID
          KYK_HOKEN_GYS_KKY_NAME
          KYK_HOKEN_KEIYAKU_END_DATE
          KYK_HOKEN_KEIYAKU_STR_DATE
          KYK_HOKEN_KINGAKU
          KYK_HOKEN_KINGAKU_TAX
          KYK_HOKEN_KOSHIN_YR
          KYK_HOKEN_SYOKEN_NO
          KYK_HOSYO_GYS_KKY_ID
          KYK_HOSYO_GYS_KKY_NAME
          KYK_HOSYO_KEIYAKU_END_DATE
          KYK_HOSYO_KEIYAKU_STR_DATE
          KYK_HOSYO_KINGAKU
          KYK_HOSYO_KINGAKU_TAX
          KYK_HOSYO_KOSHIN_YR
          KYK_HOSYO_SYOKEN_NO
          KYK_HSN_01_KKY_ID
          KYK_HSN_01_KKY_NAME
          KYK_HSN_01_ZKG_CD
          KYK_HSN_01_ZKG_NAME
          KYK_HSN_02_KKY_ID
          KYK_HSN_02_KKY_NAME
          KYK_HSN_02_ZKG_CD
          KYK_HSN_02_ZKG_NAME
          KYK_HYA_ID
          KYK_ID
          KYK_INSERTED_DATE
          KYK_ISP_KYK_ID
          KYK_KAIYAKU_ADV_CD
          KYK_KAIYAKU_ADV_NAME
          KYK_KAIYAKU_BNB_CD
          KYK_KAIYAKU_BNB_NAME
          KYK_KAIYAKU_BNK_CD
          KYK_KAIYAKU_BNK_NAME
          KYK_KAIYAKU_DATE
          KYK_KAIYAKU_HENKIN_YOTEI_DATE
          KYK_KAIYAKU_KNK_FB_FLG
          KYK_KAIYAKU_MEIGI
          KYK_KAIYAKU_MEIGI_KANA
          KYK_KAIYAKU_NUMBER
          KYK_KAIYAKU_REMARK
          KYK_KAIYAKU_SEK_END_MN
          KYK_KAIYAKU_SEK_END_YR
          KYK_KAIYAKU_SSN_HAKKO_DATE
          KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
          KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
          KYK_KAIYAKU_TACHIAI_YOTEI_DATE
          KYK_KAIYAKU_TAIKYO_YOTEI_DATE
          KYK_KAIYAKU_TKG_ADDR_01
          KYK_KAIYAKU_TKG_ADDR_02
          KYK_KAIYAKU_TKG_ATENA
          KYK_KAIYAKU_TKG_CTY_CD
          KYK_KAIYAKU_TKG_CTY_NAME
          KYK_KAIYAKU_TKG_PRF_CD
          KYK_KAIYAKU_TKG_PRF_NAME
          KYK_KAIYAKU_TKG_TEL
          KYK_KAIYAKU_TKG_TKR_CD
          KYK_KAIYAKU_TKG_TKR_NAME
          KYK_KAIYAKU_TKG_TWN_CD
          KYK_KAIYAKU_TKG_TWN_NAME
          KYK_KAIYAKU_TKG_ZIPCODE
          KYK_KAIYAKU_TSUCHI_DATE
          KYK_KAIYAKU_YOTEI_DATE
          KYK_KEIYAKU_DATE
          KYK_KEIYAKU_END_DATE
          KYK_KEIYAKU_STR_DATE
          KYK_KOSHINJIMURYO_CALC_DIV
          KYK_KOSHINJIMURYO_CALC_DIV_NAME
          KYK_KOSHINJIMURYO_KINGAKU
          KYK_KOSHINJIMURYO_KINGAKU_TAX
          KYK_KOSHINJIMURYO_MN
          KYK_KOSHINRYO_CALC_DIV
          KYK_KOSHINRYO_CALC_DIV_NAME
          KYK_KOSHINRYO_KINGAKU
          KYK_KOSHINRYO_KINGAKU_TAX
          KYK_KOSHINRYO_MN
          KYK_KOSHINTESURYO_KINGAKU
          KYK_KOSHINTESURYO_KINGAKU_TAX
          KYK_KOSHINTESURYO_PER
          KYK_KOSHINYOKOKU_DIV
          KYK_KOSHINYOKOKU_DIV_NAME
          KYK_KOSHINYOKOKU_NUM
          KYK_KOSHIN_AUTO_FLG
          KYK_KOSHIN_YR
          KYK_KYS_KKY_ID
          KYK_KYS_KKY_NAME
          KYK_MEMBER_CD
          KYK_MEMBER_CD_2
          KYK_MEMBER_FLG
          KYK_MEMBER_FLG_2
          KYK_MEMBER_NAME
          KYK_MEMBER_NAME_2
          KYK_MSK_ID
          KYK_NKY_KKY_ID
          KYK_NKY_KKY_NAME
          KYK_NKY_ZKG_CD
          KYK_NKY_ZKG_NAME
          KYK_NYUKYO_DATE
          KYK_REMARK
          KYK_SDK_KKY_ID
          KYK_SDK_KKY_NAME
          KYK_SEK_DIV_NAME
          KYK_SEK_LIMIT_DATE
          KYK_STATUS_DIV
          KYK_STATUS_DIV_NAME
          KYK_TAIKYOYOKOKU_DIV
          KYK_TAIKYOYOKOKU_DIV_NAME
          KYK_TAIKYOYOKOKU_NUM
          KYK_TAIKYO_SEIKYU_01_KINGAKU
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_01_TKM_CD
          KYK_TAIKYO_SEIKYU_01_TKM_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_02_TKM_CD
          KYK_TAIKYO_SEIKYU_02_TKM_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_03_TKM_CD
          KYK_TAIKYO_SEIKYU_03_TKM_NAME
          KYK_TEISYAKU_FLG
          KYK_TEISYAKU_TERM_DATE
          KYK_TEISYAKU_TERM_DIV
          KYK_TEISYAKU_TERM_DIV_NAME
          KYK_TEISYAKU_TERM_MN
          KYK_TEISYAKU_TERM_YR
          KYK_TINRYO_HASSEI_DATE
          KYK_UPDATED_DATE
          KYK_ZIJ_SEK_TSY_KKY_ID
          KYK_ZIJ_SEK_TSY_KKY_NAME
        }
        mstkokyaku {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        tenantContracts {
          building_id
          company_id
          contractor_id
          created_at
          deleted_at
          id
          kyk_id
          kyk_keiyaku_end_date
          kyk_keiyaku_str_date
          kyk_status_div
          room_id
          status
          tenant_id
          updated_at
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getDashboard = /* GraphQL */ `
  query GetDashboard {
    getDashboard {
      totalAppUsers
      appUserDaily
      appUserMonthly
      appUserWeekly
      applicationBicycleParkingStickerOpen
      applicationBicycleParkingStickerInProgress
      applicationBicycleParkingStickerInProgressAndNoReply
      applicationBicycleParkingStickerPending
      applicationBicycleParkingStickerPendingAndNoReply
      applicationCertificateOfConsentToUseParkingSpaceOpen
      applicationCertificateOfConsentToUseParkingSpaceInProgress
      applicationCertificateOfConsentToUseParkingSpaceInProgressAndNoReply
      applicationCertificateOfConsentToUseParkingSpacePending
      applicationCertificateOfConsentToUseParkingSpacePendingAndNoReply
      applicationPetOpen
      applicationPetInProgress
      applicationPetInProgressAndNoReply
      applicationPetPending
      applicationPetPendingAndNoReply
      applicationResidentCancellationOpen
      applicationResidentCancellationInProgress
      applicationResidentCancellationInProgressAndNoReply
      applicationResidentCancellationPending
      applicationResidentCancellationPendingAndNoReply
      applicationResidentInfoChangeOpen
      applicationResidentInfoChangeInProgress
      applicationResidentInfoChangeInProgressAndNoReply
      applicationResidentInfoChangePending
      applicationResidentInfoChangePendingAndNoReply
      applicationRoomCheckOpen
      applicationRoomCheckInProgress
      applicationRoomCheckInProgressAndNoReply
      applicationRoomCheckPending
      applicationRoomCheckPendingAndNoReply
      dailyAppUsers
      weeklyAppUsers
      MonthlyAppUsers
      messageOpen
      messageInProgress
      messageInProgressAndNoReply
      messagePending
      messagePendingAndNoReply
      messageComplete
      pk
      sk
      totalBuildings
      totalAvailableBuildings
      totalRooms
      totalAvailableRooms
      totalAvailableResidentialRooms
      totalAvailableOtherRooms
      totalContracts
      totalResidents
      totalResidentialContracts
      totalOtherContracts
    }
  }
`
export const getDataLinkFailureRecipients = /* GraphQL */ `
  query GetDataLinkFailureRecipients {
    getDataLinkFailureRecipients {
      mail_address
    }
  }
`
export const getDataLinkHistoryList = /* GraphQL */ `
  query GetDataLinkHistoryList(
    $filter: TableDataLinkHistoryFilterInput
    $limit: Int
    $offset: Int
  ) {
    getDataLinkHistoryList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        data_link_history {
          attachment_id
          company_id
          created_at
          data_link_state_id
          data_link_type_id
          data_type_id
          deleted_at
          id
          status
          updated_at
          created_by
        }
        error_attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        createdUser {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getDataLinkTypeList = /* GraphQL */ `
  query GetDataLinkTypeList($filter: TableDataLinkTypeFilterInput) {
    getDataLinkTypeList(filter: $filter) {
      data {
        created_at
        deleted_at
        id
        name
        status
        updated_at
      }
    }
  }
`
export const getExternalSystemList = /* GraphQL */ `
  query GetExternalSystemList($limit: Int, $offset: Int) {
    getExternalSystemList(limit: $limit, offset: $offset) {
      data {
        created_at
        deleted_at
        display_order
        id
        name
        status
        updated_at
      }
    }
  }
`
export const getFaq = /* GraphQL */ `
  query GetFaq($faqId: Int) {
    getFaq(faqId: $faqId) {
      faq {
        answer
        category_id
        company_id
        created_at
        created_by
        deleted_at
        deleted_by
        display_order
        id
        question
        status
        updated_at
        updated_by
        referenced_count
        ranking
        url
        url_text
      }
      large_category {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        parent_id
        status
        updated_at
        published_count
        unpublished_count
        icon
      }
      medium_category {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        parent_id
        status
        updated_at
        published_count
        unpublished_count
        icon
      }
      updated_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
    }
  }
`
export const getFaqAttachment = /* GraphQL */ `
  query GetFaqAttachment($faqId: Int) {
    getFaqAttachment(faqId: $faqId) {
      img_attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      movie_attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      pdf_attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
    }
  }
`
export const getFaqAttachment2 = /* GraphQL */ `
  query GetFaqAttachment2($faqId: Int) {
    getFaqAttachment2(faqId: $faqId) {
      img_attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      movie_attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      pdf_attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
    }
  }
`
export const getFaqList = /* GraphQL */ `
  query GetFaqList($filter: TableFaqFilterInput, $limit: Int, $offset: Int) {
    getFaqList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        faq {
          answer
          category_id
          company_id
          created_at
          created_by
          deleted_at
          deleted_by
          display_order
          id
          question
          status
          updated_at
          updated_by
          referenced_count
          ranking
          url
          url_text
        }
        large_category {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          parent_id
          status
          updated_at
          published_count
          unpublished_count
          icon
        }
        medium_category {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          parent_id
          status
          updated_at
          published_count
          unpublished_count
          icon
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getFaqListBySearchCondition = /* GraphQL */ `
  query GetFaqListBySearchCondition(
    $filter: FaqAndMediumCategoryAndLargeCategoryAndAttachmentTypeSearchConditon
  ) {
    getFaqListBySearchCondition(filter: $filter) {
      data {
        faq {
          answer
          category_id
          company_id
          created_at
          created_by
          deleted_at
          deleted_by
          display_order
          id
          question
          status
          updated_at
          updated_by
          referenced_count
          ranking
          url
          url_text
        }
        faq_attachment_type {
          attachment_type_names
          faq_id
        }
        large_category {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          parent_id
          status
          updated_at
          published_count
          unpublished_count
          icon
        }
        medium_category {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          parent_id
          status
          updated_at
          published_count
          unpublished_count
          icon
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getInsertableCsvItem = /* GraphQL */ `
  query GetInsertableCsvItem($listId: Int!) {
    getInsertableCsvItem(listId: $listId) {
      insertable_csv_items
    }
  }
`
export const getKeyReceiptApplication = /* GraphQL */ `
  query GetKeyReceiptApplication(
    $filter: TableKeyReceiptApplicationFilterInput
  ) {
    getKeyReceiptApplication(filter: $filter) {
      application {
        application_state_id
        application_type_id
        building_id
        company_id
        content
        contract_id
        created_at
        customer_id
        deleted_at
        id
        last_user_notice_at
        remark
        room_id
        status
        updated_at
      }
      attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      arrangement_status
      is_arranged
      mst_heya {
        HYA_BALCONY_MENSEKI
        HYA_BOX_NUMBER
        HYA_BSH_START_DATE
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_DEL_FLG
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_KINGAKU
        HYA_HOKEN_YR
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_ID
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_INSERTED_DATE
        HYA_ISP_KRS_ID
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_KAIYAKU_DATE
        HYA_KEY_PLACE
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHIN_YR
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_MDR_NUM
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_NYUKYOKANO_DATE
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_REMARK
        HYA_ROOM_NO
        HYA_SENYU_MENSEKI
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
        HYA_TAIKYO_DATE
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_TTY_ID
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_UPDATED_DATE
      }
      mst_kokyaku_kys {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_kokyaku_nky {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_tateya {
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_COMPLETION_DATE
        TTY_CSV_ID
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_DEL_FLG
        TTY_GROUND_FLOOR_NUM
        TY_GRP_NAME
        TTY_ID
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_INSERTED_DATE
        TTY_ISP_KRM_ID
        TTY_ISP_TTY_ID
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_KNR_DIV
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_NO
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNRPATTERN_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_NAME
        TTY_NAME_KANA
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_TIME
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NOBEYUKA_MENSEKI
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_REMARK
        TTY_SOG_DAY
        TTY_SOG_ID
        TTY_SOG_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TNP_NAME
        TTY_TOTAL_COUNT
        TTY_TOTI_MENSEKI
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_UGGROUND_FLOOR_NUM
        TTY_UPDATED_DATE
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_ZIPCODE
        TTY_GRP_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      assigned_user_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      assigned_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      application_memos {
        id
        application_id
        memo
        company_user_id
        created_at
        updated_at
        deleted_at
      }
      application_tags {
        id
        status
        body
        colorCode
        createdAt
        createdBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updatedAt
        updatedBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        deletedAt
      }
      thread {
        application_id
        assigned_by
        assigned_group_by
        building_id
        category_id
        company_id
        contract_id
        created_at
        customer_id
        deleted_at
        exchanged_at
        exchanged_by
        id
        is_reply
        room_id
        status
        thread_state_id
        updated_at
      }
      app_usage_status
      exchanged_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      exchanged_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      room_check_list_application_id
    }
  }
`
export const getKeyReceiptApplicationList = /* GraphQL */ `
  query GetKeyReceiptApplicationList(
    $filter: TableKeyReceiptApplicationFilterInput
    $limit: Int
    $offset: Int
  ) {
    getKeyReceiptApplicationList(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      data {
        application {
          application_state_id
          application_type_id
          building_id
          company_id
          content
          contract_id
          created_at
          customer_id
          deleted_at
          id
          last_user_notice_at
          remark
          room_id
          status
          updated_at
        }
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        arrangement_status
        is_arranged
        mst_heya {
          HYA_BALCONY_MENSEKI
          HYA_BOX_NUMBER
          HYA_BSH_START_DATE
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_DEL_FLG
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_KINGAKU
          HYA_HOKEN_YR
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_ID
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_INSERTED_DATE
          HYA_ISP_KRS_ID
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_KAIYAKU_DATE
          HYA_KEY_PLACE
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHIN_YR
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_MDR_NUM
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_NYUKYOKANO_DATE
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_REMARK
          HYA_ROOM_NO
          HYA_SENYU_MENSEKI
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
          HYA_TAIKYO_DATE
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_TTY_ID
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_UPDATED_DATE
        }
        mst_kokyaku_kys {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_kokyaku_nky {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        assigned_user_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        application_memos {
          id
          application_id
          memo
          company_user_id
          created_at
          updated_at
          deleted_at
        }
        application_tags {
          id
          status
          body
          colorCode
          createdAt
          createdBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          updatedAt
          updatedBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          deletedAt
        }
        thread {
          application_id
          assigned_by
          assigned_group_by
          building_id
          category_id
          company_id
          contract_id
          created_at
          customer_id
          deleted_at
          exchanged_at
          exchanged_by
          id
          is_reply
          room_id
          status
          thread_state_id
          updated_at
        }
        app_usage_status
        exchanged_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        exchanged_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        room_check_list_application_id
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getKykIdList = /* GraphQL */ `
  query GetKykIdList {
    getKykIdList {
      kyk_id
      coordination_id
    }
  }
`
export const getLargeCategoryAndMediumCategory = /* GraphQL */ `
  query GetLargeCategoryAndMediumCategory($category_type: Int) {
    getLargeCategoryAndMediumCategory(category_type: $category_type) {
      large_categories {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        parent_id
        status
        updated_at
        published_count
        unpublished_count
        icon
      }
      medium_categories {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        parent_id
        status
        updated_at
        published_count
        unpublished_count
        icon
      }
    }
  }
`
export const getMSTKokyakuSKGList = /* GraphQL */ `
  query GetMSTKokyakuSKGList {
    getMSTKokyakuSKGList {
      data {
        id
        skg_cd
        skg_name
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getMSTTateyaKNRDivList = /* GraphQL */ `
  query GetMSTTateyaKNRDivList {
    getMSTTateyaKNRDivList {
      data {
        id
        knr_cd
        knr_name
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getMSTTateyaKnrkeitaiDivList = /* GraphQL */ `
  query GetMSTTateyaKnrkeitaiDivList {
    getMSTTateyaKnrkeitaiDivList {
      data {
        id
        knrkeitai_cd
        knrkeitai_name
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getMSTTateyaTysList = /* GraphQL */ `
  query GetMSTTateyaTysList {
    getMSTTateyaTysList {
      data {
        id
        tys_cd
        tys_name
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getMessage = /* GraphQL */ `
  query GetMessage($filter: TableMessageFilterInput, $owner_id: Int) {
    getMessage(filter: $filter, owner_id: $owner_id) {
      attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      message {
        body
        company_id
        company_user_id
        created_at
        customer_id
        deleted_at
        id
        is_read
        message_type_id
        status
        thread_id
        updated_at
        bulk_delivery_message_id
      }
    }
  }
`
export const getMessageAutoReply = /* GraphQL */ `
  query GetMessageAutoReply($filter: TableMessageAutoReplyFilterInput) {
    getMessageAutoReply(filter: $filter) {
      company_id
      created_at
      deleted_at
      end
      caution_message
      id
      message_body
      start
      status
      updated_at
      title
    }
  }
`
export const getMessageList = /* GraphQL */ `
  query GetMessageList(
    $filter: TableMessageFilterInput
    $limit: Int
    $offset: Int
    $is_owner: Boolean
  ) {
    getMessageList(
      filter: $filter
      limit: $limit
      offset: $offset
      is_owner: $is_owner
    ) {
      data {
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        message {
          body
          company_id
          company_user_id
          created_at
          customer_id
          deleted_at
          id
          is_read
          message_type_id
          status
          thread_id
          updated_at
          bulk_delivery_message_id
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getMessageTemplate = /* GraphQL */ `
  query GetMessageTemplate($filter: TableMessageTemplateFilterInput) {
    getMessageTemplate(filter: $filter) {
      category {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        parent_id
        status
        updated_at
        published_count
        unpublished_count
        icon
      }
      message_template {
        body
        category_id
        company_id
        created_at
        deleted_at
        display_order
        id
        status
        title
        updated_at
      }
      updated_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      parent_category {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        parent_id
        status
        updated_at
        published_count
        unpublished_count
        icon
      }
    }
  }
`
export const getMessageTemplateList = /* GraphQL */ `
  query GetMessageTemplateList(
    $filter: TableMessageTemplateFilterInput
    $limit: Int
    $offset: Int
  ) {
    getMessageTemplateList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        category {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          parent_id
          status
          updated_at
          published_count
          unpublished_count
          icon
        }
        message_template {
          body
          category_id
          company_id
          created_at
          deleted_at
          display_order
          id
          status
          title
          updated_at
        }
        updated_by {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        parent_category {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          parent_id
          status
          updated_at
          published_count
          unpublished_count
          icon
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getMessageTypeList = /* GraphQL */ `
  query GetMessageTypeList($filter: TableMessageTypeFilterInput) {
    getMessageTypeList(filter: $filter) {
      data {
        created_at
        deleted_at
        display_order
        id
        name
        status
        updated_at
      }
    }
  }
`
export const getNoticeList = /* GraphQL */ `
  query GetNoticeList(
    $filter: TableNoticeFilterInput
    $limit: Int
    $offset: Int
  ) {
    getNoticeList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        body
        created_at
        deleted_at
        id
        status
        updated_at
        url
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getOtherCustomerActivityMemos = /* GraphQL */ `
  query GetOtherCustomerActivityMemos(
    $filter: TableOtherCustomerActivityMemoFilterInput
  ) {
    getOtherCustomerActivityMemos(filter: $filter) {
      data {
        id
        memo
        updater {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
      }
    }
  }
`
export const getPackage = /* GraphQL */ `
  query GetPackage($id: Int) {
    getPackage(id: $id) {
      attachments {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      title_attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      package {
        body
        company_id
        created_at
        created_by
        deleted_at
        deleted_by
        display_order
        is_display_top
        referenced_count
        id
        name
        status
        updated_at
        updated_by
        description
        title_attachment_id
        url
        link_text
      }
      updated_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
    }
  }
`
export const getPackageList = /* GraphQL */ `
  query GetPackageList(
    $filter: TablePackageFilterInput
    $limit: Int
    $offset: Int
  ) {
    getPackageList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        attachments {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        title_attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        package {
          body
          company_id
          created_at
          created_by
          deleted_at
          deleted_by
          display_order
          is_display_top
          referenced_count
          id
          name
          status
          updated_at
          updated_by
          description
          title_attachment_id
          url
          link_text
        }
        updated_by {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
      }
      package_size {
        available_slot_size
        top_page_display_size
        remark
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getPetApplication = /* GraphQL */ `
  query GetPetApplication($filter: TablePetApplicationFilterInput) {
    getPetApplication(filter: $filter) {
      application {
        application_state_id
        application_type_id
        building_id
        company_id
        content
        contract_id
        created_at
        customer_id
        deleted_at
        id
        last_user_notice_at
        remark
        room_id
        status
        updated_at
      }
      attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      arrangement_status
      is_arranged
      mst_heya {
        HYA_BALCONY_MENSEKI
        HYA_BOX_NUMBER
        HYA_BSH_START_DATE
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_DEL_FLG
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_KINGAKU
        HYA_HOKEN_YR
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_ID
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_INSERTED_DATE
        HYA_ISP_KRS_ID
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_KAIYAKU_DATE
        HYA_KEY_PLACE
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHIN_YR
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_MDR_NUM
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_NYUKYOKANO_DATE
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_REMARK
        HYA_ROOM_NO
        HYA_SENYU_MENSEKI
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
        HYA_TAIKYO_DATE
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_TTY_ID
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_UPDATED_DATE
      }
      mst_kokyaku_kys {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_kokyaku_nky {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_tateya {
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_COMPLETION_DATE
        TTY_CSV_ID
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_DEL_FLG
        TTY_GROUND_FLOOR_NUM
        TY_GRP_NAME
        TTY_ID
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_INSERTED_DATE
        TTY_ISP_KRM_ID
        TTY_ISP_TTY_ID
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_KNR_DIV
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_NO
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNRPATTERN_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_NAME
        TTY_NAME_KANA
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_TIME
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NOBEYUKA_MENSEKI
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_REMARK
        TTY_SOG_DAY
        TTY_SOG_ID
        TTY_SOG_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TNP_NAME
        TTY_TOTAL_COUNT
        TTY_TOTI_MENSEKI
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_UGGROUND_FLOOR_NUM
        TTY_UPDATED_DATE
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_ZIPCODE
        TTY_GRP_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      assigned_user_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      assigned_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      application_memos {
        id
        application_id
        memo
        company_user_id
        created_at
        updated_at
        deleted_at
      }
      application_tags {
        id
        status
        body
        colorCode
        createdAt
        createdBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updatedAt
        updatedBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        deletedAt
      }
      thread {
        application_id
        assigned_by
        assigned_group_by
        building_id
        category_id
        company_id
        contract_id
        created_at
        customer_id
        deleted_at
        exchanged_at
        exchanged_by
        id
        is_reply
        room_id
        status
        thread_state_id
        updated_at
      }
      app_usage_status
      exchanged_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      exchanged_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      room_check_list_application_id
    }
  }
`
export const getPetApplicationList = /* GraphQL */ `
  query GetPetApplicationList(
    $filter: TablePetApplicationFilterInput
    $limit: Int
    $offset: Int
  ) {
    getPetApplicationList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        application {
          application_state_id
          application_type_id
          building_id
          company_id
          content
          contract_id
          created_at
          customer_id
          deleted_at
          id
          last_user_notice_at
          remark
          room_id
          status
          updated_at
        }
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        arrangement_status
        is_arranged
        mst_heya {
          HYA_BALCONY_MENSEKI
          HYA_BOX_NUMBER
          HYA_BSH_START_DATE
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_DEL_FLG
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_KINGAKU
          HYA_HOKEN_YR
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_ID
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_INSERTED_DATE
          HYA_ISP_KRS_ID
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_KAIYAKU_DATE
          HYA_KEY_PLACE
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHIN_YR
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_MDR_NUM
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_NYUKYOKANO_DATE
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_REMARK
          HYA_ROOM_NO
          HYA_SENYU_MENSEKI
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
          HYA_TAIKYO_DATE
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_TTY_ID
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_UPDATED_DATE
        }
        mst_kokyaku_kys {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_kokyaku_nky {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        assigned_user_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        application_memos {
          id
          application_id
          memo
          company_user_id
          created_at
          updated_at
          deleted_at
        }
        application_tags {
          id
          status
          body
          colorCode
          createdAt
          createdBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          updatedAt
          updatedBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          deletedAt
        }
        thread {
          application_id
          assigned_by
          assigned_group_by
          building_id
          category_id
          company_id
          contract_id
          created_at
          customer_id
          deleted_at
          exchanged_at
          exchanged_by
          id
          is_reply
          room_id
          status
          thread_state_id
          updated_at
        }
        app_usage_status
        exchanged_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        exchanged_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        room_check_list_application_id
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getPrefectureList = /* GraphQL */ `
  query GetPrefectureList {
    getPrefectureList {
      data {
        created_at
        deleted_at
        display_order
        id
        name
        name_kana
        prf_cd
        status
        updated_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getRecentMessageList = /* GraphQL */ `
  query GetRecentMessageList($isUnsupported: Boolean) {
    getRecentMessageList(isUnsupported: $isUnsupported) {
      threadId
      body
      buildingName
      RoomNo
      sendAt
      senderName
      isOwner
    }
  }
`
export const getResidentCancellationApplication = /* GraphQL */ `
  query GetResidentCancellationApplication(
    $filter: TableResidentCancellationApplicationFilterInput
  ) {
    getResidentCancellationApplication(filter: $filter) {
      application {
        application_state_id
        application_type_id
        building_id
        company_id
        content
        contract_id
        created_at
        customer_id
        deleted_at
        id
        last_user_notice_at
        remark
        room_id
        status
        updated_at
      }
      attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      arrangement_status
      is_arranged
      mst_heya {
        HYA_BALCONY_MENSEKI
        HYA_BOX_NUMBER
        HYA_BSH_START_DATE
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_DEL_FLG
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_KINGAKU
        HYA_HOKEN_YR
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_ID
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_INSERTED_DATE
        HYA_ISP_KRS_ID
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_KAIYAKU_DATE
        HYA_KEY_PLACE
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHIN_YR
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_MDR_NUM
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_NYUKYOKANO_DATE
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_REMARK
        HYA_ROOM_NO
        HYA_SENYU_MENSEKI
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
        HYA_TAIKYO_DATE
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_TTY_ID
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_UPDATED_DATE
      }
      mst_kokyaku_kys {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_kokyaku_nky {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_tateya {
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_COMPLETION_DATE
        TTY_CSV_ID
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_DEL_FLG
        TTY_GROUND_FLOOR_NUM
        TY_GRP_NAME
        TTY_ID
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_INSERTED_DATE
        TTY_ISP_KRM_ID
        TTY_ISP_TTY_ID
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_KNR_DIV
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_NO
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNRPATTERN_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_NAME
        TTY_NAME_KANA
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_TIME
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NOBEYUKA_MENSEKI
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_REMARK
        TTY_SOG_DAY
        TTY_SOG_ID
        TTY_SOG_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TNP_NAME
        TTY_TOTAL_COUNT
        TTY_TOTI_MENSEKI
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_UGGROUND_FLOOR_NUM
        TTY_UPDATED_DATE
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_ZIPCODE
        TTY_GRP_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      assigned_user_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      assigned_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      application_memos {
        id
        application_id
        memo
        company_user_id
        created_at
        updated_at
        deleted_at
      }
      application_tags {
        id
        status
        body
        colorCode
        createdAt
        createdBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updatedAt
        updatedBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        deletedAt
      }
      thread {
        application_id
        assigned_by
        assigned_group_by
        building_id
        category_id
        company_id
        contract_id
        created_at
        customer_id
        deleted_at
        exchanged_at
        exchanged_by
        id
        is_reply
        room_id
        status
        thread_state_id
        updated_at
      }
      app_usage_status
      exchanged_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      exchanged_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      room_check_list_application_id
    }
  }
`
export const getResidentCancellationApplicationCsv = /* GraphQL */ `
  query GetResidentCancellationApplicationCsv(
    $filter: TableResidentCancellationApplicationFilterInput
    $limit: Int
    $offset: Int
  ) {
    getResidentCancellationApplicationCsv(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      companyId
      key
    }
  }
`
export const getResidentCancellationApplicationList = /* GraphQL */ `
  query GetResidentCancellationApplicationList(
    $filter: TableResidentCancellationApplicationFilterInput
    $limit: Int
    $offset: Int
  ) {
    getResidentCancellationApplicationList(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      data {
        application {
          application_state_id
          application_type_id
          building_id
          company_id
          content
          contract_id
          created_at
          customer_id
          deleted_at
          id
          last_user_notice_at
          remark
          room_id
          status
          updated_at
        }
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        arrangement_status
        is_arranged
        mst_heya {
          HYA_BALCONY_MENSEKI
          HYA_BOX_NUMBER
          HYA_BSH_START_DATE
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_DEL_FLG
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_KINGAKU
          HYA_HOKEN_YR
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_ID
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_INSERTED_DATE
          HYA_ISP_KRS_ID
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_KAIYAKU_DATE
          HYA_KEY_PLACE
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHIN_YR
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_MDR_NUM
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_NYUKYOKANO_DATE
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_REMARK
          HYA_ROOM_NO
          HYA_SENYU_MENSEKI
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
          HYA_TAIKYO_DATE
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_TTY_ID
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_UPDATED_DATE
        }
        mst_kokyaku_kys {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_kokyaku_nky {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        assigned_user_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        application_memos {
          id
          application_id
          memo
          company_user_id
          created_at
          updated_at
          deleted_at
        }
        application_tags {
          id
          status
          body
          colorCode
          createdAt
          createdBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          updatedAt
          updatedBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          deletedAt
        }
        thread {
          application_id
          assigned_by
          assigned_group_by
          building_id
          category_id
          company_id
          contract_id
          created_at
          customer_id
          deleted_at
          exchanged_at
          exchanged_by
          id
          is_reply
          room_id
          status
          thread_state_id
          updated_at
        }
        app_usage_status
        exchanged_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        exchanged_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        room_check_list_application_id
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getResidentInfoChangeApplication = /* GraphQL */ `
  query GetResidentInfoChangeApplication(
    $filter: TableResidentInfoChangeApplicationFilterInput
  ) {
    getResidentInfoChangeApplication(filter: $filter) {
      application {
        application_state_id
        application_type_id
        building_id
        company_id
        content
        contract_id
        created_at
        customer_id
        deleted_at
        id
        last_user_notice_at
        remark
        room_id
        status
        updated_at
      }
      attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      arrangement_status
      is_arranged
      mst_heya {
        HYA_BALCONY_MENSEKI
        HYA_BOX_NUMBER
        HYA_BSH_START_DATE
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_DEL_FLG
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_KINGAKU
        HYA_HOKEN_YR
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_ID
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_INSERTED_DATE
        HYA_ISP_KRS_ID
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_KAIYAKU_DATE
        HYA_KEY_PLACE
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHIN_YR
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_MDR_NUM
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_NYUKYOKANO_DATE
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_REMARK
        HYA_ROOM_NO
        HYA_SENYU_MENSEKI
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
        HYA_TAIKYO_DATE
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_TTY_ID
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_UPDATED_DATE
      }
      mst_kokyaku_kys {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_kokyaku_nky {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_tateya {
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_COMPLETION_DATE
        TTY_CSV_ID
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_DEL_FLG
        TTY_GROUND_FLOOR_NUM
        TY_GRP_NAME
        TTY_ID
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_INSERTED_DATE
        TTY_ISP_KRM_ID
        TTY_ISP_TTY_ID
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_KNR_DIV
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_NO
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNRPATTERN_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_NAME
        TTY_NAME_KANA
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_TIME
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NOBEYUKA_MENSEKI
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_REMARK
        TTY_SOG_DAY
        TTY_SOG_ID
        TTY_SOG_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TNP_NAME
        TTY_TOTAL_COUNT
        TTY_TOTI_MENSEKI
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_UGGROUND_FLOOR_NUM
        TTY_UPDATED_DATE
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_ZIPCODE
        TTY_GRP_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      assigned_user_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      assigned_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      application_memos {
        id
        application_id
        memo
        company_user_id
        created_at
        updated_at
        deleted_at
      }
      application_tags {
        id
        status
        body
        colorCode
        createdAt
        createdBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updatedAt
        updatedBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        deletedAt
      }
      thread {
        application_id
        assigned_by
        assigned_group_by
        building_id
        category_id
        company_id
        contract_id
        created_at
        customer_id
        deleted_at
        exchanged_at
        exchanged_by
        id
        is_reply
        room_id
        status
        thread_state_id
        updated_at
      }
      app_usage_status
      exchanged_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      exchanged_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      room_check_list_application_id
    }
  }
`
export const getResidentInfoChangeApplicationList = /* GraphQL */ `
  query GetResidentInfoChangeApplicationList(
    $filter: TableResidentInfoChangeApplicationFilterInput
    $limit: Int
    $offset: Int
  ) {
    getResidentInfoChangeApplicationList(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      data {
        application {
          application_state_id
          application_type_id
          building_id
          company_id
          content
          contract_id
          created_at
          customer_id
          deleted_at
          id
          last_user_notice_at
          remark
          room_id
          status
          updated_at
        }
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        arrangement_status
        is_arranged
        mst_heya {
          HYA_BALCONY_MENSEKI
          HYA_BOX_NUMBER
          HYA_BSH_START_DATE
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_DEL_FLG
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_KINGAKU
          HYA_HOKEN_YR
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_ID
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_INSERTED_DATE
          HYA_ISP_KRS_ID
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_KAIYAKU_DATE
          HYA_KEY_PLACE
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHIN_YR
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_MDR_NUM
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_NYUKYOKANO_DATE
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_REMARK
          HYA_ROOM_NO
          HYA_SENYU_MENSEKI
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
          HYA_TAIKYO_DATE
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_TTY_ID
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_UPDATED_DATE
        }
        mst_kokyaku_kys {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_kokyaku_nky {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        assigned_user_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        application_memos {
          id
          application_id
          memo
          company_user_id
          created_at
          updated_at
          deleted_at
        }
        application_tags {
          id
          status
          body
          colorCode
          createdAt
          createdBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          updatedAt
          updatedBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          deletedAt
        }
        thread {
          application_id
          assigned_by
          assigned_group_by
          building_id
          category_id
          company_id
          contract_id
          created_at
          customer_id
          deleted_at
          exchanged_at
          exchanged_by
          id
          is_reply
          room_id
          status
          thread_state_id
          updated_at
        }
        app_usage_status
        exchanged_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        exchanged_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        room_check_list_application_id
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getRoleList = /* GraphQL */ `
  query GetRoleList {
    getRoleList {
      data {
        created_at
        deleted_at
        display_order
        id
        name
        status
        updated_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getRoom = /* GraphQL */ `
  query GetRoom($filter: TableRoomFilterInput) {
    getRoom(filter: $filter) {
      knr_keiyaku {
        KYK_CSV_ID
        KYK_DEL_FLG
        KYK_FREERENT_FLG
        KYK_GTJ_SEK_TSY_KKY_ID
        KYK_GTJ_SEK_TSY_KKY_NAME
        KYK_GYS_KKY_ID
        KYK_GYS_KKY_NAME
        KYK_GYS_SHN_NAME
        KYK_HOKEN_GYS_KKY_ID
        KYK_HOKEN_GYS_KKY_NAME
        KYK_HOKEN_KEIYAKU_END_DATE
        KYK_HOKEN_KEIYAKU_STR_DATE
        KYK_HOKEN_KINGAKU
        KYK_HOKEN_KINGAKU_TAX
        KYK_HOKEN_KOSHIN_YR
        KYK_HOKEN_SYOKEN_NO
        KYK_HOSYO_GYS_KKY_ID
        KYK_HOSYO_GYS_KKY_NAME
        KYK_HOSYO_KEIYAKU_END_DATE
        KYK_HOSYO_KEIYAKU_STR_DATE
        KYK_HOSYO_KINGAKU
        KYK_HOSYO_KINGAKU_TAX
        KYK_HOSYO_KOSHIN_YR
        KYK_HOSYO_SYOKEN_NO
        KYK_HSN_01_KKY_ID
        KYK_HSN_01_KKY_NAME
        KYK_HSN_01_ZKG_CD
        KYK_HSN_01_ZKG_NAME
        KYK_HSN_02_KKY_ID
        KYK_HSN_02_KKY_NAME
        KYK_HSN_02_ZKG_CD
        KYK_HSN_02_ZKG_NAME
        KYK_HYA_ID
        KYK_ID
        KYK_INSERTED_DATE
        KYK_ISP_KYK_ID
        KYK_KAIYAKU_ADV_CD
        KYK_KAIYAKU_ADV_NAME
        KYK_KAIYAKU_BNB_CD
        KYK_KAIYAKU_BNB_NAME
        KYK_KAIYAKU_BNK_CD
        KYK_KAIYAKU_BNK_NAME
        KYK_KAIYAKU_DATE
        KYK_KAIYAKU_HENKIN_YOTEI_DATE
        KYK_KAIYAKU_KNK_FB_FLG
        KYK_KAIYAKU_MEIGI
        KYK_KAIYAKU_MEIGI_KANA
        KYK_KAIYAKU_NUMBER
        KYK_KAIYAKU_REMARK
        KYK_KAIYAKU_SEK_END_MN
        KYK_KAIYAKU_SEK_END_YR
        KYK_KAIYAKU_SSN_HAKKO_DATE
        KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
        KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
        KYK_KAIYAKU_TACHIAI_YOTEI_DATE
        KYK_KAIYAKU_TAIKYO_YOTEI_DATE
        KYK_KAIYAKU_TKG_ADDR_01
        KYK_KAIYAKU_TKG_ADDR_02
        KYK_KAIYAKU_TKG_ATENA
        KYK_KAIYAKU_TKG_CTY_CD
        KYK_KAIYAKU_TKG_CTY_NAME
        KYK_KAIYAKU_TKG_PRF_CD
        KYK_KAIYAKU_TKG_PRF_NAME
        KYK_KAIYAKU_TKG_TEL
        KYK_KAIYAKU_TKG_TKR_CD
        KYK_KAIYAKU_TKG_TKR_NAME
        KYK_KAIYAKU_TKG_TWN_CD
        KYK_KAIYAKU_TKG_TWN_NAME
        KYK_KAIYAKU_TKG_ZIPCODE
        KYK_KAIYAKU_TSUCHI_DATE
        KYK_KAIYAKU_YOTEI_DATE
        KYK_KEIYAKU_DATE
        KYK_KEIYAKU_END_DATE
        KYK_KEIYAKU_STR_DATE
        KYK_KOSHINJIMURYO_CALC_DIV
        KYK_KOSHINJIMURYO_CALC_DIV_NAME
        KYK_KOSHINJIMURYO_KINGAKU
        KYK_KOSHINJIMURYO_KINGAKU_TAX
        KYK_KOSHINJIMURYO_MN
        KYK_KOSHINRYO_CALC_DIV
        KYK_KOSHINRYO_CALC_DIV_NAME
        KYK_KOSHINRYO_KINGAKU
        KYK_KOSHINRYO_KINGAKU_TAX
        KYK_KOSHINRYO_MN
        KYK_KOSHINTESURYO_KINGAKU
        KYK_KOSHINTESURYO_KINGAKU_TAX
        KYK_KOSHINTESURYO_PER
        KYK_KOSHINYOKOKU_DIV
        KYK_KOSHINYOKOKU_DIV_NAME
        KYK_KOSHINYOKOKU_NUM
        KYK_KOSHIN_AUTO_FLG
        KYK_KOSHIN_YR
        KYK_KYS_KKY_ID
        KYK_KYS_KKY_NAME
        KYK_MEMBER_CD
        KYK_MEMBER_CD_2
        KYK_MEMBER_FLG
        KYK_MEMBER_FLG_2
        KYK_MEMBER_NAME
        KYK_MEMBER_NAME_2
        KYK_MSK_ID
        KYK_NKY_KKY_ID
        KYK_NKY_KKY_NAME
        KYK_NKY_ZKG_CD
        KYK_NKY_ZKG_NAME
        KYK_NYUKYO_DATE
        KYK_REMARK
        KYK_SDK_KKY_ID
        KYK_SDK_KKY_NAME
        KYK_SEK_DIV_NAME
        KYK_SEK_LIMIT_DATE
        KYK_STATUS_DIV
        KYK_STATUS_DIV_NAME
        KYK_TAIKYOYOKOKU_DIV
        KYK_TAIKYOYOKOKU_DIV_NAME
        KYK_TAIKYOYOKOKU_NUM
        KYK_TAIKYO_SEIKYU_01_KINGAKU
        KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_01_TKM_CD
        KYK_TAIKYO_SEIKYU_01_TKM_NAME
        KYK_TAIKYO_SEIKYU_02_KINGAKU
        KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_02_TKM_CD
        KYK_TAIKYO_SEIKYU_02_TKM_NAME
        KYK_TAIKYO_SEIKYU_03_KINGAKU
        KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_03_TKM_CD
        KYK_TAIKYO_SEIKYU_03_TKM_NAME
        KYK_TEISYAKU_FLG
        KYK_TEISYAKU_TERM_DATE
        KYK_TEISYAKU_TERM_DIV
        KYK_TEISYAKU_TERM_DIV_NAME
        KYK_TEISYAKU_TERM_MN
        KYK_TEISYAKU_TERM_YR
        KYK_TINRYO_HASSEI_DATE
        KYK_UPDATED_DATE
        KYK_ZIJ_SEK_TSY_KKY_ID
        KYK_ZIJ_SEK_TSY_KKY_NAME
      }
      mst_heya {
        HYA_BALCONY_MENSEKI
        HYA_BOX_NUMBER
        HYA_BSH_START_DATE
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_DEL_FLG
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_KINGAKU
        HYA_HOKEN_YR
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_ID
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_INSERTED_DATE
        HYA_ISP_KRS_ID
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_KAIYAKU_DATE
        HYA_KEY_PLACE
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHIN_YR
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_MDR_NUM
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_NYUKYOKANO_DATE
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_REMARK
        HYA_ROOM_NO
        HYA_SENYU_MENSEKI
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
        HYA_TAIKYO_DATE
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_TTY_ID
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_UPDATED_DATE
      }
      room {
        building_id
        company_id
        created_at
        deleted_at
        display_order
        hya_id
        id
        remark
        status
        updated_at
        is_manually_disabled
      }
    }
  }
`
export const getRoomCsv = /* GraphQL */ `
  query GetRoomCsv($filter: TableRoomFilterInput) {
    getRoomCsv(filter: $filter) {
      companyId
      key
    }
  }
`
export const getRoomCheckListApplication = /* GraphQL */ `
  query GetRoomCheckListApplication(
    $filter: TableRoomCheckListApplicationFilterInput
  ) {
    getRoomCheckListApplication(filter: $filter) {
      application {
        application_state_id
        application_type_id
        building_id
        company_id
        content
        contract_id
        created_at
        customer_id
        deleted_at
        id
        last_user_notice_at
        remark
        room_id
        status
        updated_at
      }
      attachment {
        attachment_type_id
        bucket
        company_id
        convert_key
        created_at
        deleted_at
        filename
        id
        key
        title
        mime_type
        status
        thumbnail_key
        updated_at
      }
      arrangement_status
      is_arranged
      mst_heya {
        HYA_BALCONY_MENSEKI
        HYA_BOX_NUMBER
        HYA_BSH_START_DATE
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_DEL_FLG
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_KINGAKU
        HYA_HOKEN_YR
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_ID
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_INSERTED_DATE
        HYA_ISP_KRS_ID
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_KAIYAKU_DATE
        HYA_KEY_PLACE
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHIN_YR
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_MDR_NUM
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_NYUKYOKANO_DATE
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_REMARK
        HYA_ROOM_NO
        HYA_SENYU_MENSEKI
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
        HYA_TAIKYO_DATE
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_TTY_ID
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_UPDATED_DATE
      }
      mst_kokyaku_kys {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_kokyaku_nky {
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_BIRTHDAY
        KKY_BOSSNAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_ATENA
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_FAX
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ZIPCODE
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_DEL_FLG
        KKY_DIV
        KKY_EMAILADDR
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_FAX
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ZIPCODE
        KKY_FAX
        KKY_ID
        KKY_INCOME
        KKY_INSERTED_DATE
        KKY_ISP_KKY_ID
        KKY_KNR_NO
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_NAME
        KKY_NAME_KANA
        KKY_NATIONARITY
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_REMARK
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_TEL_01
        KKY_TEL_02
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_UPDATED_DATE
        KKY_WORKPLACE
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_FAX
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ZIPCODE
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_ZIPCODE
      }
      mst_tateya {
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_COMPLETION_DATE
        TTY_CSV_ID
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_DEL_FLG
        TTY_GROUND_FLOOR_NUM
        TY_GRP_NAME
        TTY_ID
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_INSERTED_DATE
        TTY_ISP_KRM_ID
        TTY_ISP_TTY_ID
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_KNR_DIV
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_NO
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNRPATTERN_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_NAME
        TTY_NAME_KANA
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_TIME
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NOBEYUKA_MENSEKI
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_REMARK
        TTY_SOG_DAY
        TTY_SOG_ID
        TTY_SOG_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TNP_NAME
        TTY_TOTAL_COUNT
        TTY_TOTI_MENSEKI
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_UGGROUND_FLOOR_NUM
        TTY_UPDATED_DATE
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_ZIPCODE
        TTY_GRP_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      assigned_user_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      assigned_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      application_memos {
        id
        application_id
        memo
        company_user_id
        created_at
        updated_at
        deleted_at
      }
      application_tags {
        id
        status
        body
        colorCode
        createdAt
        createdBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updatedAt
        updatedBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        deletedAt
      }
      thread {
        application_id
        assigned_by
        assigned_group_by
        building_id
        category_id
        company_id
        contract_id
        created_at
        customer_id
        deleted_at
        exchanged_at
        exchanged_by
        id
        is_reply
        room_id
        status
        thread_state_id
        updated_at
      }
      app_usage_status
      exchanged_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      exchanged_group {
        company_id
        created_at
        deleted_at
        display_order
        id
        name
        role_id
        status
        updated_at
      }
      room_check_list_application_id
    }
  }
`
export const getRoomCheckListApplicationList = /* GraphQL */ `
  query GetRoomCheckListApplicationList(
    $filter: TableRoomCheckListApplicationFilterInput
    $limit: Int
    $offset: Int
  ) {
    getRoomCheckListApplicationList(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      data {
        application {
          application_state_id
          application_type_id
          building_id
          company_id
          content
          contract_id
          created_at
          customer_id
          deleted_at
          id
          last_user_notice_at
          remark
          room_id
          status
          updated_at
        }
        attachment {
          attachment_type_id
          bucket
          company_id
          convert_key
          created_at
          deleted_at
          filename
          id
          key
          title
          mime_type
          status
          thumbnail_key
          updated_at
        }
        arrangement_status
        is_arranged
        mst_heya {
          HYA_BALCONY_MENSEKI
          HYA_BOX_NUMBER
          HYA_BSH_START_DATE
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_DEL_FLG
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_KINGAKU
          HYA_HOKEN_YR
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_ID
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_INSERTED_DATE
          HYA_ISP_KRS_ID
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_KAIYAKU_DATE
          HYA_KEY_PLACE
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHIN_YR
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_MDR_NUM
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_NYUKYOKANO_DATE
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_REMARK
          HYA_ROOM_NO
          HYA_SENYU_MENSEKI
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
          HYA_TAIKYO_DATE
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_TTY_ID
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_UPDATED_DATE
        }
        mst_kokyaku_kys {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_kokyaku_nky {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        assigned_user_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        application_memos {
          id
          application_id
          memo
          company_user_id
          created_at
          updated_at
          deleted_at
        }
        application_tags {
          id
          status
          body
          colorCode
          createdAt
          createdBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          updatedAt
          updatedBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          deletedAt
        }
        thread {
          application_id
          assigned_by
          assigned_group_by
          building_id
          category_id
          company_id
          contract_id
          created_at
          customer_id
          deleted_at
          exchanged_at
          exchanged_by
          id
          is_reply
          room_id
          status
          thread_state_id
          updated_at
        }
        app_usage_status
        exchanged_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        exchanged_group {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        room_check_list_application_id
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getRoomList = /* GraphQL */ `
  query GetRoomList($filter: TableRoomFilterInput, $limit: Int, $offset: Int) {
    getRoomList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        knr_keiyaku {
          KYK_CSV_ID
          KYK_DEL_FLG
          KYK_FREERENT_FLG
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_GYS_KKY_ID
          KYK_GYS_KKY_NAME
          KYK_GYS_SHN_NAME
          KYK_HOKEN_GYS_KKY_ID
          KYK_HOKEN_GYS_KKY_NAME
          KYK_HOKEN_KEIYAKU_END_DATE
          KYK_HOKEN_KEIYAKU_STR_DATE
          KYK_HOKEN_KINGAKU
          KYK_HOKEN_KINGAKU_TAX
          KYK_HOKEN_KOSHIN_YR
          KYK_HOKEN_SYOKEN_NO
          KYK_HOSYO_GYS_KKY_ID
          KYK_HOSYO_GYS_KKY_NAME
          KYK_HOSYO_KEIYAKU_END_DATE
          KYK_HOSYO_KEIYAKU_STR_DATE
          KYK_HOSYO_KINGAKU
          KYK_HOSYO_KINGAKU_TAX
          KYK_HOSYO_KOSHIN_YR
          KYK_HOSYO_SYOKEN_NO
          KYK_HSN_01_KKY_ID
          KYK_HSN_01_KKY_NAME
          KYK_HSN_01_ZKG_CD
          KYK_HSN_01_ZKG_NAME
          KYK_HSN_02_KKY_ID
          KYK_HSN_02_KKY_NAME
          KYK_HSN_02_ZKG_CD
          KYK_HSN_02_ZKG_NAME
          KYK_HYA_ID
          KYK_ID
          KYK_INSERTED_DATE
          KYK_ISP_KYK_ID
          KYK_KAIYAKU_ADV_CD
          KYK_KAIYAKU_ADV_NAME
          KYK_KAIYAKU_BNB_CD
          KYK_KAIYAKU_BNB_NAME
          KYK_KAIYAKU_BNK_CD
          KYK_KAIYAKU_BNK_NAME
          KYK_KAIYAKU_DATE
          KYK_KAIYAKU_HENKIN_YOTEI_DATE
          KYK_KAIYAKU_KNK_FB_FLG
          KYK_KAIYAKU_MEIGI
          KYK_KAIYAKU_MEIGI_KANA
          KYK_KAIYAKU_NUMBER
          KYK_KAIYAKU_REMARK
          KYK_KAIYAKU_SEK_END_MN
          KYK_KAIYAKU_SEK_END_YR
          KYK_KAIYAKU_SSN_HAKKO_DATE
          KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
          KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
          KYK_KAIYAKU_TACHIAI_YOTEI_DATE
          KYK_KAIYAKU_TAIKYO_YOTEI_DATE
          KYK_KAIYAKU_TKG_ADDR_01
          KYK_KAIYAKU_TKG_ADDR_02
          KYK_KAIYAKU_TKG_ATENA
          KYK_KAIYAKU_TKG_CTY_CD
          KYK_KAIYAKU_TKG_CTY_NAME
          KYK_KAIYAKU_TKG_PRF_CD
          KYK_KAIYAKU_TKG_PRF_NAME
          KYK_KAIYAKU_TKG_TEL
          KYK_KAIYAKU_TKG_TKR_CD
          KYK_KAIYAKU_TKG_TKR_NAME
          KYK_KAIYAKU_TKG_TWN_CD
          KYK_KAIYAKU_TKG_TWN_NAME
          KYK_KAIYAKU_TKG_ZIPCODE
          KYK_KAIYAKU_TSUCHI_DATE
          KYK_KAIYAKU_YOTEI_DATE
          KYK_KEIYAKU_DATE
          KYK_KEIYAKU_END_DATE
          KYK_KEIYAKU_STR_DATE
          KYK_KOSHINJIMURYO_CALC_DIV
          KYK_KOSHINJIMURYO_CALC_DIV_NAME
          KYK_KOSHINJIMURYO_KINGAKU
          KYK_KOSHINJIMURYO_KINGAKU_TAX
          KYK_KOSHINJIMURYO_MN
          KYK_KOSHINRYO_CALC_DIV
          KYK_KOSHINRYO_CALC_DIV_NAME
          KYK_KOSHINRYO_KINGAKU
          KYK_KOSHINRYO_KINGAKU_TAX
          KYK_KOSHINRYO_MN
          KYK_KOSHINTESURYO_KINGAKU
          KYK_KOSHINTESURYO_KINGAKU_TAX
          KYK_KOSHINTESURYO_PER
          KYK_KOSHINYOKOKU_DIV
          KYK_KOSHINYOKOKU_DIV_NAME
          KYK_KOSHINYOKOKU_NUM
          KYK_KOSHIN_AUTO_FLG
          KYK_KOSHIN_YR
          KYK_KYS_KKY_ID
          KYK_KYS_KKY_NAME
          KYK_MEMBER_CD
          KYK_MEMBER_CD_2
          KYK_MEMBER_FLG
          KYK_MEMBER_FLG_2
          KYK_MEMBER_NAME
          KYK_MEMBER_NAME_2
          KYK_MSK_ID
          KYK_NKY_KKY_ID
          KYK_NKY_KKY_NAME
          KYK_NKY_ZKG_CD
          KYK_NKY_ZKG_NAME
          KYK_NYUKYO_DATE
          KYK_REMARK
          KYK_SDK_KKY_ID
          KYK_SDK_KKY_NAME
          KYK_SEK_DIV_NAME
          KYK_SEK_LIMIT_DATE
          KYK_STATUS_DIV
          KYK_STATUS_DIV_NAME
          KYK_TAIKYOYOKOKU_DIV
          KYK_TAIKYOYOKOKU_DIV_NAME
          KYK_TAIKYOYOKOKU_NUM
          KYK_TAIKYO_SEIKYU_01_KINGAKU
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_01_TKM_CD
          KYK_TAIKYO_SEIKYU_01_TKM_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_02_TKM_CD
          KYK_TAIKYO_SEIKYU_02_TKM_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_03_TKM_CD
          KYK_TAIKYO_SEIKYU_03_TKM_NAME
          KYK_TEISYAKU_FLG
          KYK_TEISYAKU_TERM_DATE
          KYK_TEISYAKU_TERM_DIV
          KYK_TEISYAKU_TERM_DIV_NAME
          KYK_TEISYAKU_TERM_MN
          KYK_TEISYAKU_TERM_YR
          KYK_TINRYO_HASSEI_DATE
          KYK_UPDATED_DATE
          KYK_ZIJ_SEK_TSY_KKY_ID
          KYK_ZIJ_SEK_TSY_KKY_NAME
        }
        mst_heya {
          HYA_BALCONY_MENSEKI
          HYA_BOX_NUMBER
          HYA_BSH_START_DATE
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_DEL_FLG
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_KINGAKU
          HYA_HOKEN_YR
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_ID
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_INSERTED_DATE
          HYA_ISP_KRS_ID
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_KAIYAKU_DATE
          HYA_KEY_PLACE
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHIN_YR
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_MDR_NUM
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_NYUKYOKANO_DATE
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_REMARK
          HYA_ROOM_NO
          HYA_SENYU_MENSEKI
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
          HYA_TAIKYO_DATE
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_TTY_ID
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_UPDATED_DATE
        }
        room {
          building_id
          company_id
          created_at
          deleted_at
          display_order
          hya_id
          id
          remark
          status
          updated_at
          is_manually_disabled
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getRoomList2 = /* GraphQL */ `
  query GetRoomList2($filter: TableRoomFilterInput, $limit: Int, $offset: Int) {
    getRoomList2(filter: $filter, limit: $limit, offset: $offset) {
      data {
        building {
          company_id
          contract_cnt
          created_at
          customer_cnt
          deleted_at
          display_order
          id
          is_management
          remark
          room_cnt
          status
          tty_id
          tty_isp_tty_id
          updated_at
          mst_tateya {
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_COMPLETION_DATE
            TTY_CSV_ID
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_DEL_FLG
            TTY_GROUND_FLOOR_NUM
            TY_GRP_NAME
            TTY_ID
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_INSERTED_DATE
            TTY_ISP_KRM_ID
            TTY_ISP_TTY_ID
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_KNR_DIV
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_NO
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNRPATTERN_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_NAME
            TTY_NAME_KANA
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_TIME
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NOBEYUKA_MENSEKI
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_REMARK
            TTY_SOG_DAY
            TTY_SOG_ID
            TTY_SOG_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TNP_NAME
            TTY_TOTAL_COUNT
            TTY_TOTI_MENSEKI
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_UGGROUND_FLOOR_NUM
            TTY_UPDATED_DATE
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_ZIPCODE
            TTY_GRP_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
          is_manually_disabled
        }
        contractAndTenant {
          contract {
            building_id
            company_id
            contractor_id
            created_at
            deleted_at
            id
            kyk_id
            kyk_keiyaku_end_date
            kyk_keiyaku_str_date
            kyk_status_div
            room_id
            status
            tenant_id
            updated_at
          }
          tenant {
            app_user_id
            company_id
            created_at
            deleted_at
            id
            iot_provider_id
            is_activation
            is_usage
            kky_id
            password
            registered_at
            remark
            status
            updated_at
            username
          }
        }
        knr_keiyaku {
          KYK_CSV_ID
          KYK_DEL_FLG
          KYK_FREERENT_FLG
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_GYS_KKY_ID
          KYK_GYS_KKY_NAME
          KYK_GYS_SHN_NAME
          KYK_HOKEN_GYS_KKY_ID
          KYK_HOKEN_GYS_KKY_NAME
          KYK_HOKEN_KEIYAKU_END_DATE
          KYK_HOKEN_KEIYAKU_STR_DATE
          KYK_HOKEN_KINGAKU
          KYK_HOKEN_KINGAKU_TAX
          KYK_HOKEN_KOSHIN_YR
          KYK_HOKEN_SYOKEN_NO
          KYK_HOSYO_GYS_KKY_ID
          KYK_HOSYO_GYS_KKY_NAME
          KYK_HOSYO_KEIYAKU_END_DATE
          KYK_HOSYO_KEIYAKU_STR_DATE
          KYK_HOSYO_KINGAKU
          KYK_HOSYO_KINGAKU_TAX
          KYK_HOSYO_KOSHIN_YR
          KYK_HOSYO_SYOKEN_NO
          KYK_HSN_01_KKY_ID
          KYK_HSN_01_KKY_NAME
          KYK_HSN_01_ZKG_CD
          KYK_HSN_01_ZKG_NAME
          KYK_HSN_02_KKY_ID
          KYK_HSN_02_KKY_NAME
          KYK_HSN_02_ZKG_CD
          KYK_HSN_02_ZKG_NAME
          KYK_HYA_ID
          KYK_ID
          KYK_INSERTED_DATE
          KYK_ISP_KYK_ID
          KYK_KAIYAKU_ADV_CD
          KYK_KAIYAKU_ADV_NAME
          KYK_KAIYAKU_BNB_CD
          KYK_KAIYAKU_BNB_NAME
          KYK_KAIYAKU_BNK_CD
          KYK_KAIYAKU_BNK_NAME
          KYK_KAIYAKU_DATE
          KYK_KAIYAKU_HENKIN_YOTEI_DATE
          KYK_KAIYAKU_KNK_FB_FLG
          KYK_KAIYAKU_MEIGI
          KYK_KAIYAKU_MEIGI_KANA
          KYK_KAIYAKU_NUMBER
          KYK_KAIYAKU_REMARK
          KYK_KAIYAKU_SEK_END_MN
          KYK_KAIYAKU_SEK_END_YR
          KYK_KAIYAKU_SSN_HAKKO_DATE
          KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
          KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
          KYK_KAIYAKU_TACHIAI_YOTEI_DATE
          KYK_KAIYAKU_TAIKYO_YOTEI_DATE
          KYK_KAIYAKU_TKG_ADDR_01
          KYK_KAIYAKU_TKG_ADDR_02
          KYK_KAIYAKU_TKG_ATENA
          KYK_KAIYAKU_TKG_CTY_CD
          KYK_KAIYAKU_TKG_CTY_NAME
          KYK_KAIYAKU_TKG_PRF_CD
          KYK_KAIYAKU_TKG_PRF_NAME
          KYK_KAIYAKU_TKG_TEL
          KYK_KAIYAKU_TKG_TKR_CD
          KYK_KAIYAKU_TKG_TKR_NAME
          KYK_KAIYAKU_TKG_TWN_CD
          KYK_KAIYAKU_TKG_TWN_NAME
          KYK_KAIYAKU_TKG_ZIPCODE
          KYK_KAIYAKU_TSUCHI_DATE
          KYK_KAIYAKU_YOTEI_DATE
          KYK_KEIYAKU_DATE
          KYK_KEIYAKU_END_DATE
          KYK_KEIYAKU_STR_DATE
          KYK_KOSHINJIMURYO_CALC_DIV
          KYK_KOSHINJIMURYO_CALC_DIV_NAME
          KYK_KOSHINJIMURYO_KINGAKU
          KYK_KOSHINJIMURYO_KINGAKU_TAX
          KYK_KOSHINJIMURYO_MN
          KYK_KOSHINRYO_CALC_DIV
          KYK_KOSHINRYO_CALC_DIV_NAME
          KYK_KOSHINRYO_KINGAKU
          KYK_KOSHINRYO_KINGAKU_TAX
          KYK_KOSHINRYO_MN
          KYK_KOSHINTESURYO_KINGAKU
          KYK_KOSHINTESURYO_KINGAKU_TAX
          KYK_KOSHINTESURYO_PER
          KYK_KOSHINYOKOKU_DIV
          KYK_KOSHINYOKOKU_DIV_NAME
          KYK_KOSHINYOKOKU_NUM
          KYK_KOSHIN_AUTO_FLG
          KYK_KOSHIN_YR
          KYK_KYS_KKY_ID
          KYK_KYS_KKY_NAME
          KYK_MEMBER_CD
          KYK_MEMBER_CD_2
          KYK_MEMBER_FLG
          KYK_MEMBER_FLG_2
          KYK_MEMBER_NAME
          KYK_MEMBER_NAME_2
          KYK_MSK_ID
          KYK_NKY_KKY_ID
          KYK_NKY_KKY_NAME
          KYK_NKY_ZKG_CD
          KYK_NKY_ZKG_NAME
          KYK_NYUKYO_DATE
          KYK_REMARK
          KYK_SDK_KKY_ID
          KYK_SDK_KKY_NAME
          KYK_SEK_DIV_NAME
          KYK_SEK_LIMIT_DATE
          KYK_STATUS_DIV
          KYK_STATUS_DIV_NAME
          KYK_TAIKYOYOKOKU_DIV
          KYK_TAIKYOYOKOKU_DIV_NAME
          KYK_TAIKYOYOKOKU_NUM
          KYK_TAIKYO_SEIKYU_01_KINGAKU
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_01_TKM_CD
          KYK_TAIKYO_SEIKYU_01_TKM_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_02_TKM_CD
          KYK_TAIKYO_SEIKYU_02_TKM_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_03_TKM_CD
          KYK_TAIKYO_SEIKYU_03_TKM_NAME
          KYK_TEISYAKU_FLG
          KYK_TEISYAKU_TERM_DATE
          KYK_TEISYAKU_TERM_DIV
          KYK_TEISYAKU_TERM_DIV_NAME
          KYK_TEISYAKU_TERM_MN
          KYK_TEISYAKU_TERM_YR
          KYK_TINRYO_HASSEI_DATE
          KYK_UPDATED_DATE
          KYK_ZIJ_SEK_TSY_KKY_ID
          KYK_ZIJ_SEK_TSY_KKY_NAME
        }
        nky_mst_kokyaku {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        kys_mst_kokyaku {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        mst_heya {
          HYA_BALCONY_MENSEKI
          HYA_BOX_NUMBER
          HYA_BSH_START_DATE
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_DEL_FLG
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_KINGAKU
          HYA_HOKEN_YR
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_ID
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_INSERTED_DATE
          HYA_ISP_KRS_ID
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_KAIYAKU_DATE
          HYA_KEY_PLACE
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHIN_YR
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_MDR_NUM
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_NYUKYOKANO_DATE
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_REMARK
          HYA_ROOM_NO
          HYA_SENYU_MENSEKI
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
          HYA_TAIKYO_DATE
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_TTY_ID
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_UPDATED_DATE
        }
        room {
          building_id
          company_id
          created_at
          deleted_at
          display_order
          hya_id
          id
          remark
          status
          updated_at
          is_manually_disabled
        }
        mst_tateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getRoomMemoList = /* GraphQL */ `
  query GetRoomMemoList($room_id: Int, $limit: Int, $offset: Int) {
    getRoomMemoList(room_id: $room_id, limit: $limit, offset: $offset) {
      data {
        id
        original_data_id
        memo
        created_at
        created_by {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_at
        updated_by {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        deleted_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getSearchConditionList = /* GraphQL */ `
  query GetSearchConditionList($screen: String!) {
    getSearchConditionList(screen: $screen) {
      data {
        id
        status
        company {
          address
          privacy_policy_url
          business_hours
          owner_function_enabled
          business_hours_text
          created_at
          created_by
          deleted_at
          deleted_by
          emergency_name
          emergency_tel
          emergency_time
          emergency_time_text
          fax
          id
          mail
          name
          name_kana
          display_name
          prefecture_id
          regular_holiday
          regular_holiday_text
          status
          stock_signage
          tel
          updated_at
          updated_by
          url
          zip_code
          privacy_policy_type_id
        }
        createdBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        name
        isDefault
        screen
        condition
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`
export const getTagList = /* GraphQL */ `
  query GetTagList {
    getTagList {
      id
      status
      body
      colorCode
      createdAt
      createdBy {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updatedAt
      updatedBy {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      deletedAt
    }
  }
`
export const getTateyaIndividualMaster = /* GraphQL */ `
  query GetTateyaIndividualMaster {
    getTateyaIndividualMaster {
      KnrTanto {
        TTY_TANTO_SHN_ID
        TTY_TANTO_SHN_NAME
      }
      KnrPattern {
        TTY_KNRPATTERN_ID
        TTY_KNRPATTERN_NAME
      }
      KnrTenpo {
        TTY_TNP_ID
        TTY_TNP_NAME
      }
    }
  }
`
export const getThread = /* GraphQL */ `
  query GetThread($filter: TableThreadFilterInput, $is_owner: Boolean) {
    getThread(filter: $filter, is_owner: $is_owner) {
      thread {
        application_id
        assigned_by
        assigned_group_by
        building_id
        category_id
        company_id
        contract_id
        created_at
        customer_id
        deleted_at
        exchanged_at
        exchanged_by
        id
        is_reply
        room_id
        status
        thread_state_id
        updated_at
      }
      thread_memos {
        id
        thread_id
        memo
        updater {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        created_at
        updated_at
        deleted_at
      }
      threadTags {
        id
        status
        body
        colorCode
        createdAt
        createdBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updatedAt
        updatedBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        deletedAt
      }
    }
  }
`
export const getThreadCsv = /* GraphQL */ `
  query GetThreadCsv(
    $filter: ThreadSearchCondition
    $limit: Int
    $offset: Int
  ) {
    getThreadCsv(filter: $filter, limit: $limit, offset: $offset) {
      companyId
      key
    }
  }
`
export const getThreadList = /* GraphQL */ `
  query GetThreadList(
    $filter: TableThreadFilterInput
    $limit: Int
    $offset: Int
  ) {
    getThreadList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        application_id
        assigned_by
        assigned_group_by
        building_id
        category_id
        company_id
        contract_id
        created_at
        customer_id
        deleted_at
        exchanged_at
        exchanged_by
        id
        is_reply
        room_id
        status
        thread_state_id
        updated_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getThreadListBySearchCondition = /* GraphQL */ `
  query GetThreadListBySearchCondition(
    $filter: ThreadSearchCondition
    $limit: Int
    $offset: Int
  ) {
    getThreadListBySearchCondition(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      data {
        assignedGroup {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          role_id
          status
          updated_at
        }
        assignedUser {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        arrangementStatus
        exchangedUser {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        isArranged
        largeCategory {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          parent_id
          status
          updated_at
          published_count
          unpublished_count
          icon
        }
        mediumCategory {
          company_id
          created_at
          deleted_at
          display_order
          id
          name
          parent_id
          status
          updated_at
          published_count
          unpublished_count
          icon
        }
        mstheya {
          HYA_BALCONY_MENSEKI
          HYA_BOX_NUMBER
          HYA_BSH_START_DATE
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_DEL_FLG
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_KINGAKU
          HYA_HOKEN_YR
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_ID
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_INSERTED_DATE
          HYA_ISP_KRS_ID
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_KAIYAKU_DATE
          HYA_KEY_PLACE
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHIN_YR
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_MDR_NUM
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_NYUKYOKANO_DATE
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_REMARK
          HYA_ROOM_NO
          HYA_SENYU_MENSEKI
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
          HYA_TAIKYO_DATE
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_TTY_ID
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_UPDATED_DATE
        }
        mstkokyaku {
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_BIRTHDAY
          KKY_BOSSNAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_ATENA
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_FAX
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ZIPCODE
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_DEL_FLG
          KKY_DIV
          KKY_EMAILADDR
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_FAX
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ZIPCODE
          KKY_FAX
          KKY_ID
          KKY_INCOME
          KKY_INSERTED_DATE
          KKY_ISP_KKY_ID
          KKY_KNR_NO
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_NAME
          KKY_NAME_KANA
          KKY_NATIONARITY
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_REMARK
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_TEL_01
          KKY_TEL_02
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_UPDATED_DATE
          KKY_WORKPLACE
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_FAX
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ZIPCODE
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_ZIPCODE
        }
        msttateya {
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_COMPLETION_DATE
          TTY_CSV_ID
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_DEL_FLG
          TTY_GROUND_FLOOR_NUM
          TY_GRP_NAME
          TTY_ID
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_INSERTED_DATE
          TTY_ISP_KRM_ID
          TTY_ISP_TTY_ID
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_KNR_DIV
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_NO
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNRPATTERN_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_NAME
          TTY_NAME_KANA
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_TIME
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NOBEYUKA_MENSEKI
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_REMARK
          TTY_SOG_DAY
          TTY_SOG_ID
          TTY_SOG_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TNP_NAME
          TTY_TOTAL_COUNT
          TTY_TOTI_MENSEKI
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_UGGROUND_FLOOR_NUM
          TTY_UPDATED_DATE
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_ZIPCODE
          TTY_GRP_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        owner {
          id
          name
          name_kana
          app_user_id
          company_id
          created_at
          updated_at
          deleted_at
        }
        thread {
          application_id
          assigned_by
          assigned_group_by
          building_id
          category_id
          company_id
          contract_id
          created_at
          customer_id
          deleted_at
          exchanged_at
          exchanged_by
          id
          is_reply
          room_id
          status
          thread_state_id
          updated_at
        }
        threadParams {
          first_sent
          last_send_at
        }
        threadTags {
          id
          status
          body
          colorCode
          createdAt
          createdBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          updatedAt
          updatedBy {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
          deletedAt
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getTotonoSessionDev = /* GraphQL */ `
  query GetTotonoSessionDev($token: String!) {
    getTotonoSessionDev(token: $token) {
      company
      token
      type
      user
    }
  }
`
export const getWeekList = /* GraphQL */ `
  query GetWeekList($filter: TableWeekFilterInput) {
    getWeekList(filter: $filter) {
      data {
        created_at
        deleted_at
        id
        name
        status
        updated_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const listTotonoSessionDevs = /* GraphQL */ `
  query ListTotonoSessionDevs(
    $filter: TableTotonoSessionDevFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTotonoSessionDevs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        company
        token
        type
        user
      }
      nextToken
    }
  }
`
export const verifyLoginIpAddress = /* GraphQL */ `
  query VerifyLoginIpAddress {
    verifyLoginIpAddress {
      company
      expire
      role
      token
      type
      user
    }
  }
`
