import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import RowWithFile from 'components/common/RowWithFile'
import { Storage } from 'aws-amplify'
import Loading from 'components/common/Loading'
import { fromCode } from 'utils/enum.utils'
import { KeyReceiptKeyType } from 'utils/enum'

const useStyles = makeStyles(theme => ({
  root: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#2c3f58',
    fontSize: '16px',
    lineHeight: '24px'
  },
  columnName1: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue1: {
    width: '82%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName2: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue2: {
    width: '32%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  uniqueColumn: {
    // MEMO: 鍵の写真エリアの下線を消し、下の余白を通常より多めにする
    border: 'none',
    paddingBottom: '32px'
  }
}))

const CellWithKeyReceiptKeyType = ({ keyType }) => {
  const keyReceiptKeyType = fromCode(KeyReceiptKeyType, keyType)
  return <>{keyReceiptKeyType.value}</>
}

function KeyReceiptApplicationDetail(props) {
  const classes = useStyles()
  const [images, setImages] = useState([])
  const { isLoading, content, attachments } = props

  async function getImage() {
    const imageList = []
    for (let attachment of attachments) {
      const result = await Storage.get(attachment.key, {
        level: 'protected',
        identityId: attachment.company_id,
        expires: 60
      })
      const object = Object.assign({}, attachment, { body: result })
      imageList.push(object)
    }
    setImages(imageList)
  }

  useEffect(() => {
    if (attachments) {
      getImage()
    }
  }, [attachments, setImages])

  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.root} colSpan="4">
              申請内容
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell className={classes.columnName2}>
                <Loading isLoading={isLoading} />
              </TableCell>
            </TableRow>
          ) : (
            Array.isArray(content) &&
            content.map((row, index) => {
              return (
                <>
                  <TableRow>
                    <TableCell className={classes.root} colSpan="4">
                      {/* MEMO: ①や②などを表示するための記述 */}
                      {`鍵の種別${String.fromCharCode(9312 + index)}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.columnName2}>
                      鍵の種類
                    </TableCell>
                    <TableCell className={classes.columnValue2}>
                      <CellWithKeyReceiptKeyType keyType={row.key_type} />
                    </TableCell>
                    <TableCell className={classes.columnName2}>
                      使用場所
                    </TableCell>
                    <TableCell className={classes.columnValue2}>
                      {row.key_use_place}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.columnName2}>
                      鍵の番号
                    </TableCell>
                    <TableCell className={classes.columnValue2}>
                      {row.key_number}
                    </TableCell>
                    <TableCell className={classes.columnName2}>
                      鍵の本数
                    </TableCell>
                    <TableCell className={classes.columnValue2}>
                      {row.key_count}本
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className={`${classes.columnName2} ${classes.uniqueColumn}`}
                    >
                      鍵の写真
                    </TableCell>
                    <TableCell
                      className={`${classes.columnName2} ${classes.uniqueColumn}`}
                      colSpan="3"
                    >
                      {/* MEMO: ホワイトアウトするためnullチェックを行う */
                      images[index] && (
                        <RowWithFile
                          key={index}
                          title={images[index].filename}
                          image={images[index].body}
                          isDeleteButtonView={false}
                          style={{ marginBottom: 10 }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </>
              )
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default KeyReceiptApplicationDetail
