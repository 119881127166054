/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import {
  ADMIN,
  BICYCLE_PARKING_STICKER,
  BUILDING,
  BULLETIN_BOARD,
  CHANGE_HEADER_BODY,
  CHANGE_SEARCH_CONDITION,
  CLEAR_SEARCH_CONDITION,
  CLOSE_MODAL_DIALOG,
  CLOSE_SEARCH_MODAL_DIALOG,
  CLOSE_SEARCH_SAVE_MODAL_DIALOG,
  COC2_USE_PARKING,
  COMPANY_USER,
  CONTRACT,
  DELETE_SEARCH_CONDITIONS,
  FAQ,
  HANDLE_CHANGE,
  HANDLE_CLEAR,
  MESSAGE,
  OWNER_MESSAGE,
  MESSAGE_TEMPLATE,
  OPEN_MODAL_DIALOG,
  OPEN_SEARCH_MODAL_DIALOG,
  OPEN_SEARCH_SAVE_MODAL_DIALOG,
  PET,
  KEY_RECEIPT,
  RESIDENT_CANCELLATION,
  RESIDENT_INFO_CHANGE,
  ROOM_CHECK_LIST,
  SETTING_BUILDING,
  SET_LOAD_DATA,
  SET_ROLE_LIST,
  SIGNED,
  SET_CSV_INFORMATION,
  SET_MATCH_CSV_VALUE,
  SET_TARGET_ID_LIST,
  SET_CSV_HEADER,
  SET_MATCH_CONTRACT_ID_LIST,
  SET_SELECT_DELIVERY_LIST_ID,
  SET_SELECT_CONTRACT_LIST,
  SET_FILTER_CSV_INFORMATION,
  SET_BULK_DELIVERY_DETAIL_LIST,
  SET_IMAGE_LIST,
  SET_PDF_LIST
} from './ActionTypes'

export const changeHeaderBody = component => {
  return {
    type: CHANGE_HEADER_BODY,
    payload: component
  }
}

export const setSignin = isSignin => {
  return {
    type: SIGNED,
    payload: isSignin
  }
}

export const setAdmin = isAdmin => {
  return {
    type: ADMIN,
    payload: isAdmin
  }
}

export const handleChange = parameter => {
  return {
    type: HANDLE_CHANGE,
    payload: parameter
  }
}

export const handleClear = () => {
  return {
    type: HANDLE_CLEAR,
    payload: {}
  }
}

export const handleOpenPage = (url, event, history, state) => {
  if (
    (event.shiftKey && event.ctrlKey) ||
    (event.shiftKey && !event.ctrlKey) ||
    (!event.shiftKey && event.ctrlKey)
  ) {
    return window.open(url)
  }
  history.push({
    pathname: url,
    state: state
  })
}

export const handleOpenPageNewTab = url => {
  return window.open(url)
}

export const setLoadData = flag => {
  return {
    type: SET_LOAD_DATA,
    payload: flag
  }
}

export const openModalDialog = () => {
  return {
    type: OPEN_MODAL_DIALOG
  }
}

export const closeModalDialog = () => {
  return {
    type: CLOSE_MODAL_DIALOG
  }
}

export const setRoleList = data => {
  return {
    type: SET_ROLE_LIST,
    payload: data
  }
}

export const changeSearchCondition = conditions => {
  return {
    type: CHANGE_SEARCH_CONDITION,
    payload: conditions
  }
}

export const clearSearchCondition = () => {
  return {
    type: CLEAR_SEARCH_CONDITION,
    payload: {}
  }
}

export const openSearchModalDialog = () => {
  return {
    type: OPEN_SEARCH_MODAL_DIALOG
  }
}

export const closeSearchModalDialog = () => {
  return {
    type: CLOSE_SEARCH_MODAL_DIALOG
  }
}

export const openSearchSaveModalDialog = () => {
  return {
    type: OPEN_SEARCH_SAVE_MODAL_DIALOG
  }
}

export const closeSearchSaveModalDialog = () => {
  return {
    type: CLOSE_SEARCH_SAVE_MODAL_DIALOG
  }
}

export const setCsvInformation = list => {
  return {
    type: SET_CSV_INFORMATION,
    payload: list
  }
}

export const setMatchCsvValue = value => {
  return {
    type: SET_MATCH_CSV_VALUE,
    payload: value
  }
}

export const setTargetIdList = list => {
  return {
    type: SET_TARGET_ID_LIST,
    payload: list
  }
}

export const setCsvHeader = value => {
  return {
    type: SET_CSV_HEADER,
    payload: value
  }
}

export const setMatchContractIdList = list => {
  return {
    type: SET_MATCH_CONTRACT_ID_LIST,
    payload: list
  }
}

export const setSelectDeliveryListId = id => {
  return {
    type: SET_SELECT_DELIVERY_LIST_ID,
    payload: id
  }
}

export const setSelectContractList = list => {
  return {
    type: SET_SELECT_CONTRACT_LIST,
    payload: list
  }
}

export const setFilterCsvInformation = list => {
  return {
    type: SET_FILTER_CSV_INFORMATION,
    payload: list
  }
}

export const setBulkDeliveryDetailList = list => {
  return {
    type: SET_BULK_DELIVERY_DETAIL_LIST,
    payload: list
  }
}

export const setImageList = list => {
  return {
    type: SET_IMAGE_LIST,
    payload: list
  }
}

export const setPdfList = list => {
  return {
    type: SET_PDF_LIST,
    payload: list
  }
}

export const saveMessageSearchCondition = parameter => {
  return {
    type: MESSAGE,
    payload: parameter
  }
}

export const saveOwnerMessageSearchCondition = parameter => {
  return {
    type: OWNER_MESSAGE,
    payload: parameter
  }
}

export const saveBulletinBoardSearchCondition = parameter => {
  return {
    type: BULLETIN_BOARD,
    payload: parameter
  }
}

export const saveResidentInfoChangeSearchCondition = parameter => {
  return {
    type: RESIDENT_INFO_CHANGE,
    payload: parameter
  }
}

export const saveResidentCancellationSearchCondition = parameter => {
  return {
    type: RESIDENT_CANCELLATION,
    payload: parameter
  }
}

export const saveRoomCheckListSearchCondition = parameter => {
  return {
    type: ROOM_CHECK_LIST,
    payload: parameter
  }
}

export const saveBicycleParkingStickerSearchCondition = parameter => {
  return {
    type: BICYCLE_PARKING_STICKER,
    payload: parameter
  }
}

export const saveCOC2UseParkingSearchCondition = parameter => {
  return {
    type: COC2_USE_PARKING,
    payload: parameter
  }
}

export const savePetSearchCondition = parameter => {
  return {
    type: PET,
    payload: parameter
  }
}

export const saveKeyReceiptSearchCondition = parameter => {
  return {
    type: KEY_RECEIPT,
    payload: parameter
  }
}

export const saveBuildingSearchCondition = parameter => {
  return {
    type: BUILDING,
    payload: parameter
  }
}

export const saveContractSearchCondition = parameter => {
  return {
    type: CONTRACT,
    payload: parameter
  }
}

export const saveFaqSearchCondition = parameter => {
  return {
    type: FAQ,
    payload: parameter
  }
}

export const saveMessageTemplateSearchCondition = parameter => {
  return {
    type: MESSAGE_TEMPLATE,
    payload: parameter
  }
}

export const saveCompanyUserSearchCondition = parameter => {
  return {
    type: COMPANY_USER,
    payload: parameter
  }
}

export const saveSettingBuildingSearchCondition = parameter => {
  return {
    type: SETTING_BUILDING,
    payload: parameter
  }
}

export const deleteSearchConditions = () => {
  return {
    type: DELETE_SEARCH_CONDITIONS
  }
}
