import React, { useEffect, useState, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CustomerHistoryMessageDialogForm from './CustomerHistoryMessageDialogForm'
import Loading from 'components/common/Loading'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { formatYYYYMMDD, formatTime, formatMMDD } from 'utils/date.utils'
import { ThreadState } from 'utils/enum'
import IconWithText from 'components/common/IconWithText'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import RateReviewIcon from '@material-ui/icons/RateReview'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import HelpIcon from '@material-ui/icons/Help'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import {
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Label from 'components/common/Label'
import { PAGE_LIMIT } from 'utils/const'
import { handleOpenPageNewTab } from 'actions/Actions'
import Pagination from 'components/common/Pagination'
import { useRole } from 'hooks/useRole'
import {
  getFormattedNameByStatus,
  getFormattedNameByEnumStatus
} from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  body: {
    flex: 1,
    absolute: 'left',
    backgroundColor: '#fff'
  },
  divider: {
    width: '100%',
    height: '32px'
  },
  detailButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  flex: {
    float: 'left',
    '& div': {
      height: '70px'
    }
  },
  plusIcon: {
    color: theme.palette.c_blue.main,
    fontSize: '45px'
  },
  disabledPlusIcon: {
    fontSize: '45px'
  },
  errorMessage: {
    marginRight: 'auto',
    color: theme.palette.c_alert
  },
  buttonPosition: {
    justifyContent: 'center'
  },
  saveButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  },
  cancelButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_gray.dark
    }
  },
  threadTime: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  },
  displayNone: {
    display: 'none'
  },
  addCircleIcon: {
    textAlign: 'right',
    position: 'relative',
    bottom: '70px',
    right: '40px'
  }
}))

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

const CellWithStatus = ({ status }) => {
  switch (status) {
    case ThreadState.open.code:
      return (
        <IconWithText
          text={ThreadState.open.value}
          icon={<NewReleasesIcon />}
          color={'c_red'}
        />
      )
    case ThreadState.inProgress.code:
      return (
        <IconWithText
          text={ThreadState.inProgress.value}
          icon={<RateReviewIcon />}
          color={'c_yellow'}
        />
      )
    case ThreadState.pending.code:
    case ThreadState.resolve.code:
      return (
        <IconWithText
          text={ThreadState.pending.value}
          icon={<BeenhereIcon />}
          color={'c_blue2'}
        />
      )
    case ThreadState.complete.code:
      return (
        <IconWithText
          text={ThreadState.complete.value}
          icon={<EmojiEmotionsIcon />}
          color={'c_thirdly'}
        />
      )
    default:
      return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
  }
}

const HistoryMessage = ({ data }) => {
  const useStyles = makeStyles(() => ({
    firstCell: {
      width: '10%',
      borderBottom: 'none'
    },
    img: {
      position: 'relative',
      top: 3,
      width: 40,
      height: 'auto',
      alignItems: 'center'
    },
    messageIcon: {
      textAlign: 'left',
      width: '5%'
    },
    messageCell: {
      wordBreak: 'break-all'
    }
  }))

  const classes = useStyles()
  const messageIcon = id => {
    switch (id) {
      case 1:
        return (
          <img
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/forward-customer-history.svg`}
            alt={'forward'}
          />
        )
      case 2:
        return (
          <img
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/reply-customer-history.svg`}
            alt={'reply'}
          />
        )
      case 3:
        return (
          <img
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/account-switch_blue.svg`}
            alt={'accountSwitch'}
          />
        )
      case 4:
        return (
          <img
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/swap-horizontal-circle_blue.svg`}
            alt={'swapHorizontalCircleBlue'}
          />
        )
      case 5:
      case 6:
      case 11:
        return (
          <img
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/lead-pencil_indigo.svg`}
            alt={'leadPencilIndigo'}
          />
        )
      case 7:
        return (
          <img
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/wrench-check.svg`}
            alt={'wrenchCheck'}
          />
        )
      case 8:
        return (
          <img
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/lead-pencil_green.svg`}
            alt={'leadPencilGreen'}
          />
        )
      case 9:
        return (
          <img
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/swap-horizontal-circle_green.svg`}
            alt={'swapHorizontalCircleGreen'}
          />
        )
      case 10:
        return (
          <img
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/account-switch_green.svg`}
            alt={'accountSwitchGreen'}
          />
        )
      default:
        return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
    }
  }

  return (
    <TableRow>
      <TableCell className={classes.firstCell}></TableCell>
      <TableCell className={classes.messageIcon}>
        {messageIcon(data.customer_history_message_type_id)}
      </TableCell>
      <TableCell colSpan={3} className={classes.messageCell}>
        {data.message}
      </TableCell>
      <TableCell align="right" colSpan={2}>
        <Grid item>
          {`${formatMMDD(new Date(data.created_at))} ${formatTime(
            new Date(data.created_at)
          )}`}
        </Grid>
        <Grid>
          {getFormattedNameByEnumStatus(
            data.updated_user,
            data.updated_user_status
          )}
        </Grid>
      </TableCell>
    </TableRow>
  )
}

const HistoryThread = ({
  data,
  addCustomerHistoryMessageList,
  index,
  fetchCustomerHistoryThreadList,
  fetchCustomerHistoryMessageList,
  setAddCustomerHistoryMessageId,
  updateCount,
  setUpdateCount,
  offset,
  customerId,
  setPageNumber,
  id,
  props
}) => {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()
  const [isOpenDetail, setIsOpenDetail] = useState(false)
  const [
    customerHistoryMessagesList,
    setCustomerHistoryMessagesList
  ] = useState(
    data.customer_history_messages ? data.customer_history_messages : []
  )
  const [companyGroupName, setCompanyGroupName] = useState(null)
  const [maxHeight, setMaxHeight] = useState(null)
  const [count, setCount] = useState(0)
  const [value, setValue] = useState(null)
  const ref = useRef(null)
  const history = useHistory()

  const handleChangeValue = event => {
    setCount(event.target.value.length)
    setValue(event.target.value)
  }

  const createRemarksMemo = async () => {
    let params = {}
    let apiName = ''
    if (count > 300) {
      props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
        variant: 'warning'
      })
      return
    }
    if (!window.confirm(`備考メモを追加します。よろしいですか？`)) {
      return
    }
    switch (data.customer_history_thread.type) {
      case 1:
        params['thread_id'] = data.customer_history_thread.original_data_id
        params['body'] = value
        apiName = 'createThreadMemo'
        break
      case 2:
        params['application_id'] = data.customer_history_thread.original_data_id
        params['memo'] = value
        apiName = 'createApplicationMemo'
        break
      case 3:
        params['other_activity_id'] =
          data.customer_history_thread.original_data_id
        params['memo'] = value
        apiName = 'createOtherCustomerActivityMemo'
        break
    }
    let result = await MutationHelper(apiName, { input: params })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('備考メモを保存できませんでした', {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar('備考メモを保存しました', {
        variant: 'success'
      })
      history.push(`/management/contract/detail/${id}`)
      localStorage.setItem('display', 'customerHistory')
      setPageNumber(1)
      setValue(null)
      fetchCustomerHistoryThreadList(customerId, offset, PAGE_LIMIT)
      setIsOpenMemo(false)
    }
  }

  const handleOpenDetail = data => {
    switch (data.customer_history_thread.type) {
      case 3: {
        setIsOpenDetail(true)
        break
      }
      case 1: {
        handleOpenPageNewTab(
          `/message/detail/${data.customer_history_thread.original_data_id}`
        )
        break
      }
      case 2: {
        handleOpenPageNewTab(
          `/application/${determineTransitionApplication(
            data.customer_history_thread.application_type.id
          )}/detail/${data.customer_history_thread.original_data_id}`
        )
        break
      }
      default: {
        break
      }
    }
  }

  const determineTransitionApplication = status => {
    switch (status) {
      case 1: {
        return 'residentInfoChange'
      }
      case 2: {
        return 'residentCancellation'
      }
      case 3: {
        return 'roomCheckList'
      }
      case 4: {
        return 'bicycleParkingSticker'
      }
      case 5: {
        return 'cOC2UseParking'
      }
      case 6: {
        return 'pet'
      }
      case 7: {
        return 'keyReceipt'
      }
      default: {
        break
      }
    }
  }

  const fetchCompanyGroupList = async (id, offset, limit) => {
    let filter = {}
    filter['id'] = {
      eq: id
    }

    let result = await QueryHelper(
      'getCompanyGroup',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('グループ名を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setCompanyGroupName(result.name)
    }
  }

  const handleCloseDetail = (event, isSaving) => {
    event.preventDefault()
    if (!isSaving) {
      if (!window.confirm(`内容が破棄されますがよろしいですか？`)) {
        return
      }
    }
    setIsOpenDetail(false)
  }

  const [isOpenMemo, setIsOpenMemo] = useState(false)

  const handleOpenMemo = async (id, type) => {
    const res = await countNoteMemo(id, type)
    setIsOpenMemo(res)
  }

  const handleCloseMemo = () => {
    setIsOpenMemo(false)
  }

  const countNoteMemo = async (id, type) => {
    let api = ''
    const params = {}
    switch (type) {
      case 1: {
        api = 'getThread'
        params['id'] = { eq: id }
        break
      }
      case 2: {
        api = 'getApplicationMemos'
        params['application_id'] = id
        break
      }
      case 3: {
        api = 'getOtherCustomerActivityMemos'
        params['other_activity_id'] = id
        break
      }
      default: {
        break
      }
    }

    let result = await QueryHelper(api, {
      filter: params
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('備考メモを取得できませんでした。', {
        variant: 'error'
      })
      return false
    } else {
      if (
        type === 1 ? result.thread_memos.length >= 20 : result.data.length >= 20
      ) {
        props.enqueueSnackbar('備考メモは20個までしか作成できません。', {
          variant: 'error'
        })
        return false
      } else {
        return true
      }
    }
  }

  useEffect(() => {
    if (addCustomerHistoryMessageList.length == 0) return
    setCustomerHistoryMessagesList([
      ...customerHistoryMessagesList,
      ...addCustomerHistoryMessageList
    ])
  }, [addCustomerHistoryMessageList])

  useEffect(() => {
    if (data.customer_history_thread.updated_user != null) {
      fetchCompanyGroupList(
        data.customer_history_thread.updated_user.company_group_id
      )
    }
    if (data.customer_history_messages) {
      setCustomerHistoryMessagesList(data.customer_history_messages)
    }
  }, [data])

  const statusLabel = status => {
    switch (status) {
      case 1:
        return <Label color="blue" text="チャット" />
      case 2:
        return <Label color="blue" text="申請" />
      default:
        return <Label color="blue" text="その他" />
    }
  }

  const otherStatusLabel = status => {
    switch (status) {
      case 1:
        return <Label color="blue" text="電話" />
      case 2:
        return <Label color="blue" text="メール" />
      case 3:
        return <Label color="blue" text="郵送" />
      default:
        return <Label color="blue" text="その他" />
    }
  }

  const getTitle = data => {
    return data.customer_history_thread.type == 2
      ? data.customer_history_thread.application_type &&
          data.customer_history_thread.application_type.name
      : data.customer_history_thread.large_category &&
          data.customer_history_thread.large_category.name
  }

  const getSubTitle = data => {
    return data.customer_history_thread.type == 2
      ? null
      : data.customer_history_thread.medium_category &&
          data.customer_history_thread.medium_category.name
  }

  const columns = [
    { id: 'created_at', label: '初回日時', minWidth: 100 },
    { id: 'id', label: '種別', minWidth: 50 },
    { id: 'status_name', label: '対応', minWidth: 50 },
    { id: 'category', label: 'カテゴリ', minWidth: 100 },
    { id: 'updated_at', label: '最終更新日', minWidth: 100 },
    { id: 'updated_by', label: '最終更新者', minWidth: 120 },
    { id: '', label: '', minWidth: 120 }
  ]

  const handleScroll = id => {
    let positionWithAdjustmentValue =
      ref.current.clientHeight + ref.current.scrollTop
    if (positionWithAdjustmentValue >= ref.current.scrollHeight) {
      setAddCustomerHistoryMessageId(id)
      let array = {}
      array[id] = updateCount[id] + 1
      setUpdateCount({ ...updateCount, ...array })
      const offset =
        updateCount[id] > 1 ? (updateCount[id] - 1) * PAGE_LIMIT : 15
      fetchCustomerHistoryMessageList(id, offset, PAGE_LIMIT)
    }
  }

  useEffect(() => {
    // 15件分のデータをデフォルトで表示するために高さを取得
    if (customerHistoryMessagesList.length != 15) return
    // 高さから50px引くことでスクロールを表示させる
    setMaxHeight(
      ref.current &&
        JSON.stringify(ref.current.getBoundingClientRect().height) - 50
    )
  }, [customerHistoryMessagesList])

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead className={index != 0 ? classes.displayNone : null}>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableRow className={classes.body}>
          <TableCell>
            <Grid>
              {formatYYYYMMDD(
                new Date(data.customer_history_thread.created_at)
              )}
            </Grid>
            <Grid className={classes.threadTime}>
              {formatTime(new Date(data.customer_history_thread.created_at))}
            </Grid>
          </TableCell>
          <TableCell
            style={{
              width: '10%'
            }}
          >
            <Grid>
              {data.customer_history_thread.type == 3
                ? otherStatusLabel(
                    data.customer_history_thread.other_activity_type &&
                      data.customer_history_thread.other_activity_type.id
                  )
                : statusLabel(data.customer_history_thread.type)}
            </Grid>
          </TableCell>
          <TableCell>
            <CellWithStatus status={data.customer_history_thread.status} />
          </TableCell>
          <TableCell
            style={{
              width: '30%'
            }}
          >
            <Grid>{getTitle(data)}</Grid>
            <Grid>{getSubTitle(data)}</Grid>
          </TableCell>
          <TableCell
            style={{
              width: '20%'
            }}
          >
            <Grid>
              {formatYYYYMMDD(
                new Date(data.customer_history_thread.updated_at)
              )}
            </Grid>
            <Grid className={classes.threadTime}>
              {formatTime(new Date(data.customer_history_thread.updated_at))}
            </Grid>
          </TableCell>
          <TableCell
            style={{
              width: '20%'
            }}
          >
            <Grid>{companyGroupName}</Grid>
            <Grid>
              {data.customer_history_thread.updated_user &&
                getFormattedNameByStatus(
                  data.customer_history_thread.updated_user.name,
                  data.customer_history_thread.updated_user.status
                )}
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Button
              variant="contained"
              className={classes.detailButton}
              onClick={() => handleOpenDetail(data)}
            >
              詳細
            </Button>
            {isOpenDetail && (
              <CustomerHistoryMessageDialogForm
                open={isOpenDetail}
                handleClose={handleCloseDetail}
                fetchCustomerHistoryThreadList={fetchCustomerHistoryThreadList}
                customerHistoryThread={data.customer_history_thread}
                offset={offset}
                customerId={customerId}
              />
            )}
          </TableCell>
        </TableRow>
      </Table>
      <div
        className={classes.tableWrapper}
        style={{
          overflow: 'auto',
          height: maxHeight
        }}
        ref={ref}
        onScroll={() => handleScroll(data.customer_history_thread.id, index)}
      >
        <Table className={classes.body} stickyHeader aria-label="sticky table">
          {customerHistoryMessagesList.length >= 1
            ? customerHistoryMessagesList.map((data, index) => {
                return <HistoryMessage data={data} key={index} />
              })
            : null}
          <TableRow>
            <TableCell colSpan={7} align="right">
              {isOpenMemo && (
                <Dialog open={isOpenMemo}>
                  <DialogTitle className={classes.dialogColor}>
                    {'備考メモを追加'}
                  </DialogTitle>
                  <DialogContent>
                    <TextareaAutosize
                      required
                      label={'本文'}
                      id="body"
                      style={{ height: 200, width: 650, fontSize: '16px' }}
                      autoComplete="body"
                      rowsMin="5"
                      className={classes.memo}
                      defaultValue={value}
                      onChange={handleChangeValue}
                      placeholder={
                        'ここに備考メモを追加できます。備考メモの内容は入居者には表示されません。'
                      }
                      maxLength={300}
                    />
                    <Grid container justify="flex-end">
                      <Grid className={classes.errorMessage}>
                        <Typography variant="body2">
                          {count > 300
                            ? '文字数制限 : 300文字以内で入力してください'
                            : null}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography variant="body2">{count}</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant="body2">/300</Typography>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className={classes.buttonPosition}>
                    <Button
                      className={classes.cancelButton}
                      onClick={() => handleCloseMemo()}
                    >
                      キャンセル
                    </Button>
                    <Button
                      className={classes.saveButton}
                      onClick={() => createRemarksMemo()}
                    >
                      保存
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </TableCell>
          </TableRow>
        </Table>
      </div>
      <Grid className={classes.addCircleIcon}>
        <IconButton
          aria-label="add"
          onClick={() =>
            handleOpenMemo(
              data.customer_history_thread.original_data_id,
              data.customer_history_thread.type
            )
          }
          disabled={isRestrictedRole}
        >
          <AddCircleIcon
            className={
              isRestrictedRole ? classes.disabledPlusIcon : classes.plusIcon
            }
          />
        </IconButton>
      </Grid>
    </>
  )
}

const CustomerHistory = React.memo(props => {
  const classes = useStyles()
  const { id } = useParams()
  const [customerHistoryThreadList, setCustomerHistoryThreadList] = useState(
    initialState
  )
  const [
    addCustomerHistoryMessageList,
    setAddCustomerHistoryMessageList
  ] = useState([])
  const [
    addCustomerHistoryMessageId,
    setAddCustomerHistoryMessageId
  ] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [updateCount, setUpdateCount] = useState({})
  const [pageNumber, setPageNumber] = useState(1)
  const [customerId, setCustomerId] = useState(null)
  const [offset, setOffset] = useState(0)

  const fetchContract = async contractId => {
    const params = {
      id: {
        eq: contractId
      }
    }
    const result = await QueryHelper('getContract', { filter: params })
    if (!result.error) {
      setCustomerId(result.customer.id)
      await fetchCustomerHistoryThreadList(result.customer.id, 0, PAGE_LIMIT)
    } else {
      props.enqueueSnackbar(`入居者情報を取得できませんでした`, {
        variant: 'error'
      })
    }
  }

  const fetchCustomerHistoryThreadList = async (customerId, offset, limit) => {
    let params = {}
    params.customer_id = {
      eq: customerId
    }
    if (props.searchCondition['customerHistoryTypeId']) {
      params.customer_history_type_id = {
        eq: props.searchCondition['customerHistoryTypeId']
      }
    }
    const result = await QueryHelper('getCustomerHistoryThreadList', {
      filter: params,
      offset: offset,
      limit: limit
    })
    if (!result.error) {
      setCustomerHistoryThreadList(result)
      formatScrollCount(result.data)
    } else {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar(`スレッドを取得できませんでした。`, {
        variant: 'error'
      })
    }
  }

  // APIの更新回数を各スレッドごとに分けるためにidを元に連想配列を作成
  const formatScrollCount = threadList => {
    let array = {}
    threadList.map(data => {
      array[data.customer_history_thread.id] = 2
    })
    setUpdateCount(array)
  }

  const fetchData = async contractId => {
    setIsLoading(true)
    await Promise.all([fetchContract(contractId)])
    setIsLoading(false)
  }

  const fetchCustomerHistoryData = async offset => {
    setIsLoading(true)
    await Promise.all([
      fetchCustomerHistoryThreadList(customerId, offset, PAGE_LIMIT)
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    if (id !== undefined) {
      fetchData(id)
    }
  }, [props])

  const fetchCustomerHistoryMessageList = async (id, offset, limit) => {
    const params = {
      customer_history_thread_id: {
        eq: id
      }
    }
    const result = await QueryHelper('getCustomerHistoryMessageList', {
      filter: params,
      offset: offset,
      limit: limit
    })
    if (!result.error) {
      setAddCustomerHistoryMessageList(
        result.customer_history_messages ? result.customer_history_messages : []
      )
    } else {
      console.log(result)
      props.enqueueSnackbar(`スレッドの詳細を取得できませんでした。`, {
        variant: 'error'
      })
    }
  }

  const handleCustomChangePage = newPage => {
    if (localStorage.getItem('pageNumber')) {
      localStorage.removeItem('pageNumber')
    }
    setPageNumber(newPage)
  }

  useEffect(() => {
    if (customerId != null) {
      const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
      setOffset(offset)
      fetchCustomerHistoryData(offset)
    }
  }, [pageNumber])

  return (
    <>
      <div className={classes.divider} />
      {!isLoading &&
      customerHistoryThreadList.data &&
      customerHistoryThreadList.data.length > 0 ? (
        customerHistoryThreadList.data.map((data, index) => {
          return (
            <React.Fragment key={index}>
              {/* 再度取得したデータと同じidのみcustomerHistoryMessageListを渡す */}
              <HistoryThread
                data={data}
                addCustomerHistoryMessageList={
                  addCustomerHistoryMessageId == data.customer_history_thread.id
                    ? addCustomerHistoryMessageList
                    : []
                }
                index={index}
                fetchCustomerHistoryThreadList={fetchCustomerHistoryThreadList}
                fetchCustomerHistoryMessageList={
                  fetchCustomerHistoryMessageList
                }
                setAddCustomerHistoryMessageId={setAddCustomerHistoryMessageId}
                updateCount={updateCount}
                setUpdateCount={setUpdateCount}
                props={props}
                offset={offset}
                customerId={customerId}
                setPageNumber={setPageNumber}
                id={id}
              />
              <div className={classes.divider} />
            </React.Fragment>
          )
        })
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      {!isLoading && (
        <>
          <div className={classes.flex}>
            <div className={classes.divider} />
          </div>
        </>
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={localStorage.getItem('pageNumber') ? 1 : pageNumber}
        count={customerHistoryThreadList.paging.count}
        rowsPerPage={PAGE_LIMIT}
      />
    </>
  )
})

CustomerHistory.displayName = 'CustomerHistory'
export default withSnackbar(CustomerHistory)
