import React from 'react'
import { useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DateLabel from 'components/common/DateLabel'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import RateReviewIcon from '@material-ui/icons/RateReview'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import HelpIcon from '@material-ui/icons/Help'
import IconWithText from 'components/common/IconWithText'
import { Grid, makeStyles } from '@material-ui/core'
import { KeyReceiptApplicationState } from 'utils/enum'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'
import { handleOpenPage } from 'actions/Actions'
import { useIsTextSelected } from 'hooks/useIsTextSelected'
import { withSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  name: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2c3f58'
  },
  nameKana: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  },
  arrangementImg: {
    position: 'relative',
    top: 3,
    width: 30,
    height: 'auto',
    alignItems: 'center'
  }
}))

const columns = [
  { id: 'application_id', label: 'ID', minWidth: 100, width: 100 },
  {
    id: 'application_state_id',
    label: '状態',
    align: 'center',
    minWidth: 60,
    width: 60
  },
  {
    id: 'application_start_date',
    label: '申請日時',
    minWidth: 120,
    width: 120
  },
  { id: 'applicant_name', label: '入居者名', minWidth: 170 },
  { id: 'building_name', label: '物件名', minWidth: 170 },
  { id: 'key_type', label: '鍵種別', minWidth: 85, width: 85 },
  { id: 'key_count', label: '鍵本数', minWidth: 85, width: 85 },
  { id: 'arrangement', label: '手配', minWidth: 70, width: 70 },
  { id: 'assigned_user', label: '担当者', minWidth: 170 }
]

const CellWithStatus = ({ status }) => {
  switch (status) {
    case KeyReceiptApplicationState.open.code:
      return (
        <IconWithText
          text={KeyReceiptApplicationState.open.value}
          icon={<NewReleasesIcon />}
          color={'c_red'}
        />
      )
    case KeyReceiptApplicationState.inProgress.code:
      return (
        <IconWithText
          text={KeyReceiptApplicationState.inProgress.value}
          icon={<RateReviewIcon />}
          color={'c_yellow'}
        />
      )
    case KeyReceiptApplicationState.complete.code:
      return (
        <IconWithText
          text={KeyReceiptApplicationState.complete.value}
          icon={<BeenhereIcon />}
          color={'c_thirdly'}
        />
      )
    default:
      return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
  }
}

const parseJson = json => {
  try {
    const parsedValue = JSON.parse(json)
    return { parsedValue, error: null }
  } catch (error) {
    return { parsedValue: null, error }
  }
}

const List = props => {
  const classes = useStyles()
  const history = useHistory()
  const isTextSelected = useIsTextSelected()

  const { searchCondition, enqueueSnackbar } = props

  const navigateToDetailPage = (e, id) => {
    if (isTextSelected()) return

    handleOpenPage(
      `/application/keyReceipt/detail/${id}`,
      e,
      history,
      searchCondition
    )
  }

  const getKeyTypeCount = json => {
    const { parsedValue, error } = parseJson(json)
    if (error) {
      enqueueSnackbar('鍵種別のデータ変換に失敗しました', {
        variant: 'error'
      })
      return
    }
    return Array.isArray(parsedValue) ? parsedValue.length : ''
  }

  const getKeyCount = json => {
    const { parsedValue, error } = parseJson(json)
    if (error) {
      enqueueSnackbar('鍵本数のデータ変換に失敗しました', {
        variant: 'error'
      })
      return
    }
    return Array.isArray(parsedValue)
      ? parsedValue.reduce((sum, item) => sum + (item.key_count || 0), 0)
      : ''
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.keyReceiptApplicationList.length
            ? props.keyReceiptApplicationList.map(row => {
                return (
                  <TableRow
                    onClick={e => navigateToDetailPage(e, row.application.id)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.application.id}
                  >
                    <TableCell>{row.application.id}</TableCell>
                    <TableCell>
                      <CellWithStatus
                        status={row.application.application_state_id}
                      />
                    </TableCell>
                    <TableCell>
                      <DateLabel timestamp={row.application.created_at} />
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column">
                        <Grid item className={classes.name}>
                          <span>{row.mst_kokyaku_nky.KKY_NAME}</span>
                        </Grid>
                        <Grid item className={classes.nameKana}>
                          <span>{row.mst_kokyaku_nky.KKY_NAME_KANA}</span>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column">
                        <Grid item>{row.mst_tateya.TTY_NAME}</Grid>
                        <Grid item>{row.mst_heya.HYA_ROOM_NO}</Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid item>
                        {getKeyTypeCount(row.application.content)}
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid item>{getKeyCount(row.application.content)}</Grid>
                    </TableCell>
                    <TableCell>
                      <Grid item>
                        {row.is_arranged &&
                          row.arrangement_status !== 'CANCEL_OR_RELEASE' && (
                            <img
                              className={classes.arrangementImg}
                              src={`${process.env.PUBLIC_URL}/wrench-check.svg`}
                              alt={'wrenchCheck'}
                            />
                          )}
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid item>
                        {row.assigned_user_group &&
                          getFormattedNameByStatus(
                            row.assigned_user_group.name,
                            row.assigned_user_group.status
                          )}
                      </Grid>
                      <Grid item>
                        {row.assigned_user &&
                          getFormattedNameByStatus(
                            row.assigned_user.name,
                            row.assigned_user.status
                          )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    </>
  )
}

export default withSnackbar(List)
