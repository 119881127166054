import React, { useEffect, useState } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import StopIcon from '@material-ui/icons/Stop'
import PeopleIcon from '@material-ui/icons/People'
import BarChartIcon from '@material-ui/icons/BarChart'
import LayersIcon from '@material-ui/icons/Layers'
import { useHistory } from 'react-router-dom'
import Collapse from '@material-ui/core/Collapse'
import { makeStyles } from '@material-ui/core/styles'
import { amber } from '@material-ui/core/colors'
import List from '@material-ui/core/List'
import { handleOpenPage } from 'actions/Actions'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Button from '@material-ui/core/Button'
import { withSnackbar } from 'notistack'
import { AuthIpAddress } from 'components/common/AuthIpAddress'
import { ErrorMessages } from 'utils/errorMessages'
import { useOwnerFunctionEnabled } from 'hooks/useOwnerFunctionEnabled'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiListItem-gutters': {
      paddingLeft: '24px',
      paddingRight: '24px',
      height: '44px'
    },
    '.MuiListItemIcon-root': {
      minWidth: '24px'
    },
    '.MuiListItemText-root': {
      paddingLeft: '8px'
    }
  },
  nested: {
    paddingLeft: theme.spacing(6)
  },
  listItemText: {
    fontSize: '14px',
    color: '#232020'
  },
  listItemIcon: {
    color: '#232020'
  },
  button: {
    borderRadius: '10% / 50%',
    backgroundColor: amber[200],
    '&:hover': {
      backgroundColor: amber[400]
    }
  }
}))

function Menu(props) {
  const history = useHistory()
  const classes = useStyles()
  const { isOwnerFunctionEnabled } = useOwnerFunctionEnabled()
  const { role12 } = useRole()

  const [isOpen1, setOpen1] = useState(false)
  const [isOpen2, setOpen2] = useState(false)
  const [isOpen3, setOpen3] = useState(false)
  const [isOpen4, setOpen4] = useState(false)
  const [isOpen5, setOpen5] = useState(false)
  const [isExternalMenuOpen, setExternalMenuOpen] = useState(true)

  useEffect(() => {
    let item = localStorage.getItem('totono-advertiser-session')
    if (!item) {
      item = sessionStorage.getItem('totono-advertiser-session')
    }
    if (!item) {
      return
    }
    const data = JSON.parse(item)
    if (data.role_id == 5) {
      setExternalMenuOpen(false)
    }
  }, [setExternalMenuOpen])

  return (
    <>
      <ListItem
        button
        onClick={async e => {
          const result = await AuthIpAddress(history)
          if (result) {
            handleOpenPage('/', e, history)
          } else if (result === false) {
            props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
              variant: 'error'
            })
          }
        }}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="ダッシュボード" />
      </ListItem>
      <ListItem button onClick={() => setOpen4(!isOpen4)}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="メッセージ管理" />
      </ListItem>
      <Collapse in={isOpen4} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={e => handleOpenPage('/message/list', e, history)}
          >
            <ListItemText primary="メッセージ一覧" />
          </ListItem>
          {isExternalMenuOpen && (
            <ListItem
              button
              className={classes.nested}
              onClick={async e => {
                const result = await AuthIpAddress(history)
                if (result) {
                  handleOpenPage('/message/bulk-delivery/list', e, history)
                } else if (result === false) {
                  props.enqueueSnackbar(
                    ErrorMessages.FailedToAuthenticateIPAddress,
                    {
                      variant: 'error'
                    }
                  )
                }
              }}
            >
              <ListItemText primary="メッセージ一括配信" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {isExternalMenuOpen && (
        <ListItem button>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText
            primary="お知らせ管理"
            onClick={async e => {
              const result = await AuthIpAddress(history)
              if (result) {
                handleOpenPage('/bulletin-board/list', e, history)
              } else if (result === false) {
                props.enqueueSnackbar(
                  ErrorMessages.FailedToAuthenticateIPAddress,
                  {
                    variant: 'error'
                  }
                )
              }
            }}
          />
        </ListItem>
      )}
      <ListItem button onClick={() => setOpen1(!isOpen1)}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="各種申請" />
      </ListItem>
      <Collapse in={isOpen1} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={async e => {
              const result = await AuthIpAddress(history)
              if (result) {
                handleOpenPage(
                  '/application/residentInfoChange/list',
                  e,
                  history
                )
              } else if (result === false) {
                props.enqueueSnackbar(
                  ErrorMessages.FailedToAuthenticateIPAddress,
                  {
                    variant: 'error'
                  }
                )
              }
            }}
          >
            <ListItemText primary="入居者情報変更" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={async e => {
              const result = await AuthIpAddress(history)
              if (result) {
                handleOpenPage('/application/keyReceipt/list', e, history)
              } else if (result === false) {
                props.enqueueSnackbar(
                  ErrorMessages.FailedToAuthenticateIPAddress,
                  {
                    variant: 'error'
                  }
                )
              }
            }}
          >
            <ListItemText primary="鍵受領書" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={async e => {
              const result = await AuthIpAddress(history)
              if (result) {
                handleOpenPage(
                  '/application/residentCancellation/list',
                  e,
                  history
                )
              } else if (result === false) {
                props.enqueueSnackbar(
                  ErrorMessages.FailedToAuthenticateIPAddress,
                  {
                    variant: 'error'
                  }
                )
              }
            }}
          >
            <ListItemText primary="入居者解約" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={async e => {
              const result = await AuthIpAddress(history)
              if (result) {
                handleOpenPage('/application/roomCheckList/list', e, history)
              } else if (result === false) {
                props.enqueueSnackbar(
                  ErrorMessages.FailedToAuthenticateIPAddress,
                  {
                    variant: 'error'
                  }
                )
              }
            }}
          >
            <ListItemText primary="入居者室内点検表" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={async e => {
              const result = await AuthIpAddress(history)
              if (result) {
                handleOpenPage(
                  '/application/bicycleParkingSticker/list',
                  e,
                  history
                )
              } else if (result === false) {
                props.enqueueSnackbar(
                  ErrorMessages.FailedToAuthenticateIPAddress,
                  {
                    variant: 'error'
                  }
                )
              }
            }}
          >
            <ListItemText primary="駐輪ステッカー" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={async e => {
              const result = await AuthIpAddress(history)
              if (result) {
                handleOpenPage('/application/cOC2UseParking/list', e, history)
              } else if (result === false) {
                props.enqueueSnackbar(
                  ErrorMessages.FailedToAuthenticateIPAddress,
                  {
                    variant: 'error'
                  }
                )
              }
            }}
          >
            <ListItemText primary="保管場所証明書申請" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={async e => {
              const result = await AuthIpAddress(history)
              if (result) {
                handleOpenPage('/application/pet/list', e, history)
              } else if (result === false) {
                props.enqueueSnackbar(
                  ErrorMessages.FailedToAuthenticateIPAddress,
                  {
                    variant: 'error'
                  }
                )
              }
            }}
          >
            <ListItemText primary="ペット飼育申請" />
          </ListItem>
        </List>
      </Collapse>
      {isExternalMenuOpen && isOwnerFunctionEnabled && role12() && (
        <>
          <ListItem button onClick={() => setOpen5(!isOpen5)}>
            <ListItemIcon>
              <StopIcon />
            </ListItemIcon>
            <ListItemText primary="オーナー向け機能" />
          </ListItem>
          <Collapse in={isOpen5} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={async e => {
                  const result = await AuthIpAddress(history)
                  if (result) {
                    handleOpenPage('/ownerMessage/list', e, history)
                  } else if (!result) {
                    props.enqueueSnackbar(
                      ErrorMessages.FailedToAuthenticateIPAddress,
                      {
                        variant: 'error'
                      }
                    )
                  }
                }}
              >
                <ListItemText primary="メッセージ一覧" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={async e => {
                  const result = await AuthIpAddress(history)
                  if (result) {
                    handleOpenPage('/owner/list', e, history)
                  } else if (result === false) {
                    props.enqueueSnackbar(
                      ErrorMessages.FailedToAuthenticateIPAddress,
                      {
                        variant: 'error'
                      }
                    )
                  }
                }}
              >
                <ListItemText primary="オーナーリスト" />
              </ListItem>
            </List>
          </Collapse>
        </>
      )}
      {isExternalMenuOpen && (
        <>
          <ListItem button onClick={() => setOpen2(!isOpen2)}>
            <ListItemIcon>
              <LayersIcon />
            </ListItemIcon>
            <ListItemText primary="運用管理" />
          </ListItem>
          <Collapse in={isOpen2} timeout="auto" unmountOnExit>
            {/* prettier-ignore */}
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={async e => {
                const result = await AuthIpAddress(history)
                if (result) {
                  handleOpenPage('/management/building', e, history)
                } else if (result === false) {
                  props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
                    variant: 'error'
                  })
                }
              }}>
                <ListItemText primary="物件リスト" />
              </ListItem>
              <ListItem button className={classes.nested} onClick={async e => {
                const result = await AuthIpAddress(history)
                if (result) {
                  handleOpenPage('/management/contract/list', e, history)
                } else if (result === false) {
                  props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
                    variant: 'error'
                  })
                }
              }}>
                <ListItemText primary="入居者リスト"/>
              </ListItem>
              <ListItem button className={classes.nested} onClick={async e => {
                const result = await AuthIpAddress(history)
                if (result) {
                  handleOpenPage('/management/package/list', e, history)
                } else if (result === false) {
                  props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
                    variant: 'error'
                  })
                }
              }}>
                <ListItemText primary="入居者サービス"/>
              </ListItem>
              <ListItem button className={classes.nested} onClick={async e => {
                const result = await AuthIpAddress(history)
                if (result) {
                  handleOpenPage('/management/faq/list', e, history)
                } else if (result === false) {
                  props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
                    variant: 'error'
                  })
                }
              }}>
                <ListItemText primary="FAQリスト"/>
              </ListItem>
              <ListItem button className={classes.nested} onClick={async e => {
                const result = await AuthIpAddress(history)
                if (result) {
                  handleOpenPage('/management/message-template/list', e, history)
                } else if (result === false) {
                  props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
                    variant: 'error'
                  })
                }
              }}>
                <ListItemText primary="定型文リスト" />
              </ListItem>
            </List>
          </Collapse>
        </>
      )}
      {isExternalMenuOpen && (
        <>
          <ListItem button onClick={() => setOpen3(!isOpen3)}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="設定" />
          </ListItem>
          <Collapse in={isOpen3} timeout="auto" unmountOnExit>
            {/* prettier-ignore */}
            <List component="div" disablePadding>
          <ListItem button className={classes.nested} onClick={async e => {
            const result = await AuthIpAddress(history)
            if (result) {
              handleOpenPage('/setting/company', e, history)
            } else if (result === false) {
              props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
                variant: 'error'
              })
            }
          }}>
            <ListItemText primary="会社情報" />
          </ListItem>
          <ListItem button className={classes.nested} onClick={async e => {
            const result = await AuthIpAddress(history)
            if (result) {
              handleOpenPage('/setting/company-group/list', e, history)
            } else if (result === false) {
              props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
                variant: 'error'
              })
            }
          }}>
            <ListItemText primary="グループ設定" />
          </ListItem>
          <ListItem button className={classes.nested} onClick={async e => {
            const result = await AuthIpAddress(history)
            if (result) {
              handleOpenPage('/setting/company-user/list', e, history)
            } else if (result === false) {
              props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
                variant: 'error'
              })
            }
          }}>
            <ListItemText primary="利用者管理" />
          </ListItem>
          <ListItem button className={classes.nested} onClick={async e => {
            const result = await AuthIpAddress(history)
            if (result) {
              handleOpenPage('/setting/building/list', e, history)
            } else if (result === false) {
              props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
                variant: 'error'
              })
            }
          }}>
            <ListItemText primary="物件管理設定"/>
          </ListItem>
          <ListItem button className={classes.nested} onClick={async e => {
            const result = await AuthIpAddress(history)
            if (result) {
              handleOpenPage('/setting/bookmark', e, history)
            } else if (result === false) {
              props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
                variant: 'error'
              })
            }
          }}>
            <ListItemText primary="しおり設定"/>
          </ListItem>
          <ListItem button className={classes.nested} onClick={async e => {
            const result = await AuthIpAddress(history)
            if (result) {
              handleOpenPage('/setting/data-link-history', e, history)
            } else if (result === false) {
              props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
                variant: 'error'
              })
            }
          }}>
            <ListItemText primary="ファイル連携"/>
          </ListItem>
          <ListItem button className={classes.nested} onClick={async e => {
            const result = await AuthIpAddress(history)
            if (result) {
              handleOpenPage('/setting/message-auto-reply', e, history)
            } else if (result === false) {
              props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
                variant: 'error'
              })
            }
          }}>
            <ListItemText primary="自動返信設定"/>
          </ListItem>
          {/* #4704で解約申請設定の刷新により以前の設定は運用後に削除 */}
          {/* <ListItem button className={classes.nested} onClick={(e) => handleOpenPage('/setting/cancellation-application-option', e, history)}>
            <ListItemText primary="解約申請設定"/>
          </ListItem> */}
          <ListItem button className={classes.nested} onClick={async e => {
            const result = await AuthIpAddress(history)
            if (result) {
              handleOpenPage('/setting/bulk-delivery/template/list', e, history)
            } else if (result === false) {
              props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
                variant: 'error'
              })
            }
          }}>
            <ListItemText primary="一括配信設定"/>
          </ListItem>
          <ListItem button className={classes.nested} onClick={async e => {
            const result = await AuthIpAddress(history)
            if (result) {
              handleOpenPage('/setting/email-notification', e, history)
            } else if (result === false) {
              props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
                variant: 'error'
              })
            }
          }}>
            <ListItemText primary="通知設定"/>
          </ListItem>
          <ListItem button className={classes.nested} onClick={(e) => handleOpenPage('/setting/residentInfoChange', e, history)}>
            <ListItemText primary="入居者情報変更申請設定"/>
          </ListItem>
          <ListItem button className={classes.nested} onClick={(e) => handleOpenPage('/setting/keyReceipt', e, history)}>
            <ListItemText primary="鍵受領書申請設定"/>
          </ListItem>
          <ListItem button className={classes.nested} onClick={(e) => handleOpenPage('/setting/residentCancellation', e, history)}>
            <ListItemText primary="解約申請設定"/>
          </ListItem>
          <ListItem button className={classes.nested} onClick={(e) => handleOpenPage('/setting/roomCheckList', e, history)}>
            <ListItemText primary="入居者室内点検表設定"/>
          </ListItem>
          <ListItem button className={classes.nested} onClick={(e) => handleOpenPage('/setting/bicycleParkingSticker', e, history)}>
            <ListItemText primary="駐輪ステッカー申請設定"/>
          </ListItem>
          <ListItem button className={classes.nested} onClick={(e) => handleOpenPage('/setting/cOC2UseParking', e, history)}>
            <ListItemText primary="駐車場証明書申請設定"/>
          </ListItem>
          <ListItem button className={classes.nested} onClick={(e) => handleOpenPage('/setting/pet', e, history)}>
            <ListItemText primary="ペット飼育申請設定"/>
          </ListItem>
        </List>
            {/* prettier-ignore-end  */}
          </Collapse>
        </>
      )}
      <div
        style={{
          boxSizing: 'border-box',
          position: 'fixed',
          width: '240px',
          backgroundColor: '#fff',
          bottom: '0',
          paddingBottom: '4px',
          textAlign: 'center'
        }}
      >
        <Button
          target="_blank"
          href="https://totono.sumasapo.co.jp/administrator/administrator-management/"
          variant="contained"
          startIcon={<HelpOutlineIcon />}
          disableElevation
          className={classes.button}
        >
          お困りの方はこちら
        </Button>
        <div>外部サイトが開きます</div>
      </div>
    </>
  )
}

export default withSnackbar(Menu)
