import React, { useReducer } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import Signin from 'components/authorize/Signin'
import Auth from 'components/authorize/Auth'
import AuthAdmin from 'components/authorize/AuthAdmin'
import Layout from 'components/common/Layout'
import Dashboard from 'containers/dashboard/Screen'
// import Dashboard from 'components/dashboard/App';
import SettingCompany from 'containers/setting/Company/EditScreen'
import SettingCompanyGroup from 'containers/setting/CompanyGroup/ListScreen'
import SettingCompanyUser from 'containers/setting/CompanyUser/ListScreen'
import SettingCompanyUserEdit from 'containers/setting/CompanyUser/Edit'
import SettingBuilding from 'containers/setting/Building/ListScreen'
import SettingBookmark from 'containers/setting/Bookmark/EditScreen'
import SettingDataLinkHistory from 'containers/setting/DataLinkHistory/ListScreen'
import SettingResidentInfoChange from 'containers/setting/ResidentInfoChange/EditScreen'
import SettingPet from 'containers/setting/Pet/EditScreen'
import SettingCOC2UseParking from 'containers/setting/COC2UseParking/EditScreen'
import SettingBicycleParkingSticker from 'containers/setting/BicycleParkingSticker/EditScreen'
import SettingRoomCheckList from 'containers/setting/RoomCheckList/EditScreen'
import SettingResidentCancellation from 'containers/setting/ResidentCancellation/EditScreen'
import SettingKeyReceipt from 'containers/setting/KeyReceipt/EditScreen'
import ManagementBuilding from 'containers/management/Building/ListScreen'
import ManagementBuildingDetail from 'containers/management/Building/Detail'
import ManagementRoom from 'containers/management/Building/Room'
import ManagementRoomDetail from 'containers/management/Building/Room/Detail'
import ManagementContract from 'containers/management/Contract/ListScreen'
import ManagementContractDetail from 'containers/management/Contract/Detail/'
import ManagementPackageHeaderBody from 'containers/management/Package/Header/Body'
import ManagementPackage from 'containers/management/Package/ListScreen'
import ManagementPackageDetailHeaderBody from 'containers/management/Package/Detail/Header/Body'
import ManagementPackageDetail from 'containers/management/Package/Detail/EditScreen'
import ManagementFaq from 'containers/management/Faq/ListScreen'
import ManagementFaqEdit from 'containers/management/Faq/Edit'
import ManagementMessageTemplate from 'containers/management/MessageTemplate/ListScreen'
import ManagementMessageTemplateEdit from 'containers/management/MessageTemplate/Edit'
import ManagementPetApplicationHeaderBody from 'containers/application/Pet/Header/Body'
import ManagementPetApplication from 'containers/application/Pet/ListScreen'
import ManagementPetApplicationDetailHeaderBody from 'containers/application/Pet/Detail/Header/Body'
import ManagementPetApplicationDetail from 'containers/application/Pet/Detail'
import ManagementCOC2UseParkingApplicationHeaderBody from 'containers/application/COC2UseParking/Header/Body'
import ManagementCOC2UseParkingApplication from 'containers/application/COC2UseParking/ListScreen'
import ManagementCOC2UseParkingApplicationDetailHeaderBody from 'containers/application/COC2UseParking/Detail/Header/Body'
import ManagementCOC2UseParkingApplicationDetail from 'containers/application/COC2UseParking/Detail'
import ManagementBicycleParkingStickerApplicationHeaderBody from 'containers/application/BicycleParkingSticker/Header/Body'
import ManagementBicycleParkingStickerApplication from 'containers/application/BicycleParkingSticker/ListScreen'
import ManagementBicycleParkingStickerApplicationDetailHeaderBody from 'containers/application/BicycleParkingSticker/Detail/Header/Body'
import ManagementBicycleParkingStickerApplicationDetail from 'containers/application/BicycleParkingSticker/Detail'
import ManagementRoomCheckListApplicationHeaderBody from 'containers/application/RoomCheckList/Header/Body'
import ManagementRoomCheckListApplication from 'containers/application/RoomCheckList/ListScreen'
import ManagementRoomCheckListApplicationDetailHeaderBody from 'containers/application/RoomCheckList/Detail/Header/Body'
import ManagementRoomCheckListApplicationDetail from 'containers/application/RoomCheckList/Detail'
import ManagementResidentInfoChangeApplicationDetail from 'containers/application/ResidentInfoChange/Detail'
import ManagementResidentInfoChangeApplicationHeaderBody from 'containers/application/ResidentInfoChange/Header/Body'
import ManagementResidentInfoChangeApplication from 'containers/application/ResidentInfoChange/ListScreen'
import ManagementResidentInfoChangeApplicationDetailHeaderBody from 'containers/application/ResidentInfoChange/Detail/Header/Body'
import ManagementResidentCancellationApplicationHeaderBody from 'containers/application/ResidentCancellation/Header/Body'
import ManagementResidentCancellationApplication from 'containers/application/ResidentCancellation/ListScreen'
import ManagementResidentCancellationApplicationDetailHeaderBody from 'containers/application/ResidentCancellation/Detail/Header/Body'
import ManagementResidentCancellationApplicationDetail from 'containers/application/ResidentCancellation/Detail'
import ManagementKeyReceiptListApplicationHeaderBody from 'containers/application/KeyReceipt/Header/Body'
import ManagementKeyReceiptListApplication from 'containers/application/KeyReceipt/ListScreen'
import ManagementKeyReceiptDetailHeaderBody from 'containers/application/KeyReceipt/Detail/Header/Body'
import ManagementKeyReceiptDetail from 'containers/application/KeyReceipt/Detail'
import lastPathReducer from 'reducers/LastPathReducer'
import { LastPathContext } from 'context/LastPathContext'
import searchConditionReducerFunc from 'reducers/SearchConditionReducerFunc'
import { SearchConditionContext } from 'context/SearchConditionContext'
import notificationReducer from 'reducers/NotificationReducer'
import { NotificationContext } from 'context/NotificationContext'
import DefaultBody from 'components/common/Header/DefaultBody'
import ManagementMessageTemplateDefaultBody from 'containers/management/MessageTemplate/Header/Body'
import ManagementMessageTemplateEditBody from 'containers/management/MessageTemplate/Edit/Header/Body'
import ManagementContractDefaultBody from 'containers/management/Contract/Header/Body'
import ManagementContractDetailDefaultBody from 'containers/management/Contract/Detail/Header/Body'
import ManagementBuildingHeaderBody from 'containers/management/Building/Header/Body'
import ManagementBuildingDetailHeaderBody from 'containers/management/Building/Detail/Header/Body'
import ManagementRoomHeaderBody from 'containers/management/Building/Room/Header/Body'
import ManagementRoomDetailHeaderBody from 'containers/management/Building/Room/Detail/Header/Body'
import ManagementFaqHeaderBody from 'containers/management/Faq/Header/Body'
import ManagementFaqEditHeaderBody from 'containers/management/Faq/Edit/Header/Body'
import SettingCompanyUserHeaderBody from 'containers/setting/CompanyUser/Header/Body'
import SettingCompanyUserEditHeaderBody from 'containers/setting/CompanyUser/Edit/Header/Body'
import SettingBuildingHeaderBody from 'containers/setting/Building/Header/Body'
import MessageHeaderBody from 'containers/message/Header/Body'
import Message from 'containers/message/ListScreen'
import MessageDetailHeaderBody from 'containers/message/Detail/Header/Body'
import MessageDetail from 'containers/message/Detail'
import BulkDeliverySelect from 'containers/message/BulkDelivery/Select/ListScreen'
import BulkDeliveryConfirm from './message/BulkDelivery/Confirm/Confirm'
import BulkDeliveryConfirmHeaderBody from './message/BulkDelivery/Confirm/Header/Body'
import BulkDeliveryList from 'containers/message/BulkDelivery/ListScreen'
import BulkDeliveryHeaderBody from 'containers/message/BulkDelivery/Header/Body'
import BulkDeliveryImportCsvData from './message/BulkDelivery/Import/ImportCsvData'
import BulkDeliveryImportHeaderBody from './message/BulkDelivery/Import/Header/Body'
import BulkDeliverySearch from 'containers/message/BulkDelivery/Search/ListScreen'
import BulkDeliverySearchHeaderBody from 'containers/message/BulkDelivery/Search/Header/Body'
import BulletinBoardHeaderBody from 'containers/bulletinBoard/Header/Body'
import BulletinBoard from 'containers/bulletinBoard/ListScreen'
import BulletinBoardEditHeaderBody from 'containers/bulletinBoard/Edit/Header/Body'
import BulletinBoardEdit from 'containers/bulletinBoard/Edit'
import { connect } from 'react-redux'
import { changeHeaderBody } from 'actions/Actions'
import BackDropLoading from 'components/common/BackDropLoading'
import UsePageTracking from 'components/common/UsePageTracking'
import AdminCsvImport from 'containers/admin/CsvImport/ListScreen'
import MessageAutoReply from 'containers/setting/MessageAutoReply/EditScreen'
// import CancellationApplicationOption from 'containers/setting/CancellationApplicationOption/EditScreen'
import BulkDeliveryTemplateList from 'containers/setting/BulkDeliveryTemplate/ListScreen'
import BulkDeliveryTemplateListHeaderBody from 'containers/setting/BulkDeliveryTemplate/Header/Body'
import BulkDeliveryTemplateEdit from 'containers/setting/BulkDeliveryTemplate/Edit/EditScreen'
import BulkDeliveryTemplateEditHeaderBody from 'containers/setting/BulkDeliveryTemplate/Edit/Header/Body'
import BulkDeliveryEdit from 'containers/message/BulkDelivery/EditScreen'
import EmailNotification from 'containers/setting/EmailNotification/EditScreen'
import OwnerHeaderBody from 'containers/owner/Header/Body'
import OwnerList from 'containers/owner/ListScreen'
import OwnerDetailHeaderBody from 'containers/owner/Detail/Header/Body'
import OwnerDetail from 'containers/owner/Detail'
import ProxySignin from 'components/authorize/ProxySignin'
import CcProxySignin from 'components/authorize/cc/ProxySignin'
import ErrorScreen from 'components/common/ErrorScreen'

const Router = props => {
  const stateReducer = useReducer(lastPathReducer, { lastPath: '/' })
  const searchConditionReducer = useReducer(searchConditionReducerFunc, {
    searchCondition: {}
  })
  const notificationState = useReducer(notificationReducer, { isReload: false })
  const { changeHeaderBody } = props
  return (
    <BrowserRouter>
      {/* prettier-ignore */}
      {/*<BackDropLoading/>*/}

      <LastPathContext.Provider value={stateReducer}>
        <SearchConditionContext.Provider value={searchConditionReducer}>
          <NotificationContext.Provider value={notificationState}>
            <BackDropLoading />
            <UsePageTracking />
            <Switch>
              <Route exact path="/authorize/signin" component={Signin} />
              <Route
                exact
                path="/authorize/proxy/signin"
                component={ProxySignin}
              />
              <Route
                exact
                path="/authorize/cc/proxy/signin"
                component={CcProxySignin}
              />
              <Route exact path="/error" component={ErrorScreen} />
              <Auth>
                <Layout>
                  {/* <Route exact path="/" component={Dashboard} /> */}
                  <Route
                    exact
                    path="/"
                    render={props => {
                      changeHeaderBody(<DefaultBody title="ダッシュボード" />)
                      return <Dashboard {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/company"
                    render={props => {
                      changeHeaderBody(<DefaultBody title="会社情報" />)
                      return <SettingCompany {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/company-group/list"
                    render={props => {
                      changeHeaderBody(<DefaultBody title="グループ設定" />)
                      return <SettingCompanyGroup {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/company-group/list/:pageId"
                    render={props => {
                      changeHeaderBody(<DefaultBody title="グループ設定" />)
                      return <SettingCompanyGroup {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/company-user"
                    render={props => {
                      changeHeaderBody(
                        <SettingCompanyUserHeaderBody title="利用者管理" />
                      )
                      return <SettingCompanyUser {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/company-user/list/"
                    render={props => {
                      changeHeaderBody(
                        <SettingCompanyUserHeaderBody title="利用者管理" />
                      )
                      return <SettingCompanyUser {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/company-user/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <SettingCompanyUserHeaderBody title="利用者管理" />
                      )
                      return <SettingCompanyUser {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/company-user/new"
                    render={props => {
                      changeHeaderBody(
                        <SettingCompanyUserEditHeaderBody title="利用者詳細" />
                      )
                      return <SettingCompanyUserEdit {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/company-user/edit/:id"
                    render={props => {
                      changeHeaderBody(
                        <SettingCompanyUserEditHeaderBody title="利用者詳細" />
                      )
                      return <SettingCompanyUserEdit {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/building/list/"
                    render={props => {
                      changeHeaderBody(
                        <SettingBuildingHeaderBody title="物件管理設定" />
                      )
                      return <SettingBuilding {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/building/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <SettingBuildingHeaderBody title="物件管理設定" />
                      )
                      return <SettingBuilding {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/bookmark"
                    render={props => {
                      changeHeaderBody(<DefaultBody title="しおり設定" />)
                      return <SettingBookmark {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/data-link-history"
                    render={props => {
                      changeHeaderBody(<DefaultBody title="ファイル連携" />)
                      return <SettingDataLinkHistory {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/residentInfoChange"
                    render={props => {
                      changeHeaderBody(
                        <DefaultBody title="入居者情報変更申請設定" />
                      )
                      return <SettingResidentInfoChange {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/residentCancellation"
                    render={props => {
                      changeHeaderBody(<DefaultBody title="解約申請設定" />)
                      return <SettingResidentCancellation {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/roomCheckList"
                    render={props => {
                      changeHeaderBody(
                        <DefaultBody title="入居者室内点検表設定" />
                      )
                      return <SettingRoomCheckList {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/bicycleParkingSticker"
                    render={props => {
                      changeHeaderBody(
                        <DefaultBody title="駐輪ステッカー申請設定" />
                      )
                      return <SettingBicycleParkingSticker {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/cOC2UseParking"
                    render={props => {
                      changeHeaderBody(
                        <DefaultBody title="保管場所使用承諾証明書申請設定" />
                      )
                      return <SettingCOC2UseParking {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/pet"
                    render={props => {
                      changeHeaderBody(
                        <DefaultBody title="ペット飼育申請設定" />
                      )
                      return <SettingPet {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/keyReceipt"
                    render={props => {
                      changeHeaderBody(<DefaultBody title="鍵受領書申請設定" />)
                      return <SettingKeyReceipt {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/building"
                    render={props => {
                      changeHeaderBody(
                        <ManagementBuildingHeaderBody title="物件リスト" />
                      )
                      return <ManagementBuilding {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/building/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <ManagementBuildingHeaderBody title="物件リスト" />
                      )
                      return <ManagementBuilding {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/building/:id"
                    render={props => {
                      changeHeaderBody(
                        <ManagementBuildingDetailHeaderBody title="物件詳細" />
                      )
                      return <ManagementBuildingDetail {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/building/:buildingId/room"
                    render={props => {
                      changeHeaderBody(
                        <ManagementRoomHeaderBody title="部屋リスト" />
                      )
                      return <ManagementRoom {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/building/:buildingId/room/:id"
                    render={props => {
                      changeHeaderBody(
                        <ManagementRoomDetailHeaderBody title="部屋詳細" />
                      )
                      return <ManagementRoomDetail {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/contract/list"
                    render={props => {
                      changeHeaderBody(
                        <ManagementContractDefaultBody title="入居者リスト" />
                      )
                      return <ManagementContract {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/contract/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <ManagementContractDefaultBody title="入居者リスト" />
                      )
                      return <ManagementContract {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/contract/detail/:id"
                    render={props => {
                      changeHeaderBody(
                        <ManagementContractDetailDefaultBody title="入居者詳細" />
                      )
                      return <ManagementContractDetail {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/package/list"
                    render={props => {
                      changeHeaderBody(
                        <ManagementPackageHeaderBody title="入居者サービス" />
                      )
                      return <ManagementPackage {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/package/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <ManagementPackageHeaderBody title="入居者サービス" />
                      )
                      return <ManagementPackage {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/package/new"
                    render={props => {
                      changeHeaderBody(
                        <ManagementPackageDetailHeaderBody title="入居者サービス" />
                      )
                      return <ManagementPackageDetail {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/package/edit/:packageId"
                    render={props => {
                      changeHeaderBody(
                        <ManagementPackageDetailHeaderBody title="入居者サービス" />
                      )
                      return <ManagementPackageDetail {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/faq/new"
                    render={props => {
                      changeHeaderBody(
                        <ManagementFaqEditHeaderBody title="新規作成 | FAQリスト" />
                      )
                      return <ManagementFaqEdit {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/faq/list/"
                    render={props => {
                      changeHeaderBody(
                        <ManagementFaqHeaderBody title="FAQリスト" />
                      )
                      return <ManagementFaq {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/faq/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <ManagementFaqHeaderBody title="FAQリスト" />
                      )
                      return <ManagementFaq {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/faq/edit/:faqId"
                    render={props => {
                      changeHeaderBody(
                        <ManagementFaqEditHeaderBody title="FAQリスト" />
                      )
                      return <ManagementFaqEdit {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/application/pet/list"
                    render={props => {
                      changeHeaderBody(
                        <ManagementPetApplicationHeaderBody title="ペット飼育" />
                      )
                      return <ManagementPetApplication {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/application/pet/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <ManagementPetApplicationHeaderBody title="ペット飼育" />
                      )
                      return <ManagementPetApplication {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/application/pet/detail/:id"
                    render={props => {
                      changeHeaderBody(
                        <ManagementPetApplicationDetailHeaderBody title="ペット飼育詳細" />
                      )
                      return <ManagementPetApplicationDetail {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/application/cOC2UseParking/list"
                    render={props => {
                      changeHeaderBody(
                        <ManagementCOC2UseParkingApplicationHeaderBody title="保管場所使用承諾証明書" />
                      )
                      return <ManagementCOC2UseParkingApplication {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/application/cOC2UseParking/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <ManagementCOC2UseParkingApplicationHeaderBody title="保管場所使用承諾証明書" />
                      )
                      return <ManagementCOC2UseParkingApplication {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/application/cOC2UseParking/detail/:id"
                    render={props => {
                      changeHeaderBody(
                        <ManagementCOC2UseParkingApplicationDetailHeaderBody title="保管場所使用承諾証明書詳細" />
                      )
                      return (
                        <ManagementCOC2UseParkingApplicationDetail {...props} />
                      )
                    }}
                  />
                  <Route
                    exact
                    path="/application/bicycleParkingSticker/list"
                    render={props => {
                      changeHeaderBody(
                        <ManagementBicycleParkingStickerApplicationHeaderBody title="駐輪ステッカー" />
                      )
                      return (
                        <ManagementBicycleParkingStickerApplication
                          {...props}
                        />
                      )
                    }}
                  />
                  <Route
                    exact
                    path="/application/bicycleParkingSticker/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <ManagementBicycleParkingStickerApplicationHeaderBody title="駐輪ステッカー" />
                      )
                      return (
                        <ManagementBicycleParkingStickerApplication
                          {...props}
                        />
                      )
                    }}
                  />
                  <Route
                    exact
                    path="/application/bicycleParkingSticker/detail/:id"
                    render={props => {
                      changeHeaderBody(
                        <ManagementBicycleParkingStickerApplicationDetailHeaderBody title="駐輪ステッカー詳細" />
                      )
                      return (
                        <ManagementBicycleParkingStickerApplicationDetail
                          {...props}
                        />
                      )
                    }}
                  />
                  <Route
                    exact
                    path="/application/roomCheckList/list"
                    render={props => {
                      changeHeaderBody(
                        <ManagementRoomCheckListApplicationHeaderBody title="入居者室内点検表" />
                      )
                      return <ManagementRoomCheckListApplication {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/application/roomCheckList/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <ManagementRoomCheckListApplicationHeaderBody title="入居者室内点検表" />
                      )
                      return <ManagementRoomCheckListApplication {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/application/roomCheckList/detail/:id"
                    render={props => {
                      changeHeaderBody(
                        <ManagementRoomCheckListApplicationDetailHeaderBody title="入居者室内点検表詳細" />
                      )
                      return (
                        <ManagementRoomCheckListApplicationDetail {...props} />
                      )
                    }}
                  />
                  <Route
                    exact
                    path="/application/residentInfoChange/list"
                    render={props => {
                      changeHeaderBody(
                        <ManagementResidentInfoChangeApplicationHeaderBody title="入居者情報変更" />
                      )
                      return (
                        <ManagementResidentInfoChangeApplication {...props} />
                      )
                    }}
                  />
                  <Route
                    exact
                    path="/application/residentInfoChange/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <ManagementResidentInfoChangeApplicationHeaderBody title="入居者情報変更" />
                      )
                      return (
                        <ManagementResidentInfoChangeApplication {...props} />
                      )
                    }}
                  />
                  <Route
                    exact
                    path="/application/residentInfoChange/detail/:id"
                    render={props => {
                      changeHeaderBody(
                        <ManagementResidentInfoChangeApplicationDetailHeaderBody title="入居者情報詳細" />
                      )
                      return (
                        <ManagementResidentInfoChangeApplicationDetail
                          {...props}
                        />
                      )
                    }}
                  />
                  <Route
                    exact
                    path="/application/residentCancellation/list"
                    render={props => {
                      changeHeaderBody(
                        <ManagementResidentCancellationApplicationHeaderBody title="入居者解約" />
                      )
                      return (
                        <ManagementResidentCancellationApplication {...props} />
                      )
                    }}
                  />
                  <Route
                    exact
                    path="/application/residentCancellation/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <ManagementResidentCancellationApplicationHeaderBody title="入居者解約" />
                      )
                      return (
                        <ManagementResidentCancellationApplication {...props} />
                      )
                    }}
                  />
                  <Route
                    exact
                    path="/application/residentCancellation/detail/:id"
                    render={props => {
                      changeHeaderBody(
                        <ManagementResidentCancellationApplicationDetailHeaderBody title="入居者解約詳細" />
                      )
                      return (
                        <ManagementResidentCancellationApplicationDetail
                          {...props}
                        />
                      )
                    }}
                  />
                  <Route
                    exact
                    path="/application/keyReceipt/list"
                    render={props => {
                      changeHeaderBody(
                        <ManagementKeyReceiptListApplicationHeaderBody title="鍵受領書" />
                      )
                      return <ManagementKeyReceiptListApplication {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/application/keyReceipt/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <ManagementKeyReceiptListApplicationHeaderBody title="鍵受領書" />
                      )
                      return <ManagementKeyReceiptListApplication {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/application/keyReceipt/detail/:id"
                    render={props => {
                      changeHeaderBody(
                        <ManagementKeyReceiptDetailHeaderBody title="鍵受領書詳細" />
                      )
                      return <ManagementKeyReceiptDetail {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/message-template"
                    render={props => {
                      changeHeaderBody(
                        <ManagementMessageTemplateDefaultBody title="定型文リスト" />
                      )
                      return <ManagementMessageTemplate {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/message-template/list/"
                    render={props => {
                      changeHeaderBody(
                        <ManagementMessageTemplateDefaultBody title="定型文リスト" />
                      )
                      return <ManagementMessageTemplate {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/message-template/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <ManagementMessageTemplateDefaultBody title="定型文リスト" />
                      )
                      return <ManagementMessageTemplate {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/message-template/new"
                    render={props => {
                      changeHeaderBody(
                        <ManagementMessageTemplateEditBody title="定型文リスト" />
                      )
                      return <ManagementMessageTemplateEdit {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/management/message-template/edit/:id"
                    render={props => {
                      changeHeaderBody(
                        <ManagementMessageTemplateEditBody title="定型文リスト" />
                      )
                      return <ManagementMessageTemplateEdit {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/message/list"
                    render={props => {
                      changeHeaderBody(
                        <MessageHeaderBody title="メッセージ管理" />
                      )
                      return <Message {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/message/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <MessageHeaderBody title="メッセージ管理" />
                      )
                      return <Message {...props} />
                    }}
                  />
                  {/* オーナー用 */}
                  <Route
                    exact
                    path="/ownerMessage/list"
                    render={props => {
                      changeHeaderBody(
                        <MessageHeaderBody title="メッセージ管理" />
                      )
                      return <Message {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/ownerMessage/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <MessageHeaderBody title="メッセージ管理" />
                      )
                      return <Message {...props} />
                    }}
                  />
                  {/* オーナー用 */}
                  <Route
                    exact
                    path="/message/detail/:id"
                    render={props => {
                      changeHeaderBody(
                        <MessageDetailHeaderBody title="メッセージ管理詳細" />
                      )
                      return <MessageDetail {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/ownerMessage/detail/:id"
                    render={props => {
                      changeHeaderBody(
                        <MessageDetailHeaderBody title="メッセージ管理詳細" />
                      )
                      return <MessageDetail {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/message/bulk-delivery/list"
                    render={props => {
                      changeHeaderBody(
                        <BulkDeliveryHeaderBody title="メッセージ一括配信リスト" />
                      )
                      return <BulkDeliveryList {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/message/bulk-delivery/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <BulkDeliveryHeaderBody title="メッセージ一括配信リスト" />
                      )
                      return <BulkDeliveryList {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/message/bulk-delivery/import"
                    render={props => {
                      changeHeaderBody(
                        <BulkDeliveryImportHeaderBody title="メッセージ一括配信＞データ取り込み" />
                      )
                      return <BulkDeliveryImportCsvData {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/message/bulk-delivery/select/:id"
                    render={props => {
                      changeHeaderBody(
                        <DefaultBody title="メッセージ一括配信＞送信先設定" />
                      )
                      return <BulkDeliverySelect {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/message/bulk-delivery/search/"
                    render={props => {
                      changeHeaderBody(
                        <BulkDeliverySearchHeaderBody title="メッセージ一括配信先設定" />
                      )
                      return <BulkDeliverySearch {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/message/bulk-delivery/search/:id"
                    render={props => {
                      changeHeaderBody(
                        <BulkDeliverySearchHeaderBody title="メッセージ一括配信先設定" />
                      )
                      return <BulkDeliverySearch {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/message/bulk-delivery/edit"
                    render={props => {
                      changeHeaderBody(
                        <DefaultBody title="メッセージ一括配信＞配信内容設定" />
                      )
                      return <BulkDeliveryEdit />
                    }}
                  />
                  <Route
                    exact
                    path="/message/bulk-delivery/confirm/:id"
                    render={props => {
                      changeHeaderBody(
                        <BulkDeliveryConfirmHeaderBody title="メッセージ一括配信＞最終確認・個別設定" />
                      )
                      return <BulkDeliveryConfirm {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/bulletin-board/list"
                    render={props => {
                      changeHeaderBody(
                        <BulletinBoardHeaderBody title="お知らせ管理" />
                      )
                      return <BulletinBoard {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/bulletin-board/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <BulletinBoardHeaderBody title="お知らせ管理" />
                      )
                      return <BulletinBoard {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/bulletin-board/new"
                    render={props => {
                      changeHeaderBody(
                        <BulletinBoardEditHeaderBody title="お知らせ管理" />
                      )
                      return <BulletinBoardEdit {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/bulletin-board/edit/:id"
                    render={props => {
                      changeHeaderBody(
                        <BulletinBoardEditHeaderBody title="お知らせ管理" />
                      )
                      return <BulletinBoardEdit {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/owner/list"
                    render={props => {
                      changeHeaderBody(
                        <OwnerHeaderBody title="物件別オーナーリスト" />
                      )
                      return <OwnerList {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/owner/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <OwnerHeaderBody title="物件別オーナーリスト" />
                      )
                      return <OwnerList {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/owner/detail/:id/building/:buildingId"
                    render={props => {
                      changeHeaderBody(
                        <OwnerDetailHeaderBody title="物件別オーナー詳細" />
                      )
                      return <OwnerDetail {...props} />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/message-auto-reply"
                    render={props => {
                      changeHeaderBody(<DefaultBody title="自動返信設定" />)
                      return <MessageAutoReply {...props} />
                    }}
                  />
                  {/* #4704で解約申請設定の刷新により以前の設定は運用後に削除 */}
                  {/* <Route
                    exact
                    path="/setting/cancellation-application-option"
                    render={props => {
                      changeHeaderBody(<DefaultBody title="解約申請設定" />)
                      return <CancellationApplicationOption {...props} />
                    }}
                  /> */}
                  <Route
                    exact
                    path="/setting/bulk-delivery/template/list"
                    render={props => {
                      changeHeaderBody(
                        <BulkDeliveryTemplateListHeaderBody title="一括配信テンプレートリスト" />
                      )
                      return <BulkDeliveryTemplateList />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/bulk-delivery/template/list/:pageId"
                    render={props => {
                      changeHeaderBody(
                        <BulkDeliveryTemplateListHeaderBody title="一括配信テンプレートリスト" />
                      )
                      return <BulkDeliveryTemplateList />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/bulk-delivery/template/new"
                    render={props => {
                      changeHeaderBody(
                        <BulkDeliveryTemplateEditHeaderBody title="一括配信テンプレートリスト＞テンプレート詳細" />
                      )
                      return <BulkDeliveryTemplateEdit />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/bulk-delivery/template/edit/:id"
                    render={props => {
                      changeHeaderBody(
                        <BulkDeliveryTemplateEditHeaderBody title="一括配信テンプレートリスト＞テンプレート詳細" />
                      )
                      return <BulkDeliveryTemplateEdit />
                    }}
                  />
                  <Route
                    exact
                    path="/setting/email-notification"
                    render={props => {
                      changeHeaderBody(<DefaultBody title="通知設定" />)
                      return <EmailNotification {...props} />
                    }}
                  />
                  <AuthAdmin>
                    <Route
                      exact
                      path="/admin/csv-import"
                      render={props => {
                        changeHeaderBody(
                          <DefaultBody title="CSVデータ取り込み" />
                        )
                        return <AdminCsvImport {...props} />
                      }}
                    />
                  </AuthAdmin>
                </Layout>
              </Auth>
            </Switch>
          </NotificationContext.Provider>
        </SearchConditionContext.Provider>
      </LastPathContext.Provider>
    </BrowserRouter>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    changeHeaderBody: component => {
      dispatch(changeHeaderBody(component))
    }
  }
}

export default Sentry.withProfiler(
  connect(mapStateToProps, mapDispatchToProps)(Router)
)
