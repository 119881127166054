import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import KeyReceiptApplicationDetail from './KeyReceiptApplicationDetail'

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  top: {
    flex: 1,
    backgroundColor: '#fff'
  }
}))

function Left(props) {
  const classes = useStyles()
  const [content, setContent] = useState({})
  const { isLoading, application, attachments } = props

  useEffect(() => {
    if (application !== undefined) {
      setContent(JSON.parse(application.content))
    }
  }, [application])

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <KeyReceiptApplicationDetail
          isLoading={isLoading}
          content={content}
          attachments={attachments}
        />
      </div>
    </div>
  )
}

export default Left
