import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Regexes } from 'utils/regexes'

const useStyles = makeStyles(theme => ({
  content: {
    '& > .MuiGrid-item': {
      padding: '5px 15px 5px 15px'
    }
  },
  center: {
    textAlign: 'center',
    marginTop: '12px'
  },
  primaryButton: {
    color: theme.palette.c_blue.main,
    backgroundColor: theme.palette.c_white.main,
    '&:hover': {
      backgroundColor: theme.palette.c_white.dark
    }
  },
  body: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    lineHeight: '1.7',
    whiteSpace: 'pre-wrap'
  }
}))

function FaqDetail(props) {
  const classes = useStyles()
  const faq = props && props.faq ? props.faq : null

  const htmlToPlainText = inputHTML => {
    // NOTE: <br>タグを改行に変換
    const withNewLines = inputHTML
      .replace(/<br\s*\/?>/gi, '\n')
      .replace(/<\/p>/gi, '\n')

    // NOTE: 残りのHTMLタグを削除
    return withNewLines.replace(Regexes.HTMLBody, '').trim()
  }

  return (
    <>
      {faq && (
        <>
          <Grid container spacing={2} className={classes.content}>
            <Grid item xs={12} className={classes.body}>
              {htmlToPlainText(faq.answer)}
            </Grid>
            <Grid item xs={12} className={classes.center} alignItems={'center'}>
              <Button
                variant="contained"
                className={classes.primaryButton}
                onClick={() => props.joinBody(htmlToPlainText(faq.answer))}
              >
                メッセージに挿入
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default withSnackbar(withRouter(FaqDetail))
